@import "globals";

#mm-drawer-nav {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20;
  @include transition(margin-right, 500ms, $easeOutExpo, 300ms);

  @include touch {
    display: none;
  }

  &.desktop {
    &.scroll-bar {
      margin-right: 15px;
    }

    &.announcement {
      top: 70px;
    }

    &.pushed {
      margin-right: 339px;

      @include mobile {
        margin-left: -265px;
      }
    }
  }

  > nav.new-drawer-nav {
    background: #1a1a1a;
    left: 0;
    position: relative;
    top: 0;
    width: 60px;
    @include transition(left, 300ms, $easeInExpo);

    &.offscreen {
      left: 55px;
    }

    ul {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 10px;
    }

    li {
      display: block;
      left: 0;
      min-height: 40px;
      padding-bottom: 10px;
      position: relative;
      top: 0;

      &.hidden {
        display: none;
      }

      a {
        display: flex;
        justify-content: center;
        z-index: 3;

        svg {
          height: 35px;
          width: 35px;

          g {
            fill: $off-white;
            transition: fill 0.2s ease-in-out;
          }
        }

        &:hover {
          svg g {
            fill: #999;
          }
        }

        &:active {
          svg g {
            fill: $off-white;
          }
        }
      }

      &.cart {
        &:hover {
          .count {
            color: #999;
          }
        }

        &:active {
          .count {
            color: $off-white;
          }
        }

        a {
          flex-direction: column;
          align-items: center;
          justify-content: unset;
          text-decoration: none;
        }

        .count {
          color: $off-white;
          font-family: Obibok;
          font-size: 12px;
          @include transition(color, 100ms);
        }
      }

      &.loading .m-spinner {
        display: inline-block;
        transform: translateZ(1px);

        div {
          display: inline-block;
          height: 35px;
          width: 35px;

          svg {
            height: 35px;
            width: 35px;

            g {
              fill: $off-white;
            }
          }

          &.play {
            animation: nav-spinner 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          }
        }
      }

      @keyframes nav-spinner {
        0%,
        100% {
          animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
        }
        0% {
          transform: rotateY(0deg);
        }
        50% {
          transform: rotateY(540deg);
          animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
        }
        100% {
          transform: rotateY(1080deg);
        }
      }
    }
  }
  /*
   *  END - NEW DRAWER NAV
   */
}

#mm-drawer {
  font-family: NeuzeitGro, georgia;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 25;
  @include transition(height, 0, linear, 300ms);

  &.viewing {
    height: 100%;
  }

  .mm-drawer-shelf {
    background: #f1efee;
    @include calc(height, "100% - 55px");
    right: -339px;
    position: fixed;
    top: 0;
    width: 339px;
    z-index: 4;
    @include transition(right, 500ms, $easeOutExpo, 300ms);
    @include mobile {
      right: -265px;
      width: 265px;
    }

    &.onscreen {
      right: 0;
    }

    .mm-drawer-nav-internal {
      position: relative;
      background: #f8f7f7;
      height: 43px;
      z-index: 5;

      @include touch {
        height: 50px;
      }

      .back,
      .close {
        &:active {
          .icon {
            background: $purple;
          }
        }

        .icon {
          background: #fff;
          display: block;
          height: 100%;
          left: 0;
          position: relative;
          top: 0;
          width: 100%;
          @include transition(background, 150ms);

          svg g {
            fill: $black;
            @include transition(fill, 100ms);

            .icon-border {
              display: none;
            }
          }

          &:hover {
            svg g {
              fill: $purple;
            }
          }

          &:active {
            svg g {
              fill: #fff;
            }
          }
        }
      }

      .close {
        background: #fff;
        border-left: 1px solid #f1efee;
        display: block;
        float: right;
        height: 100%;
        width: 43px;
        @include transition(background, 150ms);
        @include touch {
          position: absolute;
          right: 0;
          width: 50px;
          height: 100%;
        }

        &.spinning span {
          @include opacity(0);
        }
      }

      .nav {
        &.mobile {
          width: 100%;
          height: 100%;
          position: absolute;
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          svg {
            height: 100%;
            width: 120px;
          }
        }
      }

      .back {
        @include opacity(0);
        border-right: 1px solid #f1efee;
        display: block;
        float: left;
        height: 100%;
        pointer-events: none;
        visibility: hidden;
        width: 43px;
        @include transition(opacity, 150ms);
        @include touch {
          @include relative;
          width: 50px;
          z-index: 2;
        }

        &.available {
          @include opacity(1);
          pointer-events: auto;
          visibility: visible;
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 35px;
            width: 35px;
          }
        }
      }
    }

    .mm-drawer-wrap {
      position: relative;
      height: 100%;
      @include calc(height, "100% - 43px");

      @include touch {
        @include calc(height, "100% - 50px");
      }

      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;

      .disabled {
        pointer-events: none;
      }
    }

    // internal screens
    article {
      @include opacity(0);
      background: #f1efee;
      display: block;
      height: 100%;
      min-height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;

      &.showing {
        @include transition(opacity, 300ms, $easeInOutQuad, 150ms);
        transform: scale(1);
        @include opacity(1);
        overflow: auto;
        z-index: 3;
      }

      &.hiding {
        @include transition(opacity transform, 300ms, $easeInOutQuad, 0ms);
        @include opacity(0);
        transform: scale(0.9);
        z-index: 2;
      }

      section {
        @include transition(left, 300ms, $easeInOutExpo);
        margin: 0;
        padding: 0;
        padding-bottom: 50px;

        @include touch {
          padding: 5px 0;
        }

        &.root,
        &.nested {
          position: absolute;
          top: 0;
          width: 90%;
          left: 0;
          margin: 0 5%;

          @include touch {
            width: 96%;
            margin: 0 2%;
          }
        }

        &.nested {
          left: -100%;
          position: fixed;
        }
      }

      &.left {
        .root {
          left: -100%;
        }
        .nested {
          left: 0;
          position: absolute;
        }
      }

      hgroup {
        padding: 25px 20px;

        h5 {
          color: #2a2a2a;

          a {
            color: inherit;
            text-decoration: none;
          }
        }

        p {
          padding: 15px 0 0;

          &.hidden {
            display: none;
          }
        }
      }

      p {
        @include lead(1.9, 1.6);
        color: #666;
        font-family: NeuzeitGro, georgia;
        font-size: 1rem;
        padding: 50px 20px 25px 20px;

        a {
          color: $black;
          text-decoration: none;
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }

      input {
        @include box-sizing;
        font-family: NeuzeitGro, georgia;
        background: #fff;
        border: none;
        color: #666;
        display: block;
        font-size: 1.6em;
        height: 50px;
        margin: 1px 0;
        outline: none;
        padding: 0 20px;
        width: 100%;
      }

      button {
        @include kern(80);
        background: $black;
        border: none;
        color: #fff;
        cursor: pointer;
        font-family: Obibok;
        font-size: 0.75rem;
        font-weight: 400;
        height: 60px;
        letter-spacing: 0.1rem;
        line-height: 60px;
        margin: 1px 0 10px 0;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
      }

      a {
        @include opacity(1);
        @include transition(opacity, 100ms);
        &.disabled {
          @include opacity(0.5);
          cursor: default;
        }
      }

      .checkbox-newsletter input[type="checkbox"],
      .checkbox-terms input[type="checkbox"] {
        visibility: hidden;
        height: 12px;
      }

      .checkbox-newsletter,
      .checkbox-terms {
        display: inline;
      }

      .checkbox-newsletter label,
      .checkbox-terms label {
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        position: absolute;
        width: 25px;
        height: 25px;
        background: #eee;
        border: 1px solid rgba(0, 0, 0, 0.2);
        float: left;
      }

      .checkbox-terms label {
        margin-top: 8px;
      }

      p#newsletter-text,
      p#terms-text {
        float: right;
        padding: 4px 0px;
        width: 88%;
        margin-left: 10px;
      }

      p#terms-text {
        padding-bottom: 25px;
      }

      .checkbox-newsletter label:after,
      .checkbox-terms label:after {
        opacity: 0;
        content: "";
        position: absolute;
        width: 9px;
        height: 5px;
        background: transparent;
        top: 6px;
        left: 7px;
        border: 3px solid $purple;
        border-top: none;
        border-right: none;

        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      .checkbox-newsletter label:hover::after,
      .checkbox-terms label:hover::after {
        opacity: 0.7;
      }

      .checkbox-newsletter input[type="checkbox"]:checked + label:after,
      .checkbox-terms input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }

      .button-box {
        @include box-sizing;
        background: #f1efee;
        border: 2px solid lighten($black, 50%);
        color: $black;
        font-family: Obibok;
        font-weight: 100;
        display: block;
        height: 50px;
        line-height: 46px;
        margin: 2px 0;
        overflow: hidden;
        width: 100%;
        text-decoration: none;

        &:hover,
        &:focus {
          background: #f1efee;
          color: $black;
          border-color: $black;

          .sub-info {
            padding-right: 0;
          }
        }

        &:active {
          border-color: $purple;
          background-color: $purple;
          color: #fff;
        }

        .title {
          font-size: 0.9rem;
          max-width: 170px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        }

        .sub-info {
          float: right;
          font-size: 0.75rem;
          color: #666;
          padding-top: 2px;
          padding-right: 10px;
        }

        .icon-left {
          width: 20px;
          height: 20px;
          margin: 16px 7px 0 2px;
          display: none;
          cursor: move;
          float: left;
        }

        .icon-right {
          width: 20px;
          height: 20px;
          margin: 11px 0 0 7px;
          display: none;
          float: right;
        }
      }

      textarea {
        @include box-sizing;
        @include lead(1.9, 1.6);
        border: none;
        color: $black;
        font-family: NeuzeitGro, georgia;
        font-size: 1.6em;
        height: 200px;
        outline: none;
        padding: 20px;
        width: 100%;
      }
    }

    #cart {
      hgroup {
        color: $black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
      }

      .count {
        @include lead(2.6, 1.4);
        font-family: Obibok;
        font-size: 0.75rem;
        font-weight: 100;
      }

      .mm-cart-target {
        li {
          @include relative;
          background: #f8f7f7;
          overflow: hidden;
          padding: 20px;

          @include transition(background, 150ms);

          @include tablet-portrait-down {
            height: auto;
          }

          .row {
            display: flex;
            justify-content: space-between;
          }

          .top {
            overflow: hidden;

            .title,
            .price {
              @include lead(1.8, 1.8);
              color: $black;
              font-size: 18px;
            }

            .title {
              max-width: 170px;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-bottom: 2px;
            }
          }

          .bottom {
            margin-top: 3px;

            .artist,
            .license {
              @include lead(1.8, 1.4);
              color: #666;
              font-size: 14px;
            }

            .license {
              text-align: right;
            }

            @include tablet-portrait-down {
              flex-direction: column;

              .license {
                text-align: left;
              }
            }
          }

          .price,
          .license {
            @include opacity(1);
            @include transition(opacity, 150ms);
          }

          a[data-action="remove"] {
            @include opacity(0);
            background-color: #fff;
            font-size: 25px;
            font-weight: bold;
            left: auto;
            position: absolute;
            right: 20px;
            top: 15px;
            margin-left: 0;
            @include transition(opacity, 150ms);

            @include tablet-portrait-down {
              right: 20px;
            }
          }

          &:hover,
          &:focus {
            background: #fff;

            .price {
              @include opacity(0);
            }

            a[data-action="remove"] {
              @include opacity(1);
            }
          }
        }
      }

      .totals {
        padding: 18px 20px;

        .left {
          float: left;
        }
        .right {
          float: right;
        }

        .subtotal,
        .total {
          @include lead(2.8, 1.8);
          color: $black;
          font-size: 18px;
          overflow: hidden;
        }

        .total {
          font-weight: 600;
        }
      }

      .checkout {
        @include button-states;
        display: block;
        font-family: Obibok;
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0.1rem;
        margin-bottom: 10px;
      }

      .close {
        @include anchor-button-states;
        @include box-button-black;
        background: #5f5a58;
        display: block;
        font-family: Obibok;
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0.1rem;
        height: 40px;
        line-height: 40px;
      }
    }

    #cartbench {
      .root {
        .local-nest {
          display: block;
          margin-bottom: 20px;
        }
      }

      overflow: auto;

      .nested {
        .button-box .range {
          display: block;
          margin-right: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          @include touch {
            padding-left: 15px;
          }
        }

        .button-box .icon {
          @include opacity(0);
          position: absolute;
          right: 0;
          @include transition(opacity, 100ms);

          .add-to-cart {
            svg g {
              fill: $black;
            }
          }
        }

        [data-license-targ] {
          display: none;
          &.active {
            display: block;
          }

          .ex-header {
            text-align: center;
          }

          .industry-list {
            font-size: 15px;
            margin-left: 20px;
          }

          .checkbox-exclusive input {
            float: left;
            margin-bottom: 25px;
            width: 5%;
            height: initial;
          }

          .podcast-use {
            font-size: 1.25rem;
            margin: 6px 0px 20px 0px;
          }

          .podcast-submit {
            outline: none;
          }

          label {
            font-size: 14px;
            margin-left: 7px;
          }

          .ex-button {
            outline: none;
          }
        }

        .box-button-black {
          background: #bab7b5;
          height: 40px;
          line-height: 40px;

          &.available {
            background: $black;
          }
        }
        @include touch {
          .button-box {
            .range {
              margin-right: 30px;
            }
            .price {
              @include opacity(0);
            }
            .icon {
              @include opacity(1);
            }
          }
        }
      }

      .button-box {
        @include relative;
        color: $black;
        display: flex;
        font-family: Obibok;
        font-size: 0.9rem;
        font-weight: 100;
        height: 50px;
        justify-content: space-between;
        letter-spacing: 0;
        overflow: hidden;
        text-decoration: none;
        @include transition(border, 100ms);

        &.license {
          .name {
            width: auto;
          }

          @include not-mobile {
            .name {
              width: auto;
            }
          }
          @include touch {
            .name {
              width: auto;
            }
          }
        }

        .name {
          display: block;
          flex: 6;
          float: left;
          height: 50px;
          overflow: hidden;
          padding-left: 20px;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 185px;
        }

        .logo-name {
          display: block;
          flex: 6;
          float: left;
          height: 50px;
          overflow: hidden;
          padding-left: 20px;
          width: 100px;
          align-items: flex-end;
          padding-right: 100px;
          padding-top: 3px;

          svg {
            width: 125px;
          }

          svg path.cls-1 {
            fill: black;
          }

          svg path.cls-2 {
            fill: #56b9e4;
          }

          svg path.cls-3 {
            fill: #e54c40;
          }

          svg path.cls-4 {
            fill: #fff;
          }

          svg path.cls-5 {
            fill: #fae55b;
          }
        }

        .price {
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          padding-right: 20px;
          @include opacity(1);
          @include transition(opacity, 100ms);

          sup.from-superscript {
            vertical-align: top;
            position: relative;
            bottom: 4px;

            svg {
              width: 20px;

              tspan {
                fill: black;
              }
            }
          }
        }

        .range {
          padding-left: 20px;
          @include touch {
            width: 185px;
          }
        }

        .icon {
          @include opacity(0);
          display: flex;
          align-items: center;
          flex: 1;
          justify-content: center;
          height: 100%;
          padding-right: 10px;
          @include transition(opacity, 100ms);

          svg {
            width: 40px;
            height: 40px;

            g {
              fill: $black;

              .icon-border {
                display: none;
              }
            }
          }
        }

        @include not-mobile {
          &:hover,
          &:focus {
            .name {
              width: 240px;
            }
            .range {
              margin-right: 50px;
            }
            .price {
              @include opacity(0);
            }
            .podcast-icon {
              @include opacity(1);
            }
            .icon {
              @include opacity(1);
            }
          }
        }

        @include touch {
          .name {
            width: 240px;
          }
          .range {
            margin-right: 30px;
            width: 240px;
          }
          .price {
            @include opacity(0);
          }
          .podcast-icon {
            @include opacity(1);
          }
          .icon {
            @include opacity(1);
          }
        }

        @include mobile {
          .name,
          .range {
            width: 185px !important;
          }
        }
      }
    }

    #customize {
      button[type="submit"] {
        height: 40px;
        line-height: 40px;
        @include button-states;
      }
    }

    #mixbench {
      .button-box {
        @include relative;
        cursor: pointer;
        @include transition(border-color, 100ms);

        .icon-left {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          margin: 0;
          position: absolute;
          width: 40px;

          svg {
            height: 35px;
            width: 35px;

            g {
              fill: $black;

              .icon-border {
                display: none;
              }
            }
          }
        }

        .title {
          @include box-sizing;
          color: $black;
          font-size: 0.9rem;
          font-weight: 100;
          text-decoration: none;
          display: block;
          position: absolute;
          padding-left: 20px;
          padding-right: 80px;

          width: 100%;
          max-width: 288px;
          text-overflow: ellipsis;

          &.collaborator {
            padding-left: 40px;
          }

          @include touch {
            max-width: 100%;
            padding-right: 80px;
          }
        }

        &:hover,
        &:focus {
          .icon-right {
            @include opacity(1);
          }
          .sub-info {
            @include opacity(0);
          }
        }
      }

      button[type="submit"] {
        height: 40px;
        line-height: 40px;
        @include button-states;

        &[disabled] {
          background: #bab7b5;
          pointer-events: none;
        }
      }

      .icon-right,
      .sub-info {
        @include transition(opacity, 100ms);
      }

      .icon-right {
        @include opacity(0);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 0;
        position: absolute;
        right: 12px;
        width: auto;

        svg {
          height: 40px;
          width: 40px;

          g {
            fill: $black;

            .icon-border {
              display: none;
            }
          }
        }
      }

      .sub-info {
        @include opacity(1);
        position: absolute;
        right: 0;
        top: 0;
      }

      form {
        @include relative;
        padding-top: 30px;

        label {
          display: block;
          margin: 0 auto;
          &.hidden {
            display: none;
          }
          p {
            color: #333;
            font-size: 1.4em;
            padding: 5px 5px 10px 5px;
          }
          .title {
            text-align: center;
            font-size: 1.6em;
          }
        }

        .input {
          @include relative;
          height: 50px;
          background-color: #fff;
          display: block;
          margin-bottom: 2px;

          input {
            background: transparent;
            float: left;
            margin: 0;
            padding-right: 0;
            width: 210px;

            &[type="checkbox"] {
              &:focus {
                outline: none;
              }
            }
          }

          .toggle {
            @include relative;
            display: block;
            float: right;
            width: 40px;
            height: 50px;
            input {
              height: 10px;
              padding: 0;
              position: absolute;
              right: 22px;
              top: 17px;
              width: 10px;
            }

            input:focus + label,
            label:hover {
              .hover {
                @include opacity(1);
              }
            }

            label {
              background: #fff;
              display: flex;
              align-items: center;
              height: 100%;
              position: absolute;
              right: 20px;
              top: unset;
              width: 30px;

              .icon {
                &.hidden {
                  display: none;
                }

                svg {
                  height: 40px;
                  width: 40px;

                  g {
                    fill: $black;
                    @include transition(fill, 100ms);

                    .icon-border {
                      display: none;
                    }
                  }
                }
              }

              &:hover,
              &:focus {
                cursor: pointer;

                svg g {
                  fill: $purple;
                }
              }
            }
          }
        }
      }
    }

    #mixtapes {
      .list .button-box {
        @include transition(all, 100ms);
        @include relative;

        .icon-left,
        .icon-middle {
          svg {
            height: 35px;
            width: 35px;

            g {
              fill: $black;

              .icon-border {
                display: none;
              }
            }
          }
        }

        .icon-left {
          display: none;
          align-items: center;
          height: 100%;
          margin: 0 0 0 -8px;
          position: relative;
          width: auto;

          &:hover {
            svg g {
              fill: $purple;
            }
          }
        }

        .icon-middle {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          position: absolute;
          width: 40px;
        }

        .title {
          @include box-sizing;
          color: $black;
          display: block;
          max-width: 288px;
          position: absolute;
          padding-left: 20px;
          padding-right: 80px;
          text-decoration: none;
          text-overflow: ellipsis;
          width: 100%;

          &.collaborator {
            padding-left: 40px;
          }

          @include touch {
            max-width: 100%;
            padding-right: 80px;
          }
        }

        &:hover,
        &:focus,
        &.dragging {
          background: #f1efee;
          color: $black;
          border-color: $black;
          margin-left: -2%;
          padding: 0 6px;
          width: 104%;

          .icon-left {
            display: flex;
            z-index: 1;
          }

          .icon-middle {
            display: none;
          }

          .title {
            padding-left: 35px;
            left: -4px;
            width: 299px;
            max-width: 299px;
          }

          @include touch {
            margin-left: -1%;
            width: 101%;

            .title {
              max-width: 100%;
              left: 0;
              padding-left: 30px;
              padding-right: 50px;
            }
          }
        }

        &.dragging {
          @include transition(none);
        }

        &.no-sort {
          @include transition(none);
          @include transition(border-color, 100ms);
          margin-left: 0 !important;
          width: 100% !important;

          .icon-left {
            display: none;
          }

          &:hover,
          &:focus {
            padding: 0;

            .icon-middle {
              display: block;
            }
          }

          .title {
            margin-left: 0 !important;
            width: 100% !important;
            left: 0 !important;
            padding-left: 20px !important;

            &.collaborator {
              padding-left: 40px !important;
            }
          }
        }
      }

      form {
        @include relative;
        padding-top: 30px;
        label {
          display: block;
          margin: 0 auto;
          &.hidden {
            display: none;
          }
          p {
            color: #333;
            font-size: 1.4em;
            padding: 5px 5px 10px 5px;
          }
          .title {
            text-align: center;
            font-size: 1.6em;
          }
        }
        .input {
          @include relative;
          height: 50px;
          background-color: #fff;
          display: block;
          margin-bottom: 1px;
          input {
            background: transparent;
            float: left;
            margin: 0;
            padding-right: 0;
            width: 210px;
            &[type="checkbox"] {
              &:focus {
                outline: none;
              }
            }
          }
          .toggle {
            @include relative;
            display: block;
            float: right;
            width: 40px;
            height: 50px;

            input {
              height: 10px;
              padding: 0;
              position: absolute;
              right: 22px;
              top: 17px;
              width: 10px;
            }

            input:focus + label,
            label:hover {
              .hover {
                @include opacity(1);
              }
            }

            label {
              background: #fff;
              display: flex;
              align-items: center;
              height: 100%;
              position: absolute;
              right: 20px;
              top: unset;
              width: 30px;

              .icon {
                &.hidden {
                  display: none;
                }

                svg {
                  height: 40px;
                  width: 40px;

                  g {
                    fill: $black;
                    @include transition(fill, 100ms);

                    .icon-border {
                      display: none;
                    }
                  }
                }
              }

              &:hover,
              &:focus {
                cursor: pointer;

                svg g {
                  fill: $purple;
                }
              }
            }
          }
        }
      }

      button[type="submit"] {
        height: 40px;
        line-height: 40px;
        @include button-states;

        &[disabled] {
          background: #bab7b5;
          pointer-events: none;
        }
      }

      .no-mixtapes {
        .icon {
          @include relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;

          svg {
            height: 70px;
            width: 70px;

            g {
              fill: $black;

              .icon-border {
                display: none;
              }
            }
          }
        }

        p a {
          color: $purple;
        }
      }
    }

    #project-searchbench {
      .project-search-item-container {
        &.expanded {
          .project-search-item {
            background: #fff;
            border-color: $black;
            margin-left: -2%;
            width: 104%;
          }

          .directions-list {
            @include opacity(1);
            margin-left: -2%;
            width: 104%;
            z-index: 10;
          }
        }

        &.with-directions {
          .project-search-item {
            &:active {
              background: initial;
            }

            .title {
              &:active {
                background-color: initial;
                color: initial;
              }
            }
          }
        }

        .project-search-item {
          @include relative;
          cursor: pointer;
          @include transition(border-color, 100ms);

          &:hover,
          &:focus {
            margin-left: -2%;
            width: 104%;
          }

          &:active {
            background: #f1efee;
            border-color: $black;
            color: $black;
          }

          .title {
            @include box-sizing;
            color: $black;
            text-decoration: none;
            display: block;
            max-width: 100%;
            position: absolute;
            padding-left: 20px;
            padding-right: 80px;
            text-overflow: ellipsis;
            width: 100%;

            &:active {
              background-color: $purple;
              border-color: $purple;
              color: #fff;
            }

            @include touch {
              max-width: 100%;
              padding-right: 80px;
            }
          }

          .sub-info {
            @include opacity(1);
            position: absolute;
            right: 0;
            top: 0;
            padding-right: 8px;
            @include transition(opacity, 100ms);
          }

          .search-error-overlay {
            background: $red;
            color: #fff;
            display: none;
            align-items: center;
            justify-content: center;
            font-family: NeuzeitGro, georgia;
            font-size: 18px;
            line-height: 18px;
            position: absolute;
            height: 100%;
            width: 100%;

            &.show {
              display: flex;
            }

            .message-text {
              cursor: auto;
              padding: 0 10px;
              width: 90%;
            }

            .close-error-message {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              width: 10%;

              .icon {
                display: flex;

                svg {
                  height: 30px;
                  width: 30px;

                  g {
                    fill: #fff;

                    .icon-border {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }

        .directions-list {
          @include opacity(0);
          background: #fff;
          margin-top: -2px;
          position: absolute;
          z-index: 0;

          .direction {
            border-bottom: 1px solid $black;
            border-left: 2px solid $black;
            border-right: 2px solid $black;
            cursor: pointer;
            font-family: Obibok;
            font-size: 0.9rem;
            font-weight: 100;
            height: 40px;
            line-height: 40px;
            overflow: hidden;
            padding: 0 20px;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:last-of-type {
              border-bottom: 2px solid $black;
            }

            &:hover,
            &:focus {
              background: $purple;
              color: #fff;
            }

            &:active {
              background: $black;
              color: #fff;
            }
          }
        }
      }

      button[type="submit"] {
        height: 40px;
        line-height: 40px;
        @include button-states;

        &[disabled] {
          background: #bab7b5;
          pointer-events: none;
        }
      }

      button.view-more-searches {
        height: 50px;
        line-height: 50px;

        &.hide {
          display: none;
        }
      }

      form {
        @include relative;
        padding-top: 30px;

        label {
          display: block;
          margin: 0 auto;
          &.hidden {
            display: none;
          }
          p {
            color: #333;
            font-size: 1.4em;
            padding: 5px 5px 10px 5px;
          }
          .title {
            text-align: center;
            font-size: 1.6em;
          }
        }

        .input {
          @include relative;
          height: 50px;
          background-color: #fff;
          display: block;
          margin-bottom: 2px;
        }
      }

      .search.errors {
        padding: 0;
        @include kern(80);
        @include lead(1.8, 1.3);
        font-family: NeuzeitGro, georgia;
        color: $red;
        font-size: 1.3em;
      }
    }

    #project-searches {
      .list .button-box {
        @include transition(all, 100ms);

        .icon-left {
          display: none;
          align-items: center;
          height: 100%;
          margin: 0 0 0 -8px;
          position: relative;
          width: auto;

          svg {
            height: 35px;
            width: 35px;

            g {
              fill: $black;

              .icon-border {
                display: none;
              }
            }
          }

          &:hover {
            svg g {
              fill: $purple;
            }
          }
        }

        .icon-right {
          display: none;
          align-items: center;
          justify-content: center;
          height: 100%;
          left: -3px;
          margin: 0 0 0 7px;
          position: relative;
          width: 20px;

          &:hover {
            svg g {
              fill: #000;
            }
          }

          svg {
            height: 20px;
            width: 20px;

            g {
              fill: $black;
              @include transition(fill, 100ms);
            }
          }
        }

        .title {
          @include box-sizing;
          color: $black;
          display: block;
          max-width: 288px;
          position: absolute;
          padding-left: 20px;
          padding-right: 80px;
          text-decoration: none;
          text-overflow: ellipsis;
          width: 100%;

          @include touch {
            max-width: 100%;
            padding-right: 80px;
          }
        }

        &:hover,
        &:focus,
        &.dragging {
          background: #f1efee;
          color: $black;
          border-color: $black;
          margin-left: -2%;
          padding-left: 6px;
          width: 104%;

          .icon-left {
            display: flex;
            z-index: 1;
          }

          .icon-right {
            display: flex;
            z-index: 1;
            cursor: pointer;
          }

          .title {
            left: -4px;
            padding-left: 35px;
            max-width: 300px;
            width: 300px;
          }

          @include touch {
            margin-left: -1%;
            width: 101%;

            .title {
              max-width: 100%;
              left: 0;
              padding-left: 35px;
              padding-right: 50px;
            }
          }
        }

        &.dragging {
          @include transition(none);
        }

        &.no-sort {
          @include transition(none);
          @include transition(border-color, 100ms);
          margin-left: 0 !important;
          width: 100% !important;

          .icon-left {
            display: none;
          }

          &:hover,
          &:focus {
            padding: 0;

            .icon-middle {
              display: block;
            }
          }

          .title {
            margin-left: 0 !important;
            width: 100% !important;
            left: 0 !important;
            padding-left: 20px !important;

            &.collaborator {
              padding-left: 40px !important;
            }
          }
        }
      }

      button.view-more-searches {
        height: 50px;
        line-height: 50px;

        &.hide {
          display: none;
        }
      }

      form {
        @include relative;
        padding-top: 30px;
        label {
          display: block;
          margin: 0 auto;
          &.hidden {
            display: none;
          }
          p {
            color: #333;
            font-size: 1.4em;
            padding: 5px 5px 10px 5px;
          }
          .title {
            text-align: center;
            font-size: 1.6em;
          }
        }
        .input {
          @include relative;
          height: 50px;
          background-color: #fff;
          display: block;
          margin-bottom: 1px;
          input {
            background: transparent;
            float: left;
            margin: 0;
            padding-right: 0;
            width: 210px;
            text-overflow: ellipsis;
            &[type="checkbox"] {
              &:focus {
                outline: none;
              }
            }
          }
        }

        input.search-search-bar {
          border: 2px solid $black;

          &:focus {
            color: $black;
          }
        }

        div.no-results {
          display: none;
          align-items: center;
          border-right: 2px solid $black;
          border-left: 2px solid $black;
          border-bottom: 2px solid $black;
          background: #fff;
          color: $red;
          font-size: 1.6em;
          height: 50px;
          margin-top: -2px;
          padding: 0 20px;
          position: absolute;
          z-index: 100;

          &.show {
            display: flex;
          }
        }

        button[type="submit"] {
          height: 40px;
          line-height: 40px;
          @include button-states;

          &[disabled] {
            background: #bab7b5;
            pointer-events: none;
          }
        }
      }

      .no-project-searches {
        .icon {
          @include relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;

          svg {
            height: 70px;
            width: 70px;

            g {
              fill: $black;

              .icon-border {
                display: none;
              }
            }
          }
        }

        p a {
          color: $purple;
        }
      }

      .errors {
        padding: 0;
        @include kern(80);
        @include lead(1.8, 1.3);
        font-family: NeuzeitGro, georgia;
        color: $red;
        font-size: 1.3em;
      }
    }

    #share {
      &.share-mixtape {
        h5::after {
          content: " Mixtape";
        }
      }

      .button-box {
        @include proxima-semibold;
        @include transition(border-color, 100ms);

        height: 60px;
        line-height: 54px;
        margin: 5px 0;
        padding: 0 20px;
        font-size: 1.3em;
        text-decoration: none;
        text-transform: uppercase;

        .label {
          font-family: Obibok;
          font-size: 0.7rem;
          font-weight: 400;
        }

        .icon {
          float: right;
          margin-top: 22px;
        }
      }

      .email {
        .button-box {
          padding-right: 13px;
        }

        .icon {
          display: flex;
          align-items: center;
          height: 100%;
          margin-top: 0;
          width: auto;

          svg {
            height: 30px;
            width: 30px;

            g {
              fill: $black;

              .icon-border {
                display: none;
              }
            }
          }
        }
      }

      .facebook {
        .icon {
          .default {
            @include center-sprite(icon_borderless_fb);
          }
          .hover {
            @include center-sprite(icon_borderless_fb_hover);
          }
        }
      }

      .twitter {
        .icon {
          .default {
            @include center-sprite(icon_borderless_twitter);
          }
          .hover {
            @include center-sprite(icon_borderless_twitter_hover);
          }
        }
      }

      .copy {
        &.copied {
          .label {
            display: none;
          }

          a:after {
            content: "Copied!";
            color: $green;
          }
        }
      }
    }

    #share-email {
      button {
        @include button-states;
      }
    }

    #nav {
      h4 {
        @include kern(80);
        font-family: NeuzeitGro, georgia;
        font-size: 1.1em;
        font-weight: bold;
        line-height: 1;
        padding: 10px 0;
        text-align: center;
        text-transform: uppercase;
      }

      nav {
        > ol {
          > li {
            border: 2px solid #959290;
            color: $black;
            display: block;
            font-family: Obibok;
            font-weight: 100;
            font-size: 0.8rem;
            height: 44px;
            line-height: 48px;
            margin-bottom: 5px;
            padding-left: 20px;

            &:last-child {
              margin-bottom: 0;
            }

            &:active {
              border-color: $black;
            }

            &.hidden {
              display: none;
            }

            &.mobile-player {
              border: none;
              height: auto;
              padding-left: 0;
              &.expanded {
                height: auto !important;
              }
            }

            a {
              color: $black;
              display: block;
              text-decoration: none;
            }
          }
        }
      }

      .mm-station-select {
        $selectH: 46px;

        @include box-sizing(border-box);
        border: 0;
        display: none;
        font-size: 1em;
        height: $selectH;
        line-height: 0;
        margin: 0;
        padding: 0;

        label {
          display: block;
          margin: 0;
          padding: 0;
          position: relative;

          &:after {
            @include box-sizing(border-box);
            border: 0;
            border-left: 1px solid $purple;
            content: "\25BC";
            color: $black;
            display: block;
            font-size: 1em;
            height: $selectH;
            line-height: 48px; /* vertical positioning of arrow */
            margin: 0;
            overflow: hidden;
            padding: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
            width: $selectH;
          }

          select {
            @include box-sizing(border-box);
            border-radius: 0;

            background-color: #f7f7f7;
            border: 2px solid $purple;
            color: $black;
            cursor: pointer;
            display: inline-block;
            font-family: NeuzeitGro, georgia;
            font-size: 0.75rem;
            height: $selectH;
            letter-spacing: 0.07em;
            line-height: 42px;
            margin: 0;
            outline: 0;
            overflow: hidden;
            padding: 0 18px;
            padding-right: 60px;
            position: relative;
            width: 100%;
            text-transform: uppercase;
          }
        }
      }

      nav[role="main"] {
        &.playing,
        &.paused {
          .mm-station-select {
            display: block;
          }
        }
      }
    }

    #login {
      .forgot,
      .register {
        @include kern(80);
        background: #5f5a58;
        color: #fff;
        display: block;
        font-family: Obibok;
        font-size: 0.75rem;
        font-weight: normal;
        height: 40px;
        letter-spacing: 0.1rem;
        line-height: 40px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        width: 100%;
        @include anchor-button-states;
      }

      .forgot {
        margin-top: 10px;
      }

      .register {
        background: #504c49;
      }
    }

    span.bold {
      font-weight: bold;
    }

    button {
      @include button-states;
    }
  }
}

.mm-drawer-form {
  .errors {
    display: none;
    padding: 0 20px 25px 20px;

    &.display {
      display: block;
    }

    div {
      @include kern(80);
      @include lead(1.8, 1.3);
      font-family: NeuzeitGro, georgia;
      color: $red;
      font-size: 1.3em;
    }
  }

  .message {
    @include kern(80);
    @include lead(1.8, 1.3);
    color: $red;
    display: none;
    font-family: NeuzeitGro, georgia;
    font-size: 1.3em;
    padding: 0 20px 25px 20px;

    &.display {
      display: block;
    }
  }

  .success {
    display: none;
    &.display {
      display: block;
    }

    p {
      color: $green !important;
      padding-top: 0 !important;
    }
  }

  form {
    &.success {
      display: none;
    }
  }

  input,
  textarea {
    &[disabled="disabled"] {
      @include opacity(0.5);
    }

    &.invalid {
      box-shadow: 0 0 3px $red inset;
      // border: 1px solid $red;
    }
  }

  .spin-targ {
    @include relative;
    clear: both;
    display: block;
    height: 60px;

    button {
      &:disabled {
        @include opacity(0.5);
      }
    }
  }
}

.form-hide {
  &.hide {
    display: none !important;
  }
}

ul.project-search-select-options {
  background: #fff;
  border-bottom: 2px solid $black;
  border-left: 2px solid $black;
  border-right: 2px solid $black;
  color: $black;
  font-family: NeuzeitGro, georgia;
  font-weight: 400;
  font-size: 1.6em;
  max-width: 288px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  z-index: 100;
  @include transition(right, 500ms, $easeOutExpo, 300ms);
  @include mobile {
    right: -265px;
    width: 265px;
  }

  .ui-menu-item {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0px 20px;

    &:not(:first-of-type) {
      border-top: 1px solid $black;
    }

    &:hover,
    &:focus {
      background: $purple;
      color: #fff;
      cursor: pointer;
    }

    a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
