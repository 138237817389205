@import "globals";

#mm-fervor-vinyl {
  header#mm-fervor-vinyl-landing {
    .cover-image {
      height: 7.5rem;
      width: 100%;

      @include mobile {
        height: 80px;
      }
    }

    hgroup {
      @include relative;
      background: #efefed;
      height: 335px;
      text-align: center;
      width: 100%;
      z-index: 1;
      @include mobile {
        height: 250px;
      }

      .contain {
        @include relative;
      }

      .logos {
        @include relative;
        display: inline-block;
        height: 125px;
        margin: 10px;
        top: 20px;
        width: 125px;

        @include mobile {
          height: 100px;
          width: 100px;
        }
      }

      h1 {
        color: $black;
        padding-top: 40px;

        @include mobile {
          padding-top: 30px;
        }

        &:last-of-type {
          padding-top: 20px;
        }
      }
    }
  }

  .action {
    background: #f8f8f8;
    padding: 12px 0;
    text-align: center;

    .box-button {
      border-color: $black;
      color: $black;
      cursor: pointer;

      &:hover {
        background: $black;
        color: #fff;
      }
      &:active {
        background: #00978e;
        color: $black;
      }
    }
  }

  article {
    background: #fff;
    padding-bottom: 80px;
    padding-top: 60px;
    @include mobile {
      padding: 25px 0;
    }

    .album-art {
      margin: 0 auto;
      text-align: center;
    }

    .album-cover,
    .album-backcover {
      @include relative;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
      display: inline-block;
      height: 500px;
      margin: 10px 2%;
      width: 500px;

      @include mobile {
        height: 275px;
        width: 275px;
      }
    }
  }
}
