@import "globals";

#mm-session {
  #mm-session-landing {
    .cover-image {
      height: 250px;
      width: 100%;

      @include mobile {
        height: 80px;
      }
    }

    hgroup {
      background: #efefed;
      min-height: 225px;
      left: 0;
      position: relative;
      top: 0;

      .user-icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        margin: 0 auto;
        top: 40px;
        width: 80px;

        svg {
          width: 80px;
          height: 80px;

          g {
            fill: $green;
          }
        }
      }

      h1 {
        color: $black;
        padding-top: 50px;
        text-align: center;
      }

      em {
        @include lead(2.8, 1.8);
        color: #666;
        display: block;
        font-size: 1.8em;
        text-align: center;
      }
    }
  }

  .narrow-form {
    margin: 7em 0;

    @include mobile {
      margin: 7em 2em;
    }

    .contain {
      width: 350px;

      @include mobile {
        width: 100%;
        max-width: 350px;
      }
    }

    .label {
      font-family: NeuzeitGro, georgia;
      font-size: 14px;
      color: #333;
      margin-top: 2em;
    }

    .inputs li:not(:last-child) {
      margin-bottom: 2em;
    }
  }
  &.register-confirmation {
    .contain p {
      padding: 2em 0;
    }
  }

  #reset-password,
  #edit-profile {
    padding: 50px 20px;

    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .error {
    color: $purple;
    margin: 1em 0;
  }

  h1 {
    margin-bottom: 20px;
  }

  div.content {
    width: 344px;
    margin: 0 auto;
    padding: 50px 20px 100px 20px;
  }

  .new_user {
    margin: 4em 0;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    font-family: NeuzeitGro, georgia;
    font-size: 1rem;
    background: #fff;
    border: 2px solid #999;
    color: #666;
    display: block;
    height: 50px;
    margin: 1px 0;
    outline: none;
    padding: 0 1em;
    width: 100%;
    box-sizing: border-box;
  }

  .remember-me {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;

    label {
      color: #666;
      font-family: NeuzeitGro, georgia;
      font-size: 15px;
      margin-left: 6px;
      padding-top: 0;
    }
  }

  div.links {
    a {
      font-size: 1.4em;
      color: $black;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      &:active {
        color: #101011;
      }
    }
  }

  .reset-note {
    color: #666;
    font-size: 14px;
    font-weight: 100;
    margin: 1em 0;
  }

  #error_explanation {
    padding: 20px 0;

    h2 {
      font-weight: 500;
      font-size: 16px;
    }

    li {
      color: $red;
      font-size: 14px;
    }
  }

  .button {
    @include kern(80);
    @include transition(background, 150ms);
    font-family: Obibok;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
    background: $black;
    border: none;
    color: #fff;
    cursor: pointer;
    height: 60px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    &:hover {
      background: #262220;
    }
  }

  fieldset {
    margin: 20px 0;
  }
}
