@import "globals";
#mm-history-detail {
  #mm-history-detail-landing {
    .cover-image {
      height: 250px;
      width: 100%;

      @include mobile {
        height: 80px;
      }
    }

    hgroup {
      @include relative;
      background: #efefed;
      height: 200px;

      .song-version-icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        margin: 0 auto;
        top: 40px;
        width: 80px;
      }

      h1 {
        color: $black;
        padding-top: 50px;
        text-align: center;
      }
    }
  }

  nav.anchors {
    background: #f8f8f8;
    min-height: 70px;
    text-align: center;

    ol {
      display: inline-block;
      overflow: hidden;

      li {
        float: left;

        @include mobile {
          width: 50%;
        }

        @include tablet {
          width: 25%;
        }

        a {
          color: var(--purple);
          display: block;
          font-family: Obibok;
          font-size: 0.7rem;
          font-weight: 400;
          letter-spacing: 0.1rem;
          line-height: 1rem;
          margin: 27px 20px;
          text-decoration: none;
          text-transform: uppercase;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  #order {
    padding-bottom: 70px;

    h4 {
      color: $black;
      padding: 60px 0 15px 0;
      @include contain-break;
    }

    .info {
      @include contain-break;
      font-family: Obibok;
      font-size: 0.9rem;
      font-weight: 100;
      overflow: hidden;

      dt,
      dd {
        @include lead(2.1, 1.8);
        margin: 0;
      }

      dt {
        color: $black;
      }

      dd {
        color: #666;
        padding-bottom: 20px;

        a {
          color: #666;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .left,
      .right {
        width: 48%;
      }

      .left {
        float: left;
      }

      .right {
        float: right;
        margin-right: -20px;
      }

      .downloads {
        float: right;

        span {
          display: block;
          background: #3a3532;
          padding: 10px;

          a {
            color: #fff;
            text-decoration: none;
          }

          &:hover {
            background: #262220;
          }
        }
      }
    }
  }
}
