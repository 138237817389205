@import "globals";

#mm-receipt {
  #mm-receipt-landing {
    .cover-image {
      background: url("/app/assets/images/headers/brand-update-header.jpg") center center / cover
        no-repeat;
      height: 225px;
      width: 100%;

      @include mobile {
        height: 80px;
      }
    }

    hgroup {
      @include relative;
      background: #efefed;
      height: 205px;
      text-align: center;
      width: 100%;
    }

    .song-version-icon {
      @include relative;
      background: #fff;
      border-radius: 50%;
      display: block;
      height: 80px;
      margin: 0 auto;
      top: 40px;
      width: 80px;
    }

    h1 {
      color: $black;
      margin-top: 50px;
    }
  }

  .message {
    overflow: hidden;
    padding: 50px 20px 30px 20px;

    .thank-you.float {
      float: left;
      width: 61%;

      @include tablet-portrait-down {
        width: 100%;
      }
    }

    h4 {
      color: $black;
      padding-bottom: 16px;
    }

    p,
    .terms-checkbox {
      color: $black;
      font-family: Obibok;
      font-size: 0.9rem;
      font-weight: 100;
      letter-spacing: 0;
      line-height: 1.25rem;
      padding: 10px 25px 10px 0;

      @include tablet-portrait-down {
        padding: 10px 0;
      }

      a {
        color: var(--purple);
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    .purchase-date {
      padding-bottom: 0;
    }

    .next-step {
      padding-top: 30px;

      &.float {
        float: right;
        text-align: center;
        width: 39%;

        .box-button-black.newsletter {
          margin: 0 auto;
        }
      }

      @include tablet-portrait-down {
        display: none;
      }

      form#create-account-from-guest {
        label {
          color: #333;
          display: block;
          font-family: Obibok;
          font-weight: 100;
          font-size: 0.75rem;
          line-height: 1.25rem;
          padding-top: 12px;
        }

        input {
          -webkit-font-smoothing: antialiased;
          font-family: NeuzeitGro, georgia;
          font-size: 1rem;
          border: 2px solid #999;
          color: #3a3532;
          display: block;
          height: 42px;
          outline: none;
          padding: 0 10px;
          width: 276px;

          &:disabled {
            opacity: 0.6;
          }
        }

        .left,
        .right {
          float: left;
          width: 50%;
        }

        .spin-targ {
          display: inline-block;
          padding-bottom: 10px;
          padding-top: 20px;
        }

        .success-message {
          color: var(--green);
          display: none;
          height: 21px;
          padding-bottom: 4px;
          padding-top: 0;

          &.show {
            display: block;
          }

          &.fail {
            color: $red;
          }
        }
      }

      .box-button-black {
        @include button-states;
      }

      .box-button-black {
        @include kern(80);
        cursor: pointer;
        display: block;
        font-family: Obibok;
        font-size: 0.75rem;
        letter-spacing: 0.1rem;
        font-weight: 600;
        outline: none;
        width: 300px;

        &:disabled {
          opacity: 0.6;
          pointer-events: none;
        }

        &.newsletter {
          margin: 0 auto;
        }
      }

      em {
        color: $black;
        display: block;
        font-family: Obibok;
        font-size: 0.9rem;
        font-weight: 100;
        line-height: 1.25rem;
        margin: 30px auto 0;
        width: 80%;
      }
    }

    #agree_to_terms {
      cursor: pointer;
      margin-right: 5px;
    }

    .terms-checkbox {
      font-size: 0.75rem;
    }
  }

  .cta {
    .marmo-info {
      margin-bottom: 2rem;
      display: none;

      @media screen and (max-width: 1180px) {
        display: block;
      }
    }

    .cover-image {
      height: 400px;
      max-width: 1800px;
      margin: 0 auto;

      @media screen and (max-width: 1180px) {
        display: none;
      }
    }
  }

  .info-container {
    margin-top: 100px;
  }

  .marmo-info {
    color: $black;
    font-family: Obibok;
    font-weight: 100;
    line-height: 2rem;
    padding: 50px 175px;
    text-align: center;

    a {
      color: var(--purple);
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    @media screen and (max-width: 1180px) {
      padding: 10px 8px;
      margin-bottom: 1.5rem;
    }
  }
}
