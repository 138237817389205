@import "globals";

#mm-song-versions {
  #mm-browse-beta-container {
    .mm-pagelist {
      ul {
        text-align: center;

        li {
          display: inline-block;
          margin: 0px 5px 0px;

          &.hidden {
            display: none;
          }

          a {
            color: black;
            font-family: NeuzeitGro, georgia;
            font-size: 0.9rem;
            display: inline-block;
            padding: 2px 7px 2px;
            text-decoration: none;

            @include mobile {
              font-size: 1.3rem;
            }

            &:hover,
            &:focus {
              color: $purple;
            }
          }

          &.mm-page-nav {
            a,
            span {
              font-family: NeuzeitGro, georgia;
              font-size: 1.5rem;
              color: var(--grey);
              padding: 0;
              margin: 0 2px;
              display: inline-block;
              width: 20px;
              position: relative;
              top: 1px;

              &:hover,
              &:focus {
                color: $purple;
              }
            }
          }

          &.prev {
            margin-right: 15px;
          }

          &.next {
            margin-left: 15px;
          }

          &.selected a {
            color: white;
            background-color: $purple;
          }

          &.hidden {
            display: none;
          }

          @include mobile {
            line-height: 25px;
          }
        }
      }
    }
  }
}

#mm-song-versions {
  #mm-browse-beta-container {
    #mm-search-input {
      .search-filter {
        .visible {
          display: block;
        }
      }
    }
  }
}

#mm-song-versions {
  #mm-browse-beta-container {
    .no-results {
      display: block;
    }
  }
}

#mm-song-versions {
  #mm-browse-beta-container {
    .loading {
      margin: 50px auto 0 auto;
    }
  }
}

#work-bench-beta {
  background: #eee;
  left: 0;
  position: relative;
  top: 0;
  z-index: 2;
  @include touch {
    @include accelerate;
  }
  @include mobile {
    background: #fff;
  }

  article.bench {
    height: 200px;
    padding-bottom: 40px;
    overflow: hidden; /* Hide content when height is 0 */
    transition: height 0.3s ease-out, padding-bottom 0.3s ease-out, opacity 0.3s ease-out; /* Transition for smooth slide down */
    @include contain-break;
    @include mobile {
      background: #eee;
      display: block;
      height: auto;
      padding: 0;
      width: 100%;
    }

    &.closed {
      padding-bottom: 30px;
      height: 0;
      padding-bottom: 0;
      opacity: 0; /* Hide content */
      transition: height 0.3s ease-out, padding-bottom 0.3s ease-out, opacity 0.3s ease; /* Transition for smooth slide up */
    }

    .remove svg {
      height: 20px;
      width: 20px;

      g {
        fill: #fff;
      }
    }
  }

  .admin-key {
    padding: 0 0 18px;
    @include contain-break;

    @include mobile {
      background: #f3f3f3;
      margin: 0;
      padding: 10px 0 0 0;
      width: 100%;
    }

    li.catalog {
      display: inline-block;
      padding: 5px 20px 5px 0;

      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
      }

      @include mobile {
        padding: 0 0 10px 0;
      }

      div {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .catalog-symbol {
      border-radius: 20px;
      height: 20px;
      margin-right: 5px;
      width: 20px;

      @include mobile {
        margin-left: 15px;
        height: 15px;
        width: 15px;
      }
    }

    .catalog-name {
      font-family: NeuzeitGro, georgia;
      font-size: 14px;

      @include mobile {
        font-size: 1.45em;
      }
    }
  }

  footer {
    background: #f8f8f8;
    @include contain-break;
    padding: 8px 20px 8px;
    display: flex !important;
    align-items: center;

    @include tablet {
      display: flex !important;
      align-items: center;
    }

    .contain {
      .sort {
        margin-left: auto;
      }
    }

    .browse-summary {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .group {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .vertical-group {
        display: flex;
        width: 100%;

        .search-sort-group {
          display: block;
          width: 50%;
        }

        @include breakpoint(1000px) {
          flex-direction: column;
        }
      }
    }

    .label,
    .result-count {
      color: #777;
      font-family: NeuzeitGro, georgia;
      font-size: 14px;
    }

    .result-count {
      @include relative;
      width: 120px;

      .spinner-target {
        @include opacity(0);
        background: rgba(248, 248, 248, 0.95);
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 80px;

        &.spinning {
          @include opacity(1);
        }

        .spinner {
          z-index: 1 !important;
        }
      }

      .hidden {
        display: none;
      }
    }

    .sorted-by,
    .searched-by {
      width: 100%;

      .label {
        width: 80px;
        padding-right: 4px;
      }
    }

    .filtered-by {
      @include breakpoint(1000px) {
        margin-top: 5px;
      }

      .label {
        width: 68px;
        padding-right: 4px;
      }
    }

    .sorted-by,
    .searched-by,
    .filtered-by {
      display: flex;
      &.active {
        display: flex;
      }

      @include breakpoint(650px) {
        flex-direction: column;
      }
    }

    .sorted-by li,
    .result-count li,
    .searched-by li,
    .filtered-by li,
    .results-picker li {
      @include lead(1.6, 1.4);
      font-family: NeuzeitGro, georgia;
      font-size: 14px;
      color: #333;
      cursor: pointer;
      float: left;
      left: 0;
      position: relative;
      top: 0;

      &[data-filter="page"] {
        display: none;
      }

      &.clear-all {
        font-style: italic;
      }

      &.hidden {
        display: none;
      }

      span {
        @include opacity(0);
        background-color: #f8f8f890;
        font-size: 1.2rem;
        font-weight: bold;
        position: absolute;
        right: 10px;
        text-align: right;
        top: -2px;
        width: 20px;
        @include transition(opacity, 150ms);
      }

      &:hover,
      &:focus {
        &.clear-all {
          text-decoration: underline;
        }
        span {
          @include opacity(1);
        }
      }

      &:after {
        content: "-";
        color: #ccc;
        padding: 0 3px;
      }

      &:nth-last-child(2):after,
      &.clear-all:after {
        visibility: hidden;
      }
    }

    .results-picker {
      display: flex;
      max-width: 50vw;
      margin-top: 12px;

      &.only-child {
        margin-top: 0;
      }

      &.hidden {
        display: none;
      }

      .label {
        width: 100px;
      }

      li {
        display: inline-block;

        a.selected {
          text-decoration: underline;
          color: $purple;
        }
      }
    }

    .sort {
      display: flex;
      justify-content: flex-end;
      margin: 15px 0 20px 0;
      font-family: NeuzeitGro, georgia;
      font-size: 14px;

      .rrs--options-visible {
        .rrs__options {
          max-height: unset;
        }
      }
      @include breakpoint(900px) {
        display: none;
      }

      @media screen and (max-width: 580px) {
        .rrs__label {
          min-width: auto;
          margin-right: 20px;
        }
      }
    }
  }

  footer.mobile {
    background: #f3f3f3;

    .count-wrapper {
      &.hidden {
        display: none;
      }
    }
  }

  section.filter-search-nav {
    display: block;
    z-index: 1;

    @include mobile {
      display: none;
    }
    &.current {
      @include mobile {
        display: block;
        background: #f3f3f3;
      }
    }
    &.filter-wrap {
      left: 0;
      position: relative;
      top: 0;
    }
    &.search-wrap {
      form {
        margin: 0 auto;
        margin-top: 30px;
        width: 85%;

        @include mobile {
          padding-top: 0px;
          margin-top: 0px;
        }
      }
    }
  }

  nav.tabs {
    &.mobile {
      height: 0px;
      @include mobile {
        height: 44px;
        width: 100%;
      }
    }

    &.desktop {
      height: 50px;
      width: 100%;
      @include mobile {
        height: 0px;
      }
      li {
        line-height: 50px;
      }
    }

    span.new::before {
      content: "NEW";
      font-family: NeuzeitGro, georgia;
      font-size: 11.2px;
      background-color: #21928e;
      border-radius: 100px;
      color: #fff;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
      text-decoration: none;
      width: 45px;
      padding: 4px 6px 3px 7px;
      margin-right: 7px;
    }

    ul {
      height: 100%;
      overflow: hidden;
      width: 100%;
    }

    li {
      background: #fff;
      display: block;
      float: left;
      height: 100%;
      width: 50%;

      @include transition(background, 100ms);
      @include mobile {
        line-height: 44px;
      }

      a {
        @include kern(80);
        font-family: Obibok;
        font-size: 0.7rem;
        font-weight: 700;
        color: $black;
        display: block;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        width: 100%;

        @include mobile {
          font-size: 1.1em;
        }
      }

      &:hover,
      &:focus {
        background: #f8f8f8;
      }

      &.current {
        background: $green;
        a {
          color: #fff;
        }
      }
    }

    .filter {
      a {
        float: right;
      }
    }
  }

  nav.filter {
    @include relative;
    background: #eee;
    height: 40px;
    padding: 25px 0;
    text-align: center;

    a {
      text-decoration: none;
    }

    ul {
      display: flex;
      width: 78.9473684%;
      margin: auto;

      @include breakpoint(1000px) {
        width: 85%;
        margin-left: 12%;
      }

      @include mobile {
        display: inline-block;
        margin: 0 auto;
        width: 100%;
        margin-left: 0%;
      }

      li {
        float: left;
        display: block;
        text-align: center;
        margin: 0 5px;

        &.mood {
          width: 9.333%;
          @include calc(width, "8.333% - 5px");
        }

        &.length,
        &.energy {
          width: 11.444%;
          @include calc(width, "11.444% - 5px");
        }
        &.genres {
          width: 11.444%;
          @include calc(width, "11.444% - 5px");
        }

        &.arc {
          width: 7.666%;
          @include calc(width, "7.666% - 5px");
        }

        &.vocals {
          width: 10.888%;
          @include calc(width, "10.888% - 5px");
        }

        &.instruments {
          width: 17%;
          @include calc(width, "17.222% - 5px");
        }

        &.customizable {
          width: 16.444%;
          @include calc(width, "16.444% - 5px");
        }

        &.sort,
        &.internal {
          width: 5%;
          @include calc(width, "5% - 5px");
          min-width: 40px;

          a {
            height: 36px;
            .icon {
              svg path,
              svg g {
                fill: $black;
                @include transition(fill, 100ms);

                .icon-border {
                  display: none;
                }
              }
            }

            &:hover,
            &:focus {
              .icon {
                svg g {
                  fill: #fff;
                  @include transition(fill, 100ms);
                }
              }
            }
          }
        }

        &.sort {
          min-width: 15%;
          @include breakpoint(1000px) {
            min-width: 40px;
          }
          label.sort-icon-label {
            vertical-align: top;
            cursor: pointer;
            @include breakpoint(1000px) {
              display: none;
              margin-right: 5px;
            }
          }
        }

        @include tablet-down {
          margin: 0 4px;
        }

        @include mobile {
          margin: 0;
          margin-left: 15px;
          width: 76% !important;
          @include calc(width, "100% - 80px");
        }
      }

      a {
        @include kern(80);
        font-family: Obibok;
        font-size: 0.7rem;
        font-weight: 100;
        background: #fff;
        border: 2px solid #fff;
        border-radius: 20px;
        color: $black;
        display: block;
        line-height: 36px;
        padding: 0;
        text-align: center;
        text-transform: uppercase;
        width: 95%;
        @include calc(width, "100% - 4px");
        @include mult-trans((background 100ms linear, color 100ms linear, border 100ms linear));

        @include mobile {
          display: none;
          font-size: 0.9rem;
          text-align: center;
        }

        &.set {
          border: 2px solid $green;
        }

        &:hover,
        &.current,
        &:focus {
          background: $green;
          border: 2px solid $green;
          color: #fff;

          .icon svg path,
          .icon svg g {
            fill: #fff !important;
          }
        }

        &.current {
          @include mobile {
            @include relative;
            display: block;
          }
        }
      }
    }

    ul.internal {
      @include breakpoint(1000px) {
        width: 85%;
        margin: auto;
      }

      @include mobile {
        display: inline-block;
        margin: 0 auto;
        width: 100%;
        margin-left: 0%;
      }

      li {
        &.mood {
          width: 9.333%;
          @include calc(width, "8.333% - 5px");
        }

        &.genres,
        &.length,
        &.energy {
          width: 10.444%;
          @include calc(width, "10.444% - 5px");
        }

        &.arc {
          width: 7.666%;
          @include calc(width, "7.666% - 5px");
        }

        &.vocals {
          width: 10.888%;
          @include calc(width, "9.888% - 5px");
        }

        &.instruments {
          width: 15.222%;
          @include calc(width, "15.222% - 5px");
        }

        &.customizable {
          width: 16.444%;
          @include calc(width, "16.444% - 5px");
        }

        &.sort,
        &.internal {
          width: 5%;
          @include calc(width, "5% - 5px");
          min-width: 40px;

          a {
            height: 36px;
            .icon {
              svg path,
              svg g {
                fill: $black;
                @include transition(fill, 100ms);

                .icon-border {
                  display: none;
                }
              }
            }

            &:hover,
            &:focus {
              .icon {
                svg g {
                  fill: #fff;
                  @include transition(fill, 100ms);
                }
              }
            }
          }
        }

        &.sort {
          min-width: 110px;
          @include breakpoint(1000px) {
            min-width: 40px;
          }
          label.sort-icon-label {
            vertical-align: top;
            cursor: pointer;
            @include breakpoint(1000px) {
              display: none;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .connect-label {
      @include tablet-down {
        display: none;
      }
    }
  }

  .info {
    position: absolute;
    top: 26px;
    right: 0px;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 20px;
    height: 33px;
    width: 33px;

    &.not-active {
      visibility: hidden;
    }

    @include tablet-down {
      right: 48px;
    }
    @include mobile {
      right: 18px;
    }

    &[data-touch] {
      display: none;
      @include mobile {
        display: block;
      }
    }

    .icon {
      height: 100%;
      width: 100%;

      svg g {
        @include transition(opacity, 100ms);
        fill: $black;

        @include retina {
          padding-right: 2px;
        }
      }
    }

    @include not-mobile {
      &:hover,
      &:focus {
        background: $green;
        border: 2px solid $green;

        svg g {
          fill: #fff;
        }
      }
    }

    &.active {
      background: $green;
      border: 2px solid $green;

      svg g {
        fill: #fff;
      }
    }
  }

  #mm-filter-info {
    @include opacity(0);
    background: rgba(238, 238, 238, 0.95);
    height: 330px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    visibility: hidden;
    z-index: 3;
    @include transition(opacity, 150ms);
    @include mobile {
      height: 100%;
    }

    &.showing {
      @include opacity(1);
    }

    &.visible {
      visibility: visible;
    }

    article {
      color: $black;
      display: none;
      height: 100%;
      left: 50%;
      margin-left: -225px;
      position: absolute;
      top: 90px;
      width: 450px;

      @include mobile {
        left: 0;
        margin: 0 15px;
        width: auto;
      }

      &.showing {
        display: block;
      }

      p {
        @include lead(1.9, 1.6);
        font-family: NeuzeitGro, georgia;
        font-size: 1.6em;
        padding: 3px 0;
      }
    }
  }

  form {
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 2;

    @include mobile {
      margin: 0 15px;
      width: auto;
    }

    fieldset {
      display: none;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      @include mobile {
        @include relative;
        display: none;
        padding-bottom: 25px;
      }

      li.actionable {
        @include no-select;

        a {
          .count {
            background-color: rgba(0, 0, 0, 0.1);
            color: var(--darkest-grey);
            font-family: Helvetica, Arial, sans-serif;
            font-size: 10px;
            font-weight: normal;
            padding: 3px 5px;
            border-radius: 10px;
            margin-left: 5px;
          }
        }

        &:hover,
        &:focus {
          a {
            .count {
              color: var(--lighter-grey);
            }
          }
        }

        figcaption {
          .count {
            position: relative;
            top: -3px;
          }
        }
      }

      &.current {
        display: block;
        z-index: 2;
        @include mobile {
          display: block;
        }

        &#internal {
          display: flex;
        }
      }

      &#arc {
        canvas {
          left: 50%;
          margin-left: -275px;
          position: absolute;
          top: 0;
          @include mobile {
            @include relative;
            margin-left: 0;
            width: 100%;
          }
        }

        ul {
          float: left;
          height: 100%;
          margin-right: 43px;
          width: 22.11%;
          @include mobile {
            margin-right: 0;
            margin-top: 25px;
            width: 50%;
            @include calc(width, "50% - 2px");
          }

          &:last-child {
            float: right;
            margin-right: 0;
          }

          li {
            @include relative;
            margin-bottom: 6px;
            height: 61px;
            width: 100%;
            @include mobile {
              height: 50px;
              margin-bottom: 4px;
            }

            .remove {
              @include opacity(0);
              cursor: pointer;
              display: block;
              position: absolute;
              right: 15px;
              top: 15px;
              z-index: 10;
              @include transition(opacity, 100ms);
              @include mobile {
                @include transition(none);
              }
            }

            a {
              font-family: Obibok;
              font-size: 0.9rem;
              font-weight: 100;
              height: 100%;
              background: #fff;
              border: 2px solid #fff;
              color: $black;
              display: block;
              line-height: 61px;
              text-align: center;
              text-decoration: none;
              @include transition(background color border-color, 100ms);

              @include mobile {
                height: 46px;
                font-size: 0.75rem;
                line-height: 46px;
                overflow: hidden;
                white-space: nowrap;
                @include transition(none);
              }

              @include not-mobile {
                &:hover,
                &:focus {
                  background: $purple;
                  border-color: $purple;
                  color: #fff;
                  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
                }
              }
            }

            &.selected a,
            &.selected a:hover,
            &.selected a:focus {
              background: #fff;
              border-color: $purple;
              color: $black;
              text-shadow: none;
            }

            &.selected a:hover,
            &.selected a:focus {
              background: $purple;
              color: #fff;
              text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
            }

            @include not-mobile {
              &.selected:hover {
                .remove {
                  @include opacity(1);
                }
              }
            }

            @include mobile {
              &.selected {
                a {
                  background: $purple;
                  color: #fff;
                  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
                }

                .remove {
                  right: 2px;
                  top: 4px;
                  padding-right: 2px;
                  @include opacity(1);
                }
              }
            }
          }
        }
      }

      &#length {
        $thumb-size: 80px;
        $mobile-thumb-size: 40px;

        @mixin track-styles {
          appearance: none;
          background: transparent;
          border: transparent;
        }

        @mixin thumb-styles {
          appearance: none;
          pointer-events: all;
          width: $thumb-size;
          height: $thumb-size;
          border-radius: 0px;
          border: 0 none;
          cursor: grab;
          background-color: $purple;

          &:active {
            cursor: grabbing;
          }
        }

        #root {
          max-width: 500px;
          padding: 12px;
          margin: auto;
        }

        .wrapper {
          position: relative;
          display: flex;
          align-items: center;
          margin: 40px calc(#{$thumb-size} / 2);
        }

        .input-wrapper {
          width: calc(100% + #{$thumb-size});
          margin: 0 calc(#{$thumb-size} / -2);
          position: absolute;
          height: $thumb-size;
        }

        .control-wrapper {
          width: 100%;
          position: absolute;
          height: $thumb-size;
        }

        .input {
          position: absolute;
          width: 100%;
          pointer-events: none;
          appearance: none;
          height: 100%;
          opacity: 0;
          z-index: 10;
          padding: 0;

          &.active {
            z-index: 20;
          }

          &::-ms-track {
            @include track-styles;
          }

          &::-moz-range-track {
            @include track-styles;
          }

          &:focus::-webkit-slider-runnable-track {
            @include track-styles;
          }

          &::-ms-thumb {
            @include thumb-styles;
          }

          &::-moz-range-thumb {
            @include thumb-styles;
          }

          &::-webkit-slider-thumb {
            @include thumb-styles;
          }
        }

        .rail {
          position: absolute;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
          height: 35px;
          border-radius: 3px;
          -webkit-mask: radial-gradient(circle closest-side, #000 94%, #0000) 0 0/5% 100%;
          background: linear-gradient($purple 0 0) 0/0% no-repeat #ccc;
          @include transition(background, 150ms);
        }

        .inner-rail {
          position: absolute;
          height: 100%;
          background: $purple;
          opacity: 0.5;
          @include transition(background, 150ms);

          &.inactive {
            background: unset;
            @include transition(background, 150ms);
          }
        }

        .control {
          width: $thumb-size;
          height: $thumb-size;
          line-height: $thumb-size;
          border-radius: 50%;
          position: absolute;
          text-align: center;
          font-size: 1.25rem;
          background: white;
          top: 50%;
          margin-left: calc(#{$thumb-size} / -2);
          transform: translate3d(0, -50%, 0);
          z-index: 2;

          @include mobile {
            height: $mobile-thumb-size;
            width: $mobile-thumb-size;
            font-size: 0.75rem;
            line-height: $mobile-thumb-size;
            margin-left: calc(#{$mobile-thumb-size} / -2);
          }

          &.active {
            z-index: 4;
            outline: none;
            border-color: $purple;
            box-shadow: 0 0 10px $purple;
          }
        }

        p {
          font-family: NeuzeitGro, georgia;
          font-size: 16px;
          color: $black;
          margin-top: 20px;
          text-align: center;

          @include mobile {
            margin: 0 auto;
            font-size: 1.3em;
            width: 50%;
          }
        }

        .mm-filter-length {
          height: 80px;
          left: 0;
          position: relative;
          top: 25px;
          width: 100%;

          em {
            font-family: NeuzeitGro, georgia;
            font-size: 14px;
            display: block;
            font-style: italic;
            position: absolute;
            text-align: center;
            top: -$thumb-size;
            width: 80px;
            @include mobile {
              top: -$mobile-thumb-size;
            }

            &.min {
              left: 0;
            }

            &.max {
              right: 0;
            }
          }

          ul {
            height: 80px;
            display: table;
            width: 100%;

            li {
              display: table-cell;
              text-align: center;
              vertical-align: middle;
              width: 6.25%;

              span {
                @include fluid-square(50%);
                background: #ccc;
                border-radius: 50%;
                display: block;
                left: 25%;
                @include transition(background, 150ms);
              }

              &.highlight span {
                background: $purple;
              }
            }
          }

          .actionable {
            font-family: Obibok;
            font-size: 1.25rem;
            @include grab;
            background: #fff;
            border-radius: 50%;
            color: $black;
            height: 80px;
            line-height: 80px;
            position: absolute;
            text-align: center;
            top: 0;
            width: 80px;

            @include mobile {
              height: 40px;
              font-size: 0.75rem;
              line-height: 40px;
              top: 25%;
              width: 40px;
            }

            &.dragging {
              @include grabbing;
            }

            &.min {
              left: 0;
            }

            &.max {
              transform: translateX(100%);
            }
          }
        }
      }

      &#mood {
        ul {
          display: grid;
          grid-template-columns: repeat(11, 1fr);
          gap: 2px;

          @include breakpoint(1200px) {
            grid-template-columns: repeat(9, 1fr);

            li {
              &.sm-desktop-hidden {
                display: none;
              }
            }
          }

          @include breakpoint(900px) {
            grid-template-columns: repeat(7, 1fr);

            li {
              &.sm-desktop-hidden,
              &.tablet-hidden {
                display: none;
              }
            }
          }

          @include mobile {
            grid-template-columns: repeat(4, 1fr);
            gap: 6px;

            li {
              &.sm-desktop-hidden,
              &.tablet-hidden,
              &.mobile-hidden {
                display: none;
              }
            }
          }

          @include breakpoint(450px) {
            grid-template-columns: repeat(3, 1fr);

            li {
              &.sm-desktop-hidden,
              &.tablet-hidden,
              &.mobile-hidden,
              &.sm-mobile-hidden {
                display: none;
              }
            }
          }

          li {
            height: 35px;
            background-color: white;

            &.selected {
              background: $purple;

              a {
                color: #fff;
              }

              &:hover {
                background: $purple;

                a {
                  color: #fff;
                  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
                }
              }
            }

            &.selected-negative {
              background: #fff;

              a {
                color: rgba(58, 53, 50, 0.5);
                text-decoration: line-through;
              }

              &:hover {
                background: rgba(58, 53, 50, 0.5);

                a {
                  color: #fff;
                  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
                }
              }
            }

            &.disabled {
              display: none;
            }

            a {
              font-family: Obibok;
              font-size: 0.8rem;
              font-weight: 100;
              color: $black;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              text-align: center;
              text-decoration: none;
              @include transition(background color, 100ms);

              @include not-mobile {
                &:hover,
                &:focus {
                  background: $purple;
                  color: #fff;
                  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
                }
              }

              @include mobile {
                @include transition(none);
                font-size: 1.3em;
              }
            }
          }
        }
      }

      &#energy {
        ol {
          height: 100%;
          width: 100%;
          @include mobile {
            width: auto;
          }

          li {
            @include relative;
            height: 100%;
            float: left;
            margin-right: 6px;
            width: 20%;
            @include calc(width, "20% - 6px");
            @include mobile {
              float: none;
              height: 50px;
              margin: 0;
              width: 100%;
            }

            .energy-wrapper {
              @include mobile {
                display: none;
              }
              --center: translate(-50%, -50%);
              left: 0;
              margin: 40px auto 10px;
              position: relative;
              top: 0;
              z-index: 2;
              width: 90px;
              height: 90px;
              transition: margin 0.1s cubic-bezier(0, 0, 0.06, 1.02);

              svg {
                fill: $black;
              }

              svg.selected {
                fill: $purple;
              }

              .center {
                position: absolute;
                width: 45px;
                height: 45px;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: var(--center);
              }

              .outer-arc,
              .inner-arc {
                position: absolute;
                border-radius: 50%;
                border: 4px solid;
              }

              .outer-spin,
              .inner-spin {
                position: absolute;
                top: 50%;
                left: 50%;
              }

              .inner-arc {
                width: 62px;
                height: 62px;
              }

              .outer-arc {
                width: 80px;
                height: 80px;
              }

              /* Default settings */
              .inner-spin {
                animation: spin 3s linear infinite;
                animation-play-state: paused;
              }

              .outer-spin {
                animation: spin 4s linear infinite;
                animation-direction: reverse;
                animation-play-state: paused;
              }

              .inner-arc_start-a {
                border-color: transparent transparent transparent orange;
                /* NOTE: the order here very much matters!  */
                transform: var(--center) rotate(65deg);
              }
              .inner-arc_end-a {
                border-color: purple transparent transparent transparent;
                transform: var(--center) rotate(45deg);
              }

              .inner-arc_start-b {
                border-color: transparent transparent transparent red;
                transform: var(--center) rotate(65deg) scale(-1, -1);
              }
              .inner-arc_end-b {
                border-color: green transparent transparent transparent;
                transform: var(--center) rotate(45deg) scale(-1, -1);
              }

              .outer-arc_start-a {
                border-color: transparent transparent transparent red;
                /* NOTE: the order here very much matters! */
                transform: var(--center) rotate(65deg);
              }
              .outer-arc_end-a {
                border-color: green transparent transparent transparent;
                transform: var(--center) rotate(45deg);
              }
              .outer-arc_start-b {
                border-color: transparent transparent transparent orange;
                transform: var(--center) rotate(65deg) scale(-1, -1);
              }
              .outer-arc_end-b {
                border-color: purple transparent transparent transparent;
                transform: var(--center) rotate(45deg) scale(-1, -1);
              }

              &.low {
                .inner-spin {
                  animation: spin 6s linear infinite;
                  animation-play-state: paused;
                }

                .outer-spin {
                  animation: unset;
                }

                .inner-arc_start-a {
                  border-color: transparent transparent transparent $black;
                  transform: var(--center) rotate(300deg);
                  /* NOTE: Hacky, but makes the low's arc smaller  */
                  clip-path: polygon(100% 50%, 0% 0%, 0% 60%);
                }
                .inner-arc_end-a,
                .inner-arc_start-b,
                .inner-arc_end-b,
                .outer-arc_start-a,
                .outer-arc_end-a,
                .outer-arc_start-b,
                .outer-arc_end-b {
                  display: none;
                }
              }
              &.low-medium {
                .inner-spin {
                  animation: spin 3s linear infinite;
                  animation-play-state: paused;
                }

                .outer-spin {
                  animation: unset;
                }

                .inner-arc_start-a {
                  border-color: transparent transparent transparent $black;
                  /* NOTE: the order here very much matters!  */
                  transform: var(--center) rotate(65deg);
                }
                .inner-arc_end-a,
                .inner-arc_start-b,
                .inner-arc_end-b,
                .outer-arc_start-a,
                .outer-arc_end-a,
                .outer-arc_start-b,
                .outer-arc_end-b {
                  display: none;
                }
              }
              &.medium {
                .inner-spin {
                  animation: spin 2s linear infinite;
                  animation-play-state: paused;
                }

                .outer-spin {
                  animation: unset;
                }

                .inner-arc_start-a {
                  border-color: transparent transparent transparent $black;
                  /* NOTE: the order here very much matters!  */
                  transform: var(--center) rotate(65deg);
                }
                .inner-arc_end-a {
                  display: none;
                }

                .inner-arc_start-b {
                  border-color: transparent transparent transparent $black;
                  transform: var(--center) rotate(65deg) scale(-1, -1);
                }
                .inner-arc_end-b {
                  display: none;
                }

                .outer-arc_start-a,
                .outer-arc_end-a,
                .outer-arc_start-b,
                .outer-arc_end-b {
                  display: none;
                }
              }
              &.medium-high {
                .inner-spin {
                  animation: spin 1s linear infinite;
                  animation-play-state: paused;
                }

                .outer-spin {
                  animation: spin 2s linear infinite;
                  animation-direction: reverse;
                  animation-play-state: paused;
                }

                .inner-arc_start-a {
                  border-color: transparent transparent transparent $black;
                  /* NOTE: the order here very much matters!  */
                  transform: var(--center) rotate(65deg);
                }
                .inner-arc_end-a {
                  display: none;
                }

                .inner-arc_start-b {
                  border-color: transparent transparent transparent $black;
                  transform: var(--center) rotate(65deg) scale(-1, -1);
                }
                .inner-arc_end-b {
                  display: none;
                }

                .outer-arc_start-a {
                  border-color: transparent transparent transparent $black;
                  /* NOTE: the order here very much matters!  */
                  transform: var(--center) rotate(300deg);
                }
                .outer-arc_end-a,
                .outer-arc_start-b,
                .outer-arc_end-b {
                  display: none;
                }
              }
              &.high {
                .inner-spin {
                  animation: spin 0.5s linear infinite;
                  animation-play-state: paused;
                }

                .outer-spin {
                  animation: spin 1s linear infinite;
                  animation-direction: reverse;
                  animation-play-state: paused;
                }

                .inner-arc_start-a {
                  border-color: transparent transparent transparent $black;
                  /* NOTE: the order here very much matters!  */
                  transform: var(--center) rotate(65deg);
                }

                .inner-arc_start-b {
                  border-color: transparent transparent transparent $black;
                  transform: var(--center) rotate(65deg) scale(-1, -1);
                }

                .inner-arc_end-a,
                .inner-arc_end-b {
                  display: none;
                }

                .outer-arc_start-a {
                  border-color: transparent transparent transparent $black;
                  /* NOTE: the order here very much matters!  */
                  transform: var(--center) rotate(65deg);
                }
                .outer-arc_end-a {
                  border-color: $black transparent transparent transparent;
                  transform: var(--center) rotate(35deg);
                }
                .outer-arc_start-b {
                  border-color: transparent transparent transparent $black;
                  transform: var(--center) rotate(65deg) scale(-1, -1);
                }
                .outer-arc_end-b {
                  border-color: $black transparent transparent transparent;
                  transform: var(--center) rotate(35deg) scale(-1, -1);
                }
              }
            }

            .remove {
              @include opacity(0);
              cursor: pointer;
              display: block;
              position: absolute;
              right: 15px;
              top: 15px;
              z-index: 1;
              @include transition(opacity, 100ms);

              @include mobile {
                @include transition(none);
                display: none;
              }
            }

            &:last-child {
              margin-right: 0;

              @include mobile {
                width: 100%;
              }
            }

            &.low a,
            &.low-medium a,
            &.medium a,
            &.medium-high a,
            &.high a {
              border: 2px solid #fff;
              color: $black;
              display: block;
              font-family: Obibok;
              font-size: 0.9rem;
              font-weight: 100;
              height: 100%;
              text-align: center;
              text-decoration: none;
              width: 100%;
              @include transition(background color, 100ms);

              @include mobile {
                font-size: 0.75rem;
                line-height: 50px;
                text-align: left;
                @include transition(none);

                figcaption {
                  text-indent: 15px;
                }
              }

              @include not-mobile {
                &:hover,
                &:focus {
                  background: $purple;
                  border-color: $purple;
                  color: #fff;
                  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);

                  canvas {
                    transform: translateY(-10px);
                    @include transition(transform, 100ms);
                  }
                }
              }
            }

            &.low a,
            &.selected.low a {
              background: #f1f1f1;
              border-color: #f1f1f1;
              .default {
                @include m-center-sprite(filter_energy_low_default);
              }
              .active {
                @include m-center-sprite(filter_energy_low_rollover);
              }
            }

            &.low-medium a,
            &.selected.low-medium a {
              background: #f5f5f5;
              border-color: #f5f5f5;
              .default {
                @include m-center-sprite(filter_energy_lowmed_default);
              }
              .active {
                @include m-center-sprite(filter_energy_lowmed_rollover);
              }
            }

            &.medium a,
            &.selected.medium a {
              background: #f8f8f8;
              border-color: #f8f8f8;
              .default {
                @include m-center-sprite(filter_energy_med_default);
              }
              .active {
                @include m-center-sprite(filter_energy_med_rollover);
              }
            }

            &.medium-high a,
            &.selected.medium-high a {
              background: #fcfcfc;
              border-color: #fcfcfc;
              .default {
                @include m-center-sprite(filter_energy_medhigh_default);
              }
              .active {
                @include m-center-sprite(filter_energy_medhigh_rollover);
              }
            }

            &.high a,
            &.selected.high a {
              background: #fff;
              border-color: #fff;
              .default {
                @include m-center-sprite(filter_energy_high_default);
              }
              .active {
                @include m-center-sprite(filter_energy_high_rollover);
              }
            }

            &.selected a {
              border-color: $purple !important;
              color: $purple;
              text-shadow: none;

              .icon .default {
                @include opacity(1);
              }
              .icon .active {
                @include opacity(0);
              }

              svg {
                fill: $purple;
              }
            }

            &.selected a:hover,
            &.selected a:focus {
              background: $purple;
              color: #fff;
              text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
              .icon .default {
                @include opacity(0);
              }
              .icon .active {
                @include opacity(1);
              }
            }

            @include not-mobile {
              &.selected:hover {
                .remove {
                  @include opacity(1);
                }
              }
            }

            @include mobile {
              &.selected {
                background: $purple;
                border-color: $purple;
                color: #fff;
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);

                .icon .default {
                  @include opacity(0);
                }

                .icon .active {
                  @include opacity(1);
                }

                .remove {
                  @include opacity(1);
                  display: block;
                  top: 4px;
                  right: 0;
                  padding-right: 2px;

                  svg g {
                    fill: $black;
                  }
                }
              }
            }

            canvas {
              display: block;
              left: 0;
              margin: 40px auto 10px;
              position: relative;
              top: 0;
              z-index: 2;
            }

            .icon {
              @include relative;
              height: 50px;
              display: block;
              float: right;
              left: -50px;
              width: auto;

              .default,
              .active {
                @include transition(opacity, 100ms);
                @include mobile {
                  @include transition(none);
                }
              }
              .default {
                @include opacity(1);
              }
              .active {
                @include opacity(0);
              }
            }
          }

          li:hover {
            .energy-wrapper {
              margin: 30px auto 20px;
              svg {
                fill: white;
              }

              .inner-spin {
                animation-play-state: running;
              }

              .outer-spin {
                animation-play-state: running;
              }
            }
          }
        }
      }

      &#vocals,
      &#internal,
      &#customizable {
        ul {
          height: 100%;
          width: 100%;
        }

        li {
          @include relative;
          display: block;
          float: left;
          height: 100%;
          margin-right: 6px;
          margin-bottom: 6px;

          .remove {
            @include opacity(0);
            cursor: pointer;
            display: block;
            position: absolute;
            right: 15px;
            top: 15px;
            z-index: 10;

            @include transition(opacity, 100ms);
            @include mobile {
              @include transition(none);
            }
          }

          &.no {
            @include mobile {
              float: right;
            }
          }

          a {
            font-family: Obibok;
            font-size: 1.2rem;
            font-weight: 100;
            background: #fff;
            border: 2px solid #fff;
            color: $black;
            display: block;
            height: 100%;
            text-align: center;
            text-decoration: none;
            width: 100%;
            @include transition(background color border-color, 100ms);

            .icon {
              transform: translateY(0px);
              @include transition(transform, 100ms);
            }

            @include mobile {
              @include transition(none);
              font-size: 0.9rem;
            }

            @include not-mobile {
              &:hover,
              &:focus {
                background: $purple;
                border-color: $purple;
                color: #fff;

                .icon svg g {
                  fill: #fff;
                }

                .icon {
                  transform: translateY(-10px);
                }
              }
            }

            .icon svg g {
              fill: $black;
            }
          }

          &.selected a {
            background: #fff;
            border-color: $purple;
            color: $purple;

            .icon svg g {
              fill: $purple;
            }
          }

          &.selected a:hover,
          &.selected a:focus {
            background: $purple;
            color: #fff;

            .icon svg g {
              fill: #fff;
            }
          }

          &.selected-negative a {
            border-color: rgba(58, 53, 50, 0.5);
            color: rgba(58, 53, 50, 0.5);
            text-decoration: line-through;
            @include not-mobile {
              &:hover {
                background: rgba(58, 53, 50, 0.5);
                border-color: rgba(58, 53, 50, 0);
                color: #fff;
                text-decoration: line-through;
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
              }
            }
            @include mobile {
              background: rgba(58, 53, 50, 0.5);
              border-color: rgba(58, 53, 50, 0);
              color: #fff;
              text-decoration: line-through;
              text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
            }

            .default {
              filter: invert(60%);
            }
          }

          @include mobile {
            &.selected {
              a {
                background: $purple;
                color: #fff;

                .remove {
                  @include opacity(1);
                  display: block;
                  padding-right: 2px;
                  top: 6px;
                  right: 0;
                }

                .icon svg g {
                  fill: #fff;
                }
              }
            }
          }
        }
      }

      &#internal {
        ul {
          height: 50%;
          width: 60%;
        }
        ol {
          display: flex;
          justify-content: space-evenly;
          width: 40%;
          border-left: solid lightgrey 1px;
        }

        li a {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          cursor: pointer;
          font-size: 0.9rem;

          .count {
            margin-left: 0;
            margin-top: 5px;
          }
        }
      }

      &#sort {
        flex-direction: row;
        margin: auto;

        ul {
          display: flex;
          flex-wrap: wrap;
          list-style-type: none;
          padding: 0;
          margin: 0;
          @include mobile {
            display: flex;
          }
        }

        li {
          float: left;
          height: 50px;
          margin-right: 10px;
          display: flex;
          flex: 0 0 calc(50% - 10px);
          box-sizing: border-box;
          margin-bottom: 10px;
          text-align: center;
          width: 25%;
          @include calc(width, "25% - 6px");
          @include mobile {
            @include calc(width, "50% - 6px");
            height: 50px;
            margin: 0px 5px;
            margin-bottom: 12px;
            &:nth-child(even) {
              float: right;
            }
          }

          &:first-of-type {
            flex: 0 0 calc(100% - 10px);
          }

          a {
            font-family: Obibok;
            font-size: 0.9rem;
            font-weight: 100;
            background: #fff;
            border: 2px solid #fff;
            color: $black;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
            text-decoration: none;
            width: 100%;
            @include transition(background color, 100ms);

            @include not-mobile {
              &:hover,
              &:focus {
                background: $purple;
                border-color: $purple;
                color: #fff;
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
              }
            }

            &.selected {
              border-color: $purple;
            }
          }

          @include mobile {
            @include transition(none);
            font-size: 1.3em;
          }
        }

        @include relative;
        &.selected a {
          border-color: $purple;
          @include not-mobile {
            &:hover {
              background: $purple;
              color: #fff;
              text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
            }
          }
          @include mobile {
            background: $purple;
            color: #fff;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
          }
        }
      }

      &#internal,
      &#vocals {
        li {
          figure {
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;

            figcaption {
              padding: 5px 0 15px;

              @include mobile {
                padding: 10px 0 35px;
              }
            }
          }
        }

        .true,
        .female,
        .male {
          figure .icon {
            svg {
              height: 100%;
              width: 60px;
            }

            @include mobile {
              svg {
                width: 35px;
              }
            }
          }
        }

        .false figure .icon {
          svg {
            height: 100px;
            width: 100px;
          }

          @include mobile {
            svg {
              height: 50px;
              width: 50px;
            }
          }
        }
      }

      &#internal {
        li {
          width: 30%;
          height: 95%;
          @include mobile {
            @include fluid-square;
            @include calc(width, "13.5%");
            padding-bottom: 17em;
          }
        }
      }

      &#vocals {
        li {
          width: 25%;
          @include calc(width, "25% - 6px");
          @include mobile {
            @include fluid-square;
            @include calc(width, "25% - 6px");
            padding-bottom: 17em;
          }
        }
      }

      &#customizable {
        li {
          width: 50%;
          @include calc(width, "50% - 6px");
          @include mobile {
            @include fluid-square;
            @include calc(width, "50% - 6px");
            padding-bottom: 17em;
          }

          figure {
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;

            figcaption {
              padding: 25px 0 30px;

              @include mobile {
                padding-top: 20px;
              }
            }
          }
        }

        figure .icon {
          svg {
            height: 100%;
            width: 72px;
          }

          @include mobile {
            svg {
              width: 50px;
            }
          }
        }
      }

      &#internal,
      &#instruments,
      &#genres,
      &#mood {
        li {
          @include relative;
          &.selected a {
            border-color: $purple;
            @include not-mobile {
              &:hover {
                background: $purple;
                color: #fff;
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
              }
            }
            @include mobile {
              background: $purple;
              color: #fff;
              text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
            }
          }

          &.selected-negative a {
            border-color: rgba(58, 53, 50, 0.5);
            color: rgba(58, 53, 50, 0.5);
            text-decoration: line-through;
            @include not-mobile {
              &:hover {
                background: rgba(58, 53, 50, 0.5);
                border-color: rgba(58, 53, 50, 0);
                color: #fff;
                text-decoration: line-through;
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
              }
            }
            @include mobile {
              background: rgba(58, 53, 50, 0.5);
              border-color: rgba(58, 53, 50, 0);
              color: #fff;
              text-decoration: line-through;
              text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
            }

            .default {
              filter: invert(60%);
            }
          }
        }
      }

      &#instruments {
        .instrument-mute {
          position: absolute;
          top: -64px;
          background: #fff;
          border: 2px solid #fff;
          border-radius: 20px;
          height: 33px;
          width: 33px;

          @include breakpoint(1000px) {
            display: none;
          }

          .icon {
            cursor: pointer;

            svg g {
              @include transition(opacity, 100ms);
              fill: $black;

              .icon-border {
                display: none;
              }
            }

            .default {
              display: none;

              &.selected {
                display: block;

                &:hover {
                  display: block;
                }
              }
            }
            .muted {
              display: none;

              &.selected {
                display: block;

                &:hover {
                  display: block;
                }
              }
            }
          }
        }

        li {
          float: left;
          height: 50px;
          margin-right: 6px;
          margin-bottom: 6px;
          width: 25%;
          @include calc(width, "25% - 6px");
          @include mobile {
            @include calc(width, "50% - 6px");
            height: 50px;
            margin-bottom: 12px;
            margin-right: 0;
            &:nth-child(even) {
              float: right;
            }
          }

          &:nth-child(4),
          &:nth-child(8),
          &:nth-child(12),
          &:nth-child(16) {
            margin-right: 0;
          }

          a {
            font-family: Obibok;
            font-size: 0.9rem;
            font-weight: 100;
            background: #fff;
            border: 2px solid #fff;
            color: $black;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
            text-decoration: none;
            width: 100%;
            @include transition(background color, 100ms);

            @include not-mobile {
              &:hover,
              &:focus {
                background: $purple;
                border-color: $purple;
                color: #fff;
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
              }
            }

            @include mobile {
              @include transition(none);
              font-size: 1.3em;
            }
          }
        }
      }

      &#genres {
        li {
          float: left;
          height: 65px;
          margin-right: 6px;
          margin-bottom: 6px;
          width: 16.666%;
          @include calc(width, "16.666% - 6px");
          @include mobile {
            @include calc(width, "50% - 6px");
            height: 50px;
            margin-bottom: 12px;
            margin-right: 0;
            &:nth-child(even) {
              float: right;
            }
          }

          &:nth-child(6),
          &:nth-child(12),
          &:nth-child(18) {
            margin-right: 0;
          }

          a {
            font-family: Obibok;
            font-size: 0.9rem;
            font-weight: 100;
            background: #fff;
            border: 2px solid #fff;
            color: $black;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
            text-decoration: none;
            width: 100%;
            @include transition(background color, 100ms);

            @include not-mobile {
              &:hover,
              &:focus {
                background: $purple;
                border-color: $purple;
                color: #fff;
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
              }
            }

            @include mobile {
              @include transition(none);
              font-size: 1.3em;
            }
          }
        }

        .clear-all-genres {
          a {
            background: $purple;
            border-color: $purple;
            color: #fff;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);

            @include not-mobile {
              &:hover,
              &:focus {
                background: #fff;
                border: 2px solid #fff;
                color: $black;
                text-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}
