@import "globals";

#custom-request-response-container,
#submissions-response-container {
  .response-message {
    display: none;
    padding: 12px 0;
    &.show {
      display: block;
    }

    div {
      margin: 0 auto;
      max-width: 1140px;

      p {
        @include lead(2.8, 1.8);
        font-family: Obibok;
        font-size: 0.9rem;
        font-weight: 100;
        line-height: 1.25rem;
        padding: 0 10%;
        text-align: center;
      }

      p:not(:first-child) {
        font-size: 1.6em;
      }
    }
  }

  &.success {
    .response-message {
      background: rgba(33, 146, 142, 0.1);

      p {
        color: $green;
      }
    }
  }

  &.fail {
    .response-message {
      background: rgba(201, 40, 40, 0.1);

      p {
        color: $red;
      }
    }
  }
}
