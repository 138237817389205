@import "globals";

#mm-custom-request,
#mm-submissions {
  .mm-react-form {
    background: #fff;
    padding: 30px 20px 0;
    @include mobile {
      padding-bottom: 80px;
    }

    form {
      padding-bottom: 80px;

      &.display-errors {
        input:invalid,
        textarea:invalid {
          background: rgba(201, 40, 40, 0.1);
        }

        select:invalid ~ span.error,
        input[type="radio"]:invalid ~ span.error {
          background: rgba(201, 40, 40, 0.1);
          display: block;
        }

        input[type="radio"]:invalid ~ span.error {
          height: 42px;
          margin-top: -43px;
        }

        select:invalid ~ span.error {
          height: 64px;
          margin-top: -65px;
          position: relative;
          top: 54px;
          left: -1em;
          width: 100%;

          @include mobile {
            left: -0.5em;
          }
        }
      }

      fieldset {
        &:disabled {
          input,
          textarea {
            opacity: 0.5;
          }
        }

        li,
        ol > div {
          padding-bottom: 30px;

          &:last-of-type {
            padding-bottom: 0;
          }

          &.supplemental-input {
            margin-top: -25px;
            padding-bottom: 0;

            label {
              display: none;
            }

            input {
              margin-left: 1%;
              border: 2px solid #999;
              width: 330px;
              height: 43px;
            }
          }

          .invalid {
            background: rgba(201, 40, 40, 0.1);
          }

          .error-msg {
            margin-left: calc(1% - 2px);
            position: absolute;
          }

          label {
            @include lead(2, 1.2);
            font-family: NeuzeitGro, georgia;
            font-size: 20px;
            border-bottom: 2px solid #999;
            color: #333;
            display: block;
            padding-top: 12px;
          }

          .cr-select {
            margin-top: 11px;
            padding: 0 1%;
            width: 200px;
          }

          div.radio-wrapper {
            height: 42px;
          }

          div.sort {
            display: inline-block;
            float: none;
            padding: 0 1%;
            width: 100%;

            .selected-option,
            .option {
              font-size: 1.8em;
            }

            .group {
              background: #fff;
              border-bottom: 2px solid #eee;
              font-size: 1.8em;
              padding-bottom: 0;
              text-indent: 10px;

              .group-header {
                font-weight: bold;
                height: 39px;
                line-height: 43px;
              }

              .option {
                border-bottom: 0;
                font-size: inherit;
                text-indent: 20px;
              }
            }
          }

          input {
            font-family: NeuzeitGro, georgia;
            font-size: 18px;
            border: 0;
            color: $black;
            height: 42px;
            outline: none;
            padding: 0 1%;

            &[type="radio"] {
              cursor: pointer;
              margin-left: 1%;
              width: 30px;
            }
          }

          textarea {
            font-family: NeuzeitGro, georgia;
            font-size: 1rem;
            border: 0;
            color: $black;
            outline: none;
            padding: 10px 1% 0;
            width: 98%;
          }

          input[type="checkbox"] {
            visibility: hidden;
            height: 12px;
          }

          input[type="checkbox"]:checked + label:after {
            opacity: 1;
          }

          .sub-label {
            font-weight: bold;
            border: none;
            font-size: 1.5em;
            margin-top: 1em;
          }

          .checkbox {
            height: 3.5em;

            p.label {
              font-family: NeuzeitGro, georgia;
              font-size: 1rem;
              padding: 4px 0px;
              width: 97%;
              margin-left: 2em;
            }

            label {
              cursor: pointer;
              position: absolute;
              width: 25px;
              height: 25px;
              background: #fff;
              border: 1px solid rgba(0, 0, 0, 0.2);
              float: left;
              padding-top: 0;

              &:after {
                opacity: 0;
                content: "";
                position: absolute;
                width: 9px;
                height: 5px;
                background: transparent;
                top: 6px;
                left: 7px;
                border: 3px solid $purple;
                border-top: none;
                border-right: none;

                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
              }
            }

            label:hover::after {
              opacity: 0.7;
            }
          }

          select {
            display: none;
          }

          abbr {
            color: $red;
          }

          span.radio-label {
            margin-top: 12px;
            position: absolute;
            font-family: NeuzeitGro, georgia;
            font-size: 1rem;
          }
        }
      }

      .spin-target {
        .box-button-black {
          border: none;
          clear: both;
          cursor: pointer;
          padding: 0 10px;
          width: 100%;
          &:disabled {
            cursor: default;
            opacity: 0.5;
            &:hover {
              background: $black;
            }
          }
          &:focus {
            outline: 0;
          }

          @include small-display {
            width: 100%;
          }
        }
      }

      .column {
        float: left;
        margin-right: 4%;
        width: 48%;
        @include small-display {
          width: 100%;
          margin: 0;
        }

        &.right {
          margin-right: 0;
        }
      }
    }
  }

  .response-message {
    background: var(--blue);
    color: #fff;
    display: block;
    padding: 12px 0;

    &.success {
      background: var(--green);
    }

    &.error {
      background: var(--error-red);
    }

    div {
      margin: 0 auto;
      max-width: 1140px;

      p {
        @include lead(2.8, 1.8);
        font-family: Obibok;
        font-size: 1rem;
        padding: 0 10%;
        text-align: center;
      }
    }
  }
}
