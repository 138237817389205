/* React Responsive Select - Default styles */
.rrs {
  position: relative;
  box-sizing: border-box;
}

.rrs *,
.rrs *:before,
.rrs *:after {
  box-sizing: border-box;
}

.rrs__button {
  color: #555;
  position: relative;
  cursor: pointer;
  height: 44px;
  line-height: 44px;
  background: #fff;
  border-radius: 2px;

  &:focus {
    outline: 0;
  }
}

.rrs__button--disabled {
  color: #999999;
  background: #f5f5f5;
  cursor: default;
}

.rrs__button + .rrs__options {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #fff;
  position: absolute;
  z-index: 2;
  border-top: 2px solid #999;
  border-radius: 0 0 2px 2px;
  top: 44px;
  width: 100%;
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.rrs--options-visible .rrs__options {
  height: auto;
  visibility: visible;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 230px;
  border: 2px solid #999;
}

.rrs__option {
  cursor: pointer;
  padding: 0.75rem 1rem;
  margin: 0;
  border-top: #eee 2px solid;
  font-size: 16px;

  &:first-of-type {
    border-top: 0;
  }

  &:focus {
    outline: 0;
  }

  &:active {
    background: #e1f5fe;
  }

  &.rrs__option--next-selection {
    color: #745bb4;
  }

  &.rrs__option--selected {
    color: #745bb4;
  }

  &.rrs__option--disabled {
    color: #999999;
    background: #f5f5f5;
    cursor: default;
  }

  &:hover {
    background: #745bb4;
    color: #fff;
  }
}

.rrs__option * {
  pointer-events: none;
}

.rrs__label {
  padding: 0 2rem 0 1rem;
  display: inline-flex;
  width: 100%;
  max-width: 100%;
  min-width: 250px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 16px;
  background: transparent;
  border: 2px solid #999;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rrs--options-visible .rrs__label,
.rrs__button:focus .rrs__label {
  border-bottom: 1px solid #fff;
  outline: 0;
  border: 2px solid #999;
}

/* Multiselect overrides */

.rrs__multiselect-label {
  display: inline-flex;
  max-width: 100%;
  line-height: 1;
}

.rrs__multiselect-label__text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rrs__multiselect-label__badge {
  border: 1px solid #21928e;
  padding: 1px 6px;
  margin: -3px 0 0 4px;
  border-radius: 8px;
  color: #21928e;
  font-size: 16px;
  font-weight: bold;
  vertical-align: middle;
  display: inline-block;
}

.caret-icon {
  position: absolute;
  right: 1rem;
  top: 1.25rem;
  fill: #333;
}

.rrs__options {
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition-property: max-height, opacity;
  max-height: 0;
  opacity: 0;
}

.rrs--options-visible {
  .caret-icon {
    transform: rotate(180deg);
  }

  .rrs__options {
    opacity: 1;
    z-index: 1000;
  }
}

/* MultiSelect checkbox icon */
.rrs__option span.checkbox {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  margin: 2px 10px 2px 0;
  border-radius: 50%;
}

.rrs__option.rrs__option--selected {
  .checkbox {
    border: 1px solid #745bb4;
  }

  &:hover {
    .checkbox {
      border-color: #fff;
    }
  }
}

.checkbox-icon {
  fill: #fff;
  position: absolute;
  left: 4px;
  top: 4px;
}

.rrs__option.rrs__option--selected {
  .checkbox-icon {
    fill: #745bb4;
  }

  &:hover {
    .checkbox-icon {
      fill: #fff;
    }
  }
}

.tab {
  display: inline-block;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  transform: translate(0, -1px);
  line-height: 1;
  padding: 3px 10px 2px;
  margin: 0 4px 0 0;
  font-size: 16px;
}
