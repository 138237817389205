@import "globals";

#mm-mixtape {
  header#mm-mixtape-landing {
    .cover-image {
      height: 7.5rem;
      width: 100%;

      @include mobile {
        height: 80px;
      }
    }

    .heading {
      background: #efefed;
      min-height: 224px;
      text-align: center;
      width: 100%;

      .contain {
        @include relative;
      }

      .mixtape-icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        width: 80px;
        margin: 0 auto;
        top: 40px;
      }

      form {
        .mixtape-form {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          flex-direction: column;
          position: relative;
          padding-top: 40px;
          margin-top: 50px;
        }

        .mixtape-description {
          font-family: Obibok;
          font-size: 1rem;
          font-weight: 100;
          line-height: 1.25rem;

          &.edit {
            span.icon {
              @include opacity(1);
            }
          }
        }
        .mixtape-name {
          font-size: 2.25rem;
        }

        .input-sizer {
          display: inline-grid;
          vertical-align: top;
          //align-items: center;
          position: relative;
          font-family: Obibok;
          background: transparent;
          border: none;
          color: $black;
          outline: none;
          padding: 0.25em 0.5em;
          margin: 5px;
          max-width: 60%;

          input {
            outline: none;
            text-align: center;
            cursor: text;

            &[disabled="disabled"] {
              cursor: pointer;
            }
          }

          &::after,
          input,
          textarea {
            width: auto;
            min-width: 1em;
            grid-area: 1 / 2;
            font: inherit;
            padding: 0.25em;
            margin: 0;
            resize: none;
            background: none;
            appearance: none;
            border: none;
          }
          &::after {
            content: attr(data-value) " ";
            visibility: hidden;
            white-space: pre-wrap;
          }

          &.edit {
            span.icon-pencil {
              @include opacity(1);
            }
          }

          .icon-pencil {
            @include opacity(0);
            @include transition(opacity, 150ms);
            pointer-events: none;
            grid-column-start: 3;
            margin-left: 40%;

            svg {
              height: 40px;
              width: 30px;
              transform: rotate(90deg);
              g {
                fill: gray;
                .icon-border {
                  display: none;
                }
              }
            }

            &.hide {
              visibility: hidden;
            }
          }
        }

        h1 {
          @include relative;
          color: $black;
          font-weight: normal;
          justify-content: flex-end;
          align-items: flex-end;
          flex-direction: row;
          position: relative;
        }

        .mixtape-alternative-description {
          position: relative;
          margin-top: 30px;
          justify-content: flex-end;
          align-items: flex-end;
          width: 60%;
          flex-direction: row;
          padding-bottom: 3rem;

          &.edit {
            span.icon {
              @include opacity(1);
            }
          }
        }

        textarea {
          background: transparent;
          border: none;
          outline: none;
          text-align: center;
          overflow-y: hidden;

          &[disabled="disabled"] {
            cursor: pointer;
          }

          @media (max-width: 700px) {
            width: 80%;
          }
        }
        .buttons-control {
          display: none;
          margin-bottom: 20px;

          button {
            font-family: "proxima-nova", sans-serif;
            font-size: 1.25rem;
            color: white;
            border: none;
            width: 80px;
            height: 40px;
            border-radius: 8px;
            cursor: pointer;
          }

          #save-button {
            background-color: var(--green);
            &:hover {
              transition: all 0.2s ease-in-out;
              background-color: var(--light-green);
            }
            &[disabled="disabled"] {
              @include opacity(0.5);
              cursor: default;

              &:hover {
                transition: all 0.2s ease-in-out;
                background-color: var(--green);
              }
            }
          }
          #cancel-button {
            background-color: var(--red);
            &:hover {
              transition: all 0.2s ease-in-out;
              background-color: var(--error-red);
            }
          }
        }
      }

      .info {
        @include relative;
        text-align: center;
        @include mobile {
          padding-bottom: 20px;
        }

        ul {
          display: inline-block;
          overflow: hidden;

          li {
            @include lead(2.8, 1.6);
            @include kern(-20);
            @include relative;
            color: $black;
            font-family: Obibok;
            font-size: 0.8rem;
            font-weight: 100;
            float: left;
            @include mobile {
              float: none;
            }

            &:after {
              content: "-";
              color: #999;
              padding: 0 6px;
            }
            @include mobile {
              &:after {
                content: "";
                padding: 0;
              }
            }

            &:last-child:after {
              content: "";
            }

            em {
              color: #666;
            }

            time {
            }
          }
        }
      }
      p {
        width: 100%;
        font-family: Obibok;
        font-size: 1rem;
        font-weight: 100;
        line-height: 2.2em;
        background: transparent;
        border: none;
        color: $black;
        outline: none;
        text-align: center;
        resize: none;
        overflow-y: hidden;

        &[disabled="disabled"] {
          cursor: pointer;
        }

        @media (max-width: 700px) {
          width: 80%;
        }
      }

      .collaborators {
        padding: 0 55px 5px 55px;

        @include mobile {
          padding: 0 15px 20px 15px;
        }

        p {
          @include lead(2.8, 1.6);
          @include kern(-20);
          @include relative;
          color: #666;
          font-size: 1.6em;
          overflow: hidden;
        }

        p:first-of-type {
          color: $black;
        }
      }
    }
  }

  // Staff Pick Mixtape Styling!
  &.staff-pick {
    header#mm-mixtape-landing {
      .cover-image {
        background: #f7f7f7;
        height: 7.5rem;
        @include tablet-down {
          display: none;
        }
      }

      .heading {
        min-height: 600px;
        text-align: left;
        @include relative;

        @include tablet {
          min-height: 530px;
        }
        @include mobile {
          min-height: 365px;
        }

        .mixtape-bg-image {
          clip: rect(0px, auto, auto, 0px); /* For FF - overflow: hidden not working */
          left: -30px;
          height: 100%;
          overflow: hidden;
          padding: 0 30px 30px 30px;
          position: absolute;
          right: -30px;
          width: 100%;
          filter: blur(20px);
          -webkit-filter: blur(20px);
          -moz-filter: blur(20px);
          -o-filter: blur(20px);
          -ms-filter: blur(20px);

          &:after {
            background: rgba(0, 0, 0, 0.3);
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        .contain {
          #mixtape-default {
            padding: 100px 0;
            margin: 0 65px;
            display: flex;
            justify-content: center;

            @include mobile {
              padding: 100px 1% 50px;
              flex-direction: column;
              align-items: center;
            }

            h1 {
              color: #fff;
            }

            h1.mobile {
              color: #fff;
              font-weight: bold;
              letter-spacing: 0;
              margin-left: 0;
              margin-top: 0;
              padding-bottom: 10px;
              text-align: center;
              text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);

              @include mobile {
                font-size: 24px;
              }
            }

            .mixtape-image {
              min-width: 436px;
              height: 436px;

              @include mobile {
                height: 336px;
                min-width: 336px;
                margin: 3em 0;
              }

              @include breakpoint(350px) {
                height: 250px;
                min-width: 250px;
              }
            }

            .mixtape-info {
              margin: 25px 0 0 4%;
              text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);

              @include tablet {
                margin-top: 10px;
              }

              @include mobile {
                margin: 0;
                width: 336px;
              }

              @include breakpoint(350px) {
                margin: 0 1em;
                width: 250px;
              }

              .italic {
                font-style: italic;
              }

              ul li {
                @include lead(2.8, 1.6);
                @include kern(-20);
                @include relative;
                color: #fff;
                font-family: Obibok;
                font-size: 0.9rem;
                font-weight: 100;
                line-height: 1.25rem;
                float: none;
                width: 100%;

                h1,
                p {
                  color: #fff;
                  letter-spacing: 0;
                  margin-left: 0;
                  margin-top: 0;
                  text-align: left;
                }

                h1 {
                  @include tablet {
                    font-size: 28px;
                  }
                }

                &.name {
                  width: 100%;

                  @include tablet {
                    width: 85%;
                  }
                }

                &.curator {
                  font-size: 2em;
                  padding: 10px 0 5px;

                  @include tablet {
                    font-size: 1.8em;
                  }
                  @include mobile {
                    font-size: 1.5em;
                    padding-top: 0;
                  }
                }

                &.description {
                  p {
                    font-size: 1.2em;
                    padding: 10px 0;
                  }
                }

                &.collaborators {
                  padding: 10px 0 0 0;
                  p {
                    font-size: 1em;
                    padding: 0;
                  }
                }

                span {
                  color: #aaa;
                }
              }
            }
          }
        }
      }
    }
  }

  .actions {
    @include relative;
    background: #f8f8f8;
    padding: 12px 0;
    text-align: center;

    > .contain {
      @include relative;
    }

    .spin-targ {
      display: flex;
      align-items: center;
      height: 100%;
      position: absolute;
      top: 0;

      .spinner {
        left: 12px !important;
      }

      p {
        text-align: left;
        margin-left: 40px;

        &.timeout {
          color: $red;
          margin-left: 0;
          max-width: 230px;
        }
      }

      .long-process-wrapper {
        .long-process-message {
          display: none;
        }
      }
    }

    .share-message {
      font-family: Obibok;
      font-size: 0.9rem;
      font-weight: 100;
      line-height: 1.25rem;
      color: $black;
      font-style: italic;
      max-height: 0;
      overflow: hidden;
      padding-top: 0;
      @include transition(all, 500ms, ease-in-out);
      &.showing {
        max-height: 38px;
        padding-top: 10px;
        @include transition(all, 500ms, ease-in-out);
      }
    }

    ul {
      @include relative;
      display: inline-block;

      &.owner {
        margin-left: 82px;
        @include mobile {
          margin: 0;
        }
      }

      li {
        @include relative;
        background: #fff;
        cursor: pointer;
        float: left;
        height: 47px;
        margin: 0 1px;
        width: 62px;
        @include transition(background, 100ms);

        &.disabled {
          @include opacity(0.5);
          cursor: default;
          pointer-events: none;
        }

        &.login-required {
          @include opacity(0.5);
        }

        .icon {
          svg {
            height: 45px;
            width: 45px;
            g {
              fill: $black;
              @include transition(fill, 100ms);
              .icon-border {
                display: none;
              }
            }
          }
        }

        &:hover {
          svg g {
            fill: #fff;
            @include transition(fill, 100ms);
          }
        }

        &.play {
          background: $purple;
          svg g {
            fill: #fff;
          }
        }

        &.lock {
          &.unlocked {
            .make-secret {
              display: none;
            }
          }
          &:hover {
            &.unlocked {
              .make-secret {
                display: block;
              }
              .make-public {
                display: none;
              }
            }
          }

          &.locked {
            .make-public {
              display: none;
            }
          }
          &:hover {
            &.locked {
              .make-public {
                display: block;
              }
              .make-secret {
                display: none;
              }
            }
          }
        }

        &.join {
          &.joined {
            .join-mixtape {
              display: none;
            }
          }
          &.unjoined {
            .leave-mixtape {
              display: none;
            }
          }
        }

        &.collaborate {
          &.collaborative {
            .remove-collab {
              display: none;
            }
          }
          &:hover {
            &.collaborative {
              .remove-collab {
                display: block;
              }
              .add-collab {
                display: none;
              }
            }
          }

          &.uncollaborative {
            .add-collab {
              display: none;
            }
          }
          &:hover {
            &.uncollaborative {
              .add-collab {
                display: block;
              }
              .remove-collab {
                display: none;
              }
            }
          }
        }

        .label {
          display: none;
        }

        &:hover {
          &:not(.disabled) {
            background: $gray;
            .icon {
              .default {
                @include opacity(0);
              }
              .over {
                @include opacity(1);
              }
            }
          }
        }

        &:active {
          &:not(.disabled) {
            background: $purple;
            .icon {
              .default {
                @include opacity(0);
              }
              .over {
                @include opacity(1);
              }
            }
          }
        }
      }
    }

    .delete {
      @include relative;
      background: #efefed;
      display: block;
      float: right;
      height: 47px;
      width: 62px;
      @include transition(background, 100ms);

      @include content-break {
        margin-right: 20px;
      }

      @include breakpoint(353px) {
        margin-right: 0;
      }

      &:hover {
        background: #fff;
      }

      .label {
        display: none;
      }

      .icon {
        svg {
          height: 45px;
          width: 45px;
          g {
            fill: $black;
            @include transition(fill, 100ms);
            .icon-border {
              display: none;
            }
          }
        }
      }
    }
  }

  .song-versions {
    background: #fff;
    padding: 15px 0 95px 0;

    .mm-song-versions-table {
      @include relative;

      .mm-song-versions-table-list {
        position: relative;
      }

      .song-version-item.mixtape {
        &.dragging {
          cursor: move;
          @include grabbing;
        }

        .default {
          @include grab;
        }
      }
    }
  }
}

#mixtape-wrapper {
  height: 224px;
  overflow: hidden;
  padding: 40px 0 30px;
  position: relative;
  width: 100%;
}

#mixtape-playing {
  @include opacity(0);
  height: 224px;
  left: 50%;
  margin-left: -165px;
  position: absolute;
  width: 328px;

  @include transition(opacity 200ms);

  &.fadein {
    @include opacity(1);
    @include animation(scaler 200ms linear 1 reverse);
  }

  &.fadeout {
    @include opacity(0);
    @include animation(scaler 200ms linear 1);
    @include animation-fill-mode(forwards);
  }

  #mixtape-spools,
  #mixtape-reels {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  #mixtape-spools {
    background-color: white;
    height: 160px;
    width: 280px;
    left: 50%;
    top: 50%;
    margin-left: -140px;
    margin-top: -92px;

    #mixtape-spool-left,
    #mixtape-spool-right {
      background-color: #cdcdd0;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -53px;
      height: 108px;
      width: 108px;
    }

    #mixtape-spool-left {
      margin-left: -116px;
    }

    #mixtape-spool-right {
      margin-left: 9px;
    }
  }

  #mixtape-reels {
    left: 50%;
    height: 53px;
    margin-left: -88px;
    margin-top: -38px;
    top: 50%;
    width: 178px;

    #mixtape-reel-left,
    #mixtape-reel-right {
      @include icons-sprite(reel);
      position: absolute;
      top: 0;
      left: 0;
    }

    #mixtape-reel-right {
      left: auto;
      right: 0;
    }
  }

  // the rotation of the reels is dependent on the timing below 200s and 5 spool/reel
  // adjust the js in mixtape.js accordingly.
  &.spinning {
    #mixtape-spool-left {
      @include animation(spool 200s linear infinite);
    }
    #mixtape-spool-right {
      @include animation(spool 200s linear infinite reverse);
    }
    &.clockwise {
      #mixtape-reel-left,
      #mixtape-reel-right {
        @include animation(spin 5s linear infinite);
      }
    }
    &.counterclockwise {
      #mixtape-reel-left,
      #mixtape-reel-right {
        @include animation(spin 5s linear infinite reverse);
      }
    }
  }

  #mixtape-cassette {
    @include icons-sprite(cassette);
    position: relative;

    .label {
      @include felt-tip;
      color: $purple;
      display: block;
      font-size: 2.2em;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
    }

    .title {
      left: 72px;
      height: 40px;
      line-height: 40px;
      text-align: left;
      top: 22px;
      text-overflow: ellipsis;
      width: 225px;
    }

    .description {
      bottom: 46px;
      left: 26px;
      height: 37px;
      line-height: 36px;
      text-transform: uppercase;
      width: 278px;
    }
  }
}

#mixtape-default {
  min-height: 300px;
  margin-top: -294px;

  hgroup > .hThree {
    margin-top: 1.4em;
  }

  hgroup,
  .mixtape-icon {
    @include opacity(1);
    @include transition(opacity 200ms);
  }

  &.fadeout {
    hgroup,
    .mixtape-icon {
      @include opacity(0);
      @include animation(scaler 200ms linear 1);
    }
  }

  &.fadein {
    hgroup,
    .mixtape-icon {
      @include opacity(1);
      @include animation(scaler 200ms linear 1 reverse);
    }
  }
}
