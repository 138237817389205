@import "globals";

#mm-not-found {
  #mm-not-found-landing {
    .cover-image {
      background: url("/app/assets/images/headers/brand-update-header.jpg") center center / cover
        no-repeat;
      height: 250px;
      width: 100%;

      @include mobile {
        height: 80px;
      }
    }

    hgroup {
      @include relative;
      background: #efefed;
      min-height: 205px;
      text-align: center;
      width: 100%;
      z-index: 1;

      .contain {
        @include relative;
      }

      .error-icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        margin: 0 auto;
        top: 40px;
        width: 80px;

        span {
          height: 80px;
          width: 80px;

          g {
            fill: $green;
          }
        }
      }

      h1 {
        color: $black;
        padding-top: 50px;
        text-align: center;
      }
    }
  }

  article {
    @include box-sizing;
    padding: 140px 1em 100px 1em;
    text-align: center;

    h4 {
      color: $black;
      padding-bottom: 15px;
    }

    p {
      color: $black;

      a {
        color: $purple;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}
