@import "globals";

#mm-artist {
  header#mm-artist-landing {
    .cover-image {
      background: url("/app/assets/images/headers/brand-update-header.jpg") center center / cover
        no-repeat;
      height: 250px;
      width: 100%;
      @include mobile {
        height: 80px;
      }
    }

    hgroup {
      @include relative;
      background: #efefed;
      height: 222px;
      text-align: center;
      width: 100%;
      z-index: 1;
      @include mobile {
        height: 170px;
      }

      .contain {
        @include relative;
      }

      .artist-icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        margin: 0 auto;
        top: 40px;
        width: 80px;
        @include mobile {
          top: 20px;
        }

        svg {
          height: 80px;
          width: 80px;

          g {
            fill: $green;
          }
        }
      }

      h1 {
        padding-top: 50px;
        @include mobile {
          font-size: 1.25rem;
          padding-top: 30px;
        }
      }

      p {
        font-family: Obibok;
        font-size: 0.9rem;
        font-weight: 100;
        line-height: 1.25rem;

        em,
        a {
          @include lead(2.8, 1.6);
        }

        em {
          color: #666;
        }

        a {
          color: #745bb4;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .shareables {
    background: #f8f8f8;
    padding: 12px 0;
    text-align: center;

    ul {
      display: inline-block;
      overflow: hidden;

      li {
        @include relative;
        display: inline-block;
        margin: 0 3px;

        &.bandcamp a {
          @include social-sprite-state(
            social_bandcamp_default,
            social_bandcamp_rollover,
            social_bandcamp_click,
            150
          );
        }

        &.facebook a {
          @include social-sprite-state(
            social_facebook_default,
            social_facebook_rollover,
            social_facebook_click,
            150
          );
        }

        &.instagram a {
          @include social-sprite-state(
            social_instagram_default,
            social_instagram_rollover,
            social_instagram_click,
            150
          );
        }

        &.itunes a {
          @include social-sprite-state(
            social_itunes_default,
            social_itunes_rollover,
            social_itunes_click,
            150
          );
        }

        &.myspace a {
          @include social-sprite-state(
            social_myspace_default,
            social_myspace_rollover,
            social_myspace_click,
            150
          );
        }

        &.soundcloud a {
          @include social-sprite-state(
            social_soundcloud_default,
            social_soundcloud_rollover,
            social_soundcloud_click,
            150
          );
        }

        &.spotify a {
          @include social-sprite-state(
            social_spotify_default,
            social_spotify_rollover,
            social_spotify_click,
            150
          );
        }

        &.tiktok a {
          @include social-sprite-state(
            social_tiktok_default,
            social_tiktok_rollover,
            social_tiktok_click,
            150
          );
        }

        &.twitter a {
          @include social-sprite-state(
            social_twitter_default,
            social_twitter_rollover,
            social_twitter_click,
            150
          );
        }

        &.vimeo a {
          @include social-sprite-state(
            social_vimeo_default,
            social_vimeo_rollover,
            social_vimeo_click,
            150
          );
        }

        &.website a {
          @include social-sprite-state(
            social_website_default,
            social_website_rollover,
            social_website_click,
            150
          );
        }

        &.youtube a {
          @include social-sprite-state(
            social_youtube_default,
            social_youtube_rollover,
            social_youtube_click,
            150
          );
        }
      }
    }
  }

  .details {
    background: #fff;
    padding-top: 40px;
    padding-bottom: 80px;

    @include contain-break;

    @include mobile {
      padding: 0;
    }

    figure {
      background: #4a4a4a;
      overflow: hidden;
      @include mobile {
        padding: 15px;
      }

      img {
        float: left;
        width: 51.75%;
        @include mobile {
          float: none;
          width: 100%;
        }
      }

      figcaption {
        display: block;
        float: right;
        height: 100%;
        width: 48.25%;
        @include mobile {
          float: none;
          width: 100%;
        }

        div {
          margin: 55px;
          @include mobile {
            margin: 0;
          }
        }

        h5 {
          color: #fff;
          @include mobile {
            display: none;
          }
        }

        p {
          @include lead(2.1, 1.8);
          font-family: NeuzeitGro, georgia;
          font-size: 1.8em;
          color: #ccc;
          padding: 10px 0 0 0;
          @include mobile {
            font-size: 1.1em;
          }
        }
      }
    }
  }

  .mm-song-versions-table-list {
    position: relative;
  }

  .staff-picks {
    background: #fff;
    padding-bottom: 80px;
    @include mobile {
      padding: 25px 0 25px 0;
    }

    h5 {
      padding-bottom: 15px;
      @include contain-break;
      @include mobile {
        font-size: 1.8em;
        margin-left: 15px;
      }
    }

    .mm-song-versions-table {
      // border-bottom: 2px solid #e7e7e7;
    }
  }

  .discog {
    background: #fff;
    padding-bottom: 80px;
    @include mobile {
      padding: 25px 0 25px 0;
    }

    h5 {
      padding-bottom: 15px;

      @include content-break {
        padding-left: 20px;
      }

      @include mobile {
        font-size: 1.8em;
        margin-left: 15px;
      }
    }

    .sort {
      margin-top: -14px;
      @include content-break {
        margin-right: 20px;
      }
    }

    .mm-song-versions-table {
      // border-bottom: 2px solid #e7e7e7;
      clear: both;
    }

    .view-more {
      display: none;
      margin-top: 50px;
      @include content-break {
        margin-left: 15px;
        margin-right: 15px;
        height: 50px;
        line-height: 50px;
        font-size: 1.1em;
      }

      &.available {
        display: block !important;
      }
    }
  }

  .related {
    background: #fff;
    padding-bottom: 90px;
    @include contain-break;
    @include mobile {
      padding: 25px 0 25px 0;
      margin: 0 15px;
    }

    h5 {
      padding-bottom: 35px;
      @include mobile {
        font-size: 1.8em;
        padding-bottom: 0;
      }
    }

    ul {
      overflow: hidden;

      li {
        @include relative;
        float: left;
        height: 290px;
        margin-right: 3.86%;
        width: 22.1%;
        @include mobile {
          height: 145px;
          margin: 12px;
          width: 100%;
          @include calc(width, "50% - 24px");
        }

        &:last-child {
          float: right;
          margin-right: 0;
          @include mobile {
            float: left;
            margin-right: 12px;
          }
        }

        a {
          display: block;
          height: 100%;
          text-decoration: none;
          width: 100%;
        }

        .gradient-overlay {
          background: linear-gradient(0deg, #000000, transparent);
          bottom: 0;
          left: 0;
          height: 150px;
          position: absolute;
          width: 100%;
          @include mobile {
            height: 60px;
          }

          hgroup {
            bottom: 0;
            left: 0;
            margin: 20px;
            position: absolute;
            @include mobile {
              margin: 10px;
            }

            h5 {
              @include kern(-20);
              color: #fff;
              text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
              padding: 0;
            }

            p {
              @include lead(2.8, 1.6);
              color: #fff;
              font-style: italic;
              font-weight: 100;
              text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
            }
          }
        }
      }
    }
  }
}

#mm-new-artist-form {
  header#mm-new-artist-form-landing {
    height: 7.5rem;
    width: 100%;
    @include touch {
      height: 80px;
    }
    @include mobile {
      height: 80px;
    }
  }

  .new-artist-form-container {
    display: flex;

    @include mobile {
      flex-direction: column-reverse;
    }
  }

  .form-box {
    position: relative;
    margin: 100px auto;
    box-shadow: 0px 10px 20px lightgray;
    min-width: 700px;
    min-height: 500px;
    border-radius: 10px;
    padding: 20px;

    @include mobile {
      width: 100%;
      min-height: 300px;
      min-width: unset;
      margin: 100px auto;
      box-sizing: border-box;
    }

    .step-indicator {
      position: absolute;
      top: -35px;
      width: 700px;

      @include mobile {
        width: 100%;
        left: 0px;
      }

      .steps-list {
        display: flex;
        width: 100%;
        justify-content: space-evenly;

        .step {
          position: relative;
          font-family: NeuzeitGro, georgia;
          font-size: 1.2rem;
          background: white;

          @include tablet {
            font-size: 0.9rem;
          }

          @include mobile {
            font-size: 0.7rem;
          }

          &.active {
            transition: 0.5s width ease-in-out;
            color: var(--purple);
            border-bottom: solid 2px;
          }
        }
      }
    }
  }

  .left-section,
  .right-section {
    width: 25%;
    display: flex;
    justify-content: left;

    @include mobile {
      width: 100%;
      justify-content: center;
    }

    .hint-box {
      margin: 100px 25px;
      box-shadow: 0px 10px 20px lightgray;
      border-radius: 10px;
      padding: 50px 20px;
      width: 70%;

      @include mobile {
        margin: unset;
        margin-top: 20px;
      }
    }

    .extra-hint {
      opacity: 50%;
      font-family: "NeuzeitGro";
      font-size: 0.9rem;
      margin-bottom: 10px;

      &.centered {
        text-align: center;
      }
    }
  }

  .step-container {
    height: 100%;
    form {
      height: 100%;
      position: relative;
      font-family: "NeuzeitGro";

      .identities-section,
      .links-section,
      .final-questions-section,
      .review-page {
        margin-bottom: 60px;

        .pill-label {
          padding: 5px;
          border-radius: 10px;
        }
      }

      .final-questions-section {
        .radio-group {
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;

          label {
            margin: 5px 0px;
          }

          .radio-label {
            padding: 5px;
            border-radius: 10px;
          }

          input[type="radio"] {
            appearance: none;
            background-color: #fff;
            margin: 0;
            font: inherit;
            color: lightgray;
            width: 1.15em;
            height: 1.15em;
            border: 0.15em solid lightgray;
            border-radius: 50%;
            transform: translateY(-0.075em);
            display: inline-grid;
            place-content: center;
            margin: 5px 2px;
          }

          input[type="radio"]::before {
            content: "";
            width: 0.65em;
            height: 0.65em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--blue);
          }
          input[type="radio"]:checked::before {
            transform: scale(1);
          }
        }
      }

      #artist-bio-input {
        padding: 8px;
        font-size: 16px;
        font-weight: 400;
        width: 685px;
        font-family: NeuzeitGro, georgia;

        @include mobile {
          width: 95%;
        }
      }

      .bio-label-field {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
      }

      .photo-options {
        display: flex;
        justify-content: space-evenly;
        height: 110px;

        .photo-option-container {
          width: 190px;
          height: 106px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          position: relative;

          @include mobile {
            width: 6.4rem;
            height: 3.9rem;
          }

          .remove-photo-option {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            border: none;
            position: absolute;
            right: -5px;
            top: -5px;
            cursor: pointer;
          }
        }
      }

      .error-msg {
        font-family: NeuzeitGro, georgia;
      }

      .extra-hint {
        width: 700px;
        opacity: 50%;
        font-family: "NeuzeitGro";
        font-size: 0.9rem;
        margin-bottom: 1rem;

        &.centered {
          text-align: center;
        }

        @include mobile {
          width: 95%;
        }
      }

      .button-container {
        display: flex;
        position: absolute;
        bottom: 5px;
        justify-content: space-between;
        width: 100%;

        @include mobile {
          position: unset;
        }
      }

      button[type="submit"],
      button.form-step-button,
      button.previous-step {
        padding: 8px 8px;
        border: none;

        &:hover {
          background-color: lightgray;
        }
      }
    }

    .vendor-detail {
      margin-bottom: 0.6rem;
    }

    .success-portal-link,
    .success-message {
      display: inline-flex;
      width: 100%;
      justify-content: center;
      margin-top: 1rem;
      font-size: 1.3rem;
      color: var(--green);
      font-family: "NEUZEITGRO";
    }

    .success-portal-link {
      margin-top: 0.5rem;
      font-size: 1rem;
    }

    .submission-success {
      margin: auto;
      justify-content: center;
      display: flex;

      svg {
        stroke-width: 1.4;
        height: 60%;
        width: 60%;

        stroke: var(--green);
        opacity: 0;
        animation: 2s opacity forwards;

        @keyframes opacity {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
    }
  }
}
