@import "globals";

footer[role="main"].new-footer {
  background: #000;
  color: #f1eff0;
  font-family: "Obibok";
  font-size: 14px;
  height: 500px;
  min-height: unset;
  padding: 0 0 4.75rem;
  width: 100%;

  @include tablet {
    height: 750px;
    padding: 0 0 3.25rem;
  }

  @include mobile {
    height: 958px;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin: 0 5.5rem;

    @include tablet {
      margin: 0 2.5rem;
    }

    @include mobile {
      margin: 0 1.5rem;
    }
  }

  .logos-and-nav-links {
    display: flex;

    @include tablet-down {
      flex-direction: column;
    }

    .logos {
      display: flex;
      flex-direction: column;
      margin-top: 4.75rem;
      flex: 1;

      @include mobile {
        margin-top: 3.75rem;
      }

      .marmoset-logo {
        width: 225px;

        path {
          fill: #fff;
        }
      }

      .trackclub-logo {
        width: 220px;
        margin-left: -5px;
      }

      svg {
        height: 3.5rem;
        width: 100%;
      }
    }

    .nav-links {
      display: flex;
      flex: 2;
      justify-content: space-between;
      margin-top: 13rem;

      @include mobile {
        flex-flow: wrap;
        margin-top: 3.75rem;
      }

      @include tablet {
        margin-top: 4.75rem;
      }

      ol {
        display: flex;
        flex-direction: column;

        @include mobile {
          width: 50%;

          &:nth-child(-n + 2) {
            margin-bottom: 1.75rem;
          }
        }

        li {
          color: #909090;
          line-height: 1.5rem;

          a {
            color: #909090;
          }

          &:first-of-type {
            color: #f1eff0;
            font-weight: bold;

            a {
              color: #f1eff0;
            }
          }
        }
      }
    }
  }

  .social-and-legal {
    display: flex;
    font-weight: bold;
    justify-content: space-between;

    @include tablet-down {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .b-corp-logo {
      flex: 1;
    }

    .social-and-legal-links {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;

      @include tablet-down {
        margin-bottom: 2.75rem;
      }

      .social {
        font-size: 15px;

        @include mobile {
          font-size: 13px;
        }

        a {
          color: #f1eff0;
        }
      }

      .legal {
        font-size: 13px;
        letter-spacing: 0.1em;
        margin-top: 10px;

        a,
        .small-font {
          color: #909090;
        }

        .small-font {
          display: flex;

          div:not(:last-of-type) {
            margin-right: 15px;
          }
        }
      }
    }

    .marmocons {
      display: flex;
      align-items: flex-end;
      flex: 1;
      justify-content: flex-end;

      @include tablet-down {
        margin-bottom: 2.75rem;
      }

      @include mobile {
        margin-top: 1.75rem;
      }

      svg:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }

  a {
    text-decoration: none;
  }
}
