@import "globals";
.player {
  background: #eee;
  bottom: 0;
  font-family: Obibok;
  font-weight: 100;
  height: 55px;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 10;
  @include transition(width, 500ms, $easeOutExpo, 300ms);
  $mobilePlayerH: 46px;
  &.mobile {
    @include relative;
    background: #f8f7f7;
    bottom: auto;
    height: $mobilePlayerH;
    margin-top: 5px;
    .player-deck {
      background: #f8f7f7;
      border: none;
      height: $mobilePlayerH;
      .mm-radio-cta {
        @include box-sizing(border-box);
        @include relative;
        display: flex;
        justify-content: center;
        height: $mobilePlayerH;
        left: 5px;
        margin: 0;
        padding: 0;
        width: 100%;
        .mm-radio {
          height: $mobilePlayerH;
          .label {
            padding: 0;
            letter-spacing: 0.07em;
            line-height: 50px;
          }
        }
      }
      .mm-scrubber {
        border: none;
        height: $mobilePlayerH;
        left: 0;
        top: 0;
        width: 100%;
        .mm-playing-song-version {
          display: block;
          height: $mobilePlayerH;
          .artist-image,
          .song-version-details {
            float: left;
            width: 165px;
          }
          .artist-image {
            @include box-sizing(border-box);
            height: $mobilePlayerH;
            width: $mobilePlayerH;
            margin: 0;
            img {
              display: block;
              border: 0;
              height: $mobilePlayerH;
              width: $mobilePlayerH;
            }
          }
          .song-version-details {
            @include box-sizing(border-box);
            @include relative;
            height: $mobilePlayerH;
            margin: 0;
            padding: 2px;
            width: 170px;
            br {
              display: none !important;
            }
            p {
              @include relative;
              display: block !important;
              font-size: 12px !important;
              height: 15px;
              margin: 0;
              overflow: visible;
              padding: 0 !important;
              top: 2px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              a,
              em {
                @include lead(1.3, 1.1);
                display: block;
                font-size: 1.1em;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &.song-version-title {
                  display: block;
                }
              }
              em {
                float: left;
                margin-right: 3px;
              }
            }
            p,
            small {
              margin: 0 0 0 2px;
              padding: 0;
            }
            small {
              .elapsed,
              .interior {
                display: block;
                float: left;
                font-size: 11px;
                line-height: 11px;
                margin: 0;
                padding: 0;
              }
              .elapsed {
              }
              .interior {
                margin-left: 2px;
              }
            }
          }
          .song-version-stream {
            height: $mobilePlayerH;
            left: $mobilePlayerH;
            margin: 0;
            padding: 0;
            position: absolute;
            top: 0;
            width: 209px;
            .song-version-time {
              display: flex;
              position: absolute;
              z-index: 10;
              font-size: 10px;
              right: 0;
              width: 40px;
              small {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                font-weight: 400;
                height: 46px;
                line-height: 10px;
              }
            }
            .waveform-mobile {
              height: $mobilePlayerH;
              width: 209px;
              wave {
                overflow-x: hidden !important;
                canvas {
                  width: 0px;
                }
              }
            }
            .song-version-buffered,
            .song-version-elapsed {
              height: $mobilePlayerH;
            }
          }
        }
      }
    }
  }
  &.expanded {
    .player-history {
      top: -545px;
      @include transition(top, 500ms, $easeOutExpo);
    }
    .mm-song-versions-table-list {
      display: block !important;
    }
  }
  &.expanded-stations {
    .player-stations {
      top: -545px;
      @include transition(top, 500ms, $easeOutExpo);
    }
  }
  .player-deck {
    background: #eee;
    border-top: 1px solid #ddd;
    height: 55px;
    left: 0;
    overflow: hidden;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 12;
    .connect-label {
      display: none;
      align-items: center;
      background: rgba(255, 255, 255, 0.5);
      height: 46px;
      margin-top: 4px;
      margin-right: 4px !important;
      padding: 0 10px;
      text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
      line-height: 55px;
      margin-right: 10px;
      z-index: 3;
      &.connected {
        display: flex;
      }
    }
    .mm-player-controls.desktop,
    .mm-scrubber,
    .mm-player-actions {
      height: 55px;
    }
    .mm-player-controls.desktop {
      float: left;
      margin-left: 2px;
      overflow: hidden;
      .navigate {
        display: none;
        float: left;
      }
      .volume {
        display: none;
        float: left;
        width: 12px;
        height: 50px;
        /* GENERAL VOLUME STYLING */
        input[type="range"].volume-slider {
          -webkit-appearance: none;
          background-image: linear-gradient(to left, $purple, #eee);
          width: 46px;
          height: 10px;
          margin-top: 22px;
          margin-left: -16px;
          cursor: pointer;
          -webkit-transform: rotate(-90deg);
          -moz-transform: rotate(-90deg);
          -o-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
          z-index: 0;
        }
        input[type="range"].volume-slider:focus {
          outline: none;
        }
        /* CHROME STYLING */
        input[type="range"].volume-slider::-webkit-slider-thumb {
          -webkit-appearance: none;
          background-color: $black;
          width: 3px;
          height: 10px;
        }
        /* FIREFOX STYLING */
        input[type="range"].volume-slider::-moz-range-track {
          background-image: linear-gradient(to left, $purple, #eee);
          border: none;
          cursor: pointer;
        }
        input[type="range"].volume-slider::-moz-range-thumb {
          border: none;
          border-radius: 0;
          width: 3px;
          height: 10px;
          background: $black;
        }
        input[type="range"].volume-slider::-moz-focus-outer {
          border: none;
          outline: none;
        }
        /* IE STYLING */
        input[type="range"].volume-slider::-ms-track {
          width: 46px;
          height: 10px;
          cursor: pointer;
          background-image: linear-gradient(to left, $purple, #eee);
          border-color: transparent;
          color: transparent;
        }
        input[type="range"].volume-slider::-ms-fill-lower {
          background: transparent;
          border: none;
          border-radius: 0;
          box-shadow: none;
        }
        input[type="range"].volume-slider::-ms-fill-upper {
          background: transparent;
          border: none;
          border-radius: 0;
          box-shadow: none;
        }
        input[type="range"].volume-slider::-ms-thumb {
          border: none;
          border-radius: 0;
          width: 3px;
          height: 10px;
          background: $black;
          cursor: pointer;
          box-shadow: none;
        }
      }
      .spinner {
        left: 50% !important;
        margin-left: 2px;
        margin-top: 2px;
      }
      &.spinning {
        > .play,
        .navigate,
        .volume {
          @include opacity(0);
          pointer-events: none;
        }
      }
    }
    .download-options-menu {
      .action {
        &.disabled {
          pointer-events: none;
        }
      }
    }
    .action {
      background: #fff;
      display: block;
      height: 46px;
      left: 0;
      margin: 4px 1px;
      position: relative;
      top: 0;
      width: 55px;
      @include transition(background, 100ms);
      &:hover {
        background: $black;
        svg g {
          fill: #fff;
        }
        .default {
          @include opacity(0);
        }
        .over {
          @include opacity(1);
        }
      }
      &:active {
        @include transition(background, 0ms);
        background: $purple;
      }
      svg {
        height: 45px;
        width: 45px;
        g {
          fill: $black;
          @include transition(fill, 100ms);
          .icon-border {
            display: none;
          }
        }
      }
      &.hide {
        display: none !important;
      }
      &.show {
        display: block !important;
      }
      &.play {
        float: left;
        margin-left: 2px;
        width: 62px;
        .play {
          left: 0;
          position: absolute;
          top: 0;
        }
        .pause {
          display: none;
          left: 0;
          position: absolute;
          top: 0;
        }
        &[data-action="play"] {
          .play {
            display: flex !important;
          }
          .pause {
            display: none !important;
          }
        }
        &[data-action="pause"] {
          .play {
            display: none !important;
          }
          .pause {
            display: flex !important;
          }
        }
      }
      &.next,
      &.prev {
        height: 22px;
        width: 38px;
        svg {
          height: 30px;
          width: 30px;
        }
        &.disabled {
          @include opacity(0.5);
          pointer-events: none;
        }
      }
      &.next {
        margin-bottom: 1px;
      }
      &.prev {
        margin-top: 2px;
        margin-bottom: 0;
      }
      &.license {
        margin-right: 4px;
        width: 170px;
        &:hover {
          .button-text {
            color: #fff;
          }
        }
        .button-text {
          font-size: 0.75rem;
          text-align: center;
          line-height: 1rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        a {
          background: $purple;
          font-weight: bold;
          color: #fff;
        }
      }
      &.favorite {
        .favorite-icon {
          &.selected {
            svg g {
              fill: $green;
            }
          }
        }
      }
      &.history,
      &.stations {
        width: 62px;
      }
      &.history {
        margin-right: 2px;
      }
      &.disabled {
        @include opacity(0.5);
      }
      a {
        display: block;
        height: 100%;
        width: 100%;
      }
      .label {
        display: none;
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
        width: 100%;
        z-index: 100;
      }
      .default,
      .over {
        left: 50%;
        position: absolute;
        top: 50%;
        @include transition(opacity, 100ms);
      }
      .default {
        @include opacity(1);
      }
      .over {
        @include opacity(0);
      }
    }
    .mm-radio-cta {
      background: #f7f7f7;
      display: inline-block;
      float: left;
      height: 46px;
      margin-left: -2px;
      margin-top: 4px;
      padding: 0 10px;
      .mm-radio {
        @include relative;
        cursor: pointer;
        margin-left: 0;
        margin-right: 0;
        line-height: 41px;
        height: 46px;
        z-index: 2;
      }
    }
    .mm-scrubber {
      @include opacity(0);
      border-left: 1px solid #ddd;
      height: 100%;
      left: 122px;
      position: absolute;
      top: 0;
      width: auto;
      @include transition(opacity, 500ms);
      .mm-playing-song-version {
        display: flex;
        .artist-image {
          margin: 4px 0 4px 4px;
          height: 46px;
          width: 46px;
          overflow: hidden;
        }
        .song-version-details {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          margin-left: 7px;
          height: 55px;
          max-width: 275px;
          z-index: 5;
          p {
            color: $black;
            display: inline-block;
            font-size: 0.85rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .song-version-title {
              color: $black;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
            em {
              color: #666;
              font-style: italic;
            }
            a.artist-name {
              color: #666;
              font-style: italic;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .song-version-stream {
          display: flex;
          justify-content: center;
          height: 50px;
          margin: 2px 0 2px 4px;
          overflow: hidden;
          .song-version-time {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 7px;
            width: 70px;
            @include tablet-down {
              display: none;
              width: 0;
            }
            small {
              color: #666;
              font-size: 0.6rem;
              font-weight: 400;
              pointer-events: none;
            }
          }
          .waveform-desktop {
            height: 50px;
            wave {
              overflow-x: hidden !important;
              canvas {
                width: 0px;
              }
            }
          }
        }
      }
    }
    .mm-player-actions {
      backface-visibility: hidden;
      float: right;
      margin-left: 4px;
      .song-version-actions {
        float: left;
        display: none;
        overflow: hidden;
        li {
          float: left;
          z-index: 8;
        }
        li.tooltip {
          @include opacity(0);
          background: rgba(0, 0, 0, 0.8);
          display: none;
          height: 50px;
          margin-right: 0;
          position: absolute;
          top: 2px;
          z-index: -1;
          &.partial-exclusivity-tooltip {
            right: 266px;
            width: 240px;
            &.internal-user {
              right: 323px;
            }
            &.nudge {
              right: 209px;
            }
            &[data-connect-action="license"] {
              right: 210px;
              &.internal-user {
                right: 267px;
              }
            }
            &[data-connect-action="download"] {
              &.internal-user {
                right: 322px;
              }
            }
            &[data-connect-action="project-search"] {
              right: 266px;
              &.internal-user {
                right: 267px;
              }
            }
          }
          &.vintage-tooltip {
            right: 266px;
            width: 240px;
            &.internal-user {
              right: 323px;
            }
            &.nudge {
              right: 209px;
            }
            &.double-tip {
              width: 300px;
            }
          }
          &.double-tip {
            width: 300px;
          }
          &.hide {
            display: none !important;
          }
          &.show {
            @include opacity(1);
            display: block;
            z-index: 10;
          }
          p {
            @include kern(80);
            color: #fff;
            font-family: NeuzeitGro, georgia;
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 0.05rem;
            line-height: 14px;
            padding: 5px 10px 0;
            text-align: left;
            text-transform: uppercase;
            white-space: normal;
          }
          .tri {
            color: rgba(0, 0, 0, 0.8);
            font-size: 20px;
            right: -7px;
            line-height: 43px;
            pointer-events: none;
            position: absolute;
            text-decoration: none;
            top: 3px;
          }
        }
      }
      .mm-history {
        float: left;
        margin-right: 2px;
      }
    }
  }
  .player-history,
  .player-stations {
    background: #fff;
    border-top: 1px solid #b7b7b7;
    left: 0;
    height: 545px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 11;
    @include transition(top, 500ms, $easeInOutExpo);
    .mm-song-versions-table-list {
      display: none;
      position: relative;
      .song-version-badges {
        display: none;
      }
    }
    .wrap {
      background: #fff;
      height: 100%;
      overflow-y: scroll;
      width: 100%;
    }
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      padding-top: 34px;
      width: 100%;
      .mm-radio {
        float: right;
      }
      .close-history,
      .close-stations {
        svg {
          height: 30px;
          width: 30px;
          g {
            fill: $black;
          }
        }
      }
    }
  }
  .mobile-song-version-actions {
    background: #f1efee;
    ul {
      @include relative;
      display: none;
      overflow: hidden;
      left: -2px;
      width: 100%;
      @include calc(width, "100% + 4px");
      li {
        background: #fff;
        display: block;
        float: left;
        margin: 2px;
        text-align: center;
        width: 48%;
        @include calc(width, "50% - 4px");
        &.hide {
          display: none;
        }
        &.mm-player-controls {
          background: none;
          display: block;
          height: 48px;
          margin: 2px 0;
          width: 100%;
          ul {
            height: 100%;
            li {
              background: #fff;
              cursor: pointer;
              float: left;
              height: 100%;
              margin: 2px;
              width: 33%;
              @include calc(width, "33.33% - 4px");
              .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                svg {
                  height: 40px;
                  width: 40px;
                  g {
                    fill: $black;
                    .icon-border {
                      display: none;
                    }
                  }
                }
              }
              &.next,
              &.prev {
                @include relative;
                &.disabled {
                  @include opacity(0.1);
                  pointer-events: none;
                }
              }
              &.play {
                &[data-action="play"] {
                  .play {
                    display: flex !important;
                  }
                  .pause {
                    display: none !important;
                  }
                }
                &[data-action="pause"] {
                  .play {
                    display: none !important;
                  }
                  .pause {
                    display: flex !important;
                  }
                }
              }
            }
          }
          @include touch {
            display: none !important;
          }
        }
      }
    }
  }
  &.expanded {
    @include touch {
      height: auto !important;
    }
    @include mobile {
      height: auto !important;
    }
    .mobile-song-version-actions {
      display: block;
      ul {
        display: block;
      }
      @include touch {
        .mm-player-controls {
          display: block !important;
        }
      }
    }
  }
  .mm-radio {
    @include kern(80);
    color: $black;
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    text-decoration: none;
    text-transform: uppercase;
    .label {
      padding-left: 10px;
    }
    .flank {
      display: inline-block;
      position: relative;
      min-width: 10px;
    }
    .icon {
      transform: scale(-1, 1);
      display: flex;
      align-items: center;
      height: 46px;
      justify-content: center;
      width: 100%;
      svg {
        height: 30px;
        width: 30px;
        g {
          fill: $purple;
          .icon-border {
            display: none;
          }
        }
      }
    }
  }
}

.player-panes-navigation {
  border-left: 1px solid #ddd;
  overflow: hidden;
  padding-left: 3px;
  float: left;
  > div,
  > li {
    float: left;
  }
  .expand-player-actions-menu {
    display: none;
    @include mobile {
      display: none;
    }
    .horizontal-ellipsis {
      height: 45px;
      width: 45px;
      display: block;
      cursor: pointer;
      font-size: 2.4em;
      font-weight: bold;
      position: relative;
      text-align: center;
      top: 2px;
      @include transition(all, 150ms);
      &:hover,
      &.expanded {
        text-shadow: 1px 1px 2px;
      }
    }
  }
}

.player .player-deck .player-actions-menu,
.player .player-deck .download-options-menu {
  background: #e7e7e7;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.3);
  height: auto;
  width: auto;
  overflow: visible;
  position: fixed;
  right: 15px;
  bottom: 65px;
  visibility: hidden;
  z-index: -99999;
  @include mobile {
    display: none;
  }
  &.expanded {
    visibility: visible;
    z-index: 15;
  }
  .action {
    width: auto;
    margin: 0;
    padding: 2px 10px;
    a {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      .icon {
        width: 45px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
      .label {
        width: 85px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        font-size: 0.75rem;
        padding-left: 10px;
      }
    }
    &:hover {
      .label {
        color: #fff;
      }
    }
  }
}

.player .player-deck .download-options-menu {
  right: 270px;

  .action {
    a {
      text-decoration: none;
      flex-direction: unset;
      color: black;

      .label {
        align-items: start;
        justify-content: start;
      }
    }
  }
}

.player.has-song-version {
  .song-version-actions {
    display: block !important;
  }
  .navigate {
    display: block !important;
  }
  .expand-player-actions-menu {
    display: block;
  }
  .mm-scrubber {
    @include opacity(1);
  }
  .player-deck .mm-radio-cta {
    display: none;
  }
}

.mm-player-stations-list {
  margin-top: 30px;
  .stations {
    @include box-sizing;
    overflow: hidden;
    padding: 10px;
    .station {
      display: block;
      float: left;
      height: 290px;
      left: 0;
      margin-bottom: 3.85%;
      margin-right: 3.85%;
      position: relative;
      top: 0;
      width: 22.1%;
      &:nth-child(4n + 4) {
        margin-right: 0;
        @include tablet-down {
          margin-right: 3.85%;
        }
        @include mobile {
          margin-right: 10px;
        }
      }
      @include tablet-down {
        width: 30.75%;
        &:nth-child(3n + 3) {
          margin-right: 0;
        }
      }
      @include mobile {
        @include box-sizing;
        backface-visibility: hidden;
        @include no-select;
        width: 190px;
        height: 217px;
        margin: 10px !important;
        &:last-child {
          margin-right: 5px !important;
        }
      }
      @include not-mobile {
        &:hover,
        &.playing {
          .over {
            @include opacity(1);
          }
          .icon {
            @include opacity(0);
          }
        }
      }
      @include mobile {
        &.hover {
          .over {
            @include opacity(1);
          }
          .icon {
            @include opacity(0);
          }
        }
      }
      .over {
        @include opacity(0);
        border: 2px solid white;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15), 0 1px 0 rgba(0, 0, 0, 0.15) inset;
        display: block;
        height: 100%;
        left: -5px;
        padding: 3px;
        position: absolute;
        top: -5px;
        width: 100%;
        z-index: 2;
        @include transition(opacity, 100ms);
        backface-visibility: hidden;
        > div {
          background: rgba(58, 53, 50, 0.8);
          height: 100%;
          width: 100%;
          .info {
            @include icons-sprite(picks_info_default);
            height: 35px;
            position: absolute;
            right: 13px;
            top: 13px;
            width: 35px;
          }
          a.listen {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            .play-icon {
              svg {
                height: 60px;
                width: 60px;
                g {
                  fill: #fff;
                  @include transition(fill, 150ms);
                  .icon-border {
                    fill: $green;
                  }
                }
              }
            }
            &:active {
              svg g .icon-border {
                fill: #fff;
              }
            }
          }
        }
      }
      hgroup {
        bottom: 0;
        color: #fff;
        left: 0;
        padding: 6.5% 7.9%;
        position: absolute;
        z-index: 2;
        h1 {
          @include kern(-20);
          @include lead(3.2, 3.2);
          text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
          @include mobile {
            font-size: 2.4em;
          }
        }
      }
    }
  }
}

/* target tablet drawer without overriding the mobile definitions */

body[data-device="desktop"] {
  &.touch {
    .player {
      &.mobile {
        .song-version-details,
        .song-version-stream {
          width: 278px !important;
          .waveform-mobile {
            width: 278px;
          }
        }
        .player-deck {
          .mm-player-controls {
            &.desktop {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

.me-plugin {
  position: fixed;
  height: 1px;
  width: 1px;
  bottom: -1px;
  left: -1px;
}
