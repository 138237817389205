@import "globals";

#mm-submissions {
  header#mm-submissions-landing {
    .cover-image {
      background: url("/app/assets/images/headers/brand-update-header.jpg") center center / cover
        no-repeat;
      height: 225px;
      width: 100%;

      @include mobile {
        height: 80px;
      }
    }

    hgroup {
      @include relative;
      background: #efefed;
      min-height: 205px;
      text-align: center;
      width: 100%;
      z-index: 1;

      .contain {
        @include relative;
      }

      .submissions-icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        margin: 0 auto;
        top: 40px;
        width: 80px;

        svg {
          height: 80px;
          width: 80px;

          g {
            fill: $green;
          }
        }
      }

      h1 {
        color: $black;
        padding-top: 50px;
      }
    }
  }

  .submissions-message {
    left: 0;
    position: relative;
    top: 0;
    background: #f8f8f8;
    padding: 12px 0;
    text-align: center;
    font-family: Obibok;
    font-size: 0.5rem;
    font-weight: 100;
    line-height: 1.25rem;

    p {
      padding: 0 10%;
    }
  }
}
