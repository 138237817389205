@import "globals";

.order-summary {
  font-family: Obibok;
  font-weight: 100;
  overflow: hidden;
  @include contain-break;

  .line-items {
    ol {
      .left {
        width: 55%;
      }

      .right {
        width: 45%;
      }

      .left,
      .right {
        float: left;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include tablet-down {
          width: 50%;
        }

        @include under-tablet {
          display: block;
        }
      }

      li {
        float: left;
        height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 100%;
        white-space: nowrap;
        margin-right: 15px;

        &.mobile-play {
          width: 6.452%;
          width: 40px;
        }

        &.art {
          max-height: 50px;
          flex: 0 0 50px;

          @include tablet-down {
            display: none;
          }

          img {
            width: 50px;
          }
        }

        &.notes {
          flex: 2 1 100%;

          @include tablet {
            display: none;
          }
          @include mobile {
            display: none;
          }
        }

        &.cost {
          flex: 0 0 80px;
          text-align: right;
          margin-right: 30px;
          overflow: visible;
        }

        &.download-song,
        &.download-stems {
          flex: 0 0 70px;
          text-align: center;
          overflow: visible;

          a {
            @include relative;
            background: $black;
            display: block;
            width: 70px;
            z-index: 2;
            @include transition(background, 100ms);

            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              overflow: hidden;
              width: 100%;

              svg {
                height: 45px;
                width: 45px;

                g {
                  fill: #fff;

                  .icon-border {
                    display: none;
                  }
                }
              }
            }

            @include not-mobile {
              &:hover {
                @include transition(background, 100ms);
                background: #262220;
              }
            }

            &:active {
              @include transition(background, 0ms);
              background: var(--purple);
            }
          }

          .stems-request {
            background: none;
            font-size: 0.75rem;
            color: var(--purple);
            white-space: normal;
            line-height: normal;
            text-decoration: none;

            &:hover {
              background: none;
              text-decoration: underline;
            }
          }

          .not-available {
            display: block;
            font-size: 0.75rem;
            color: var(--grey);
            white-space: normal;
            line-height: normal;
          }
        }

        &.song-version,
        &.artist {
          a {
            color: $black;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }

        .not-applicable {
          font-size: 0.75rem;
          color: var(--grey);
        }

        @include under-tablet {
          text-align: right;
          line-height: 1em;
          height: 20px;

          &.art {
            display: none;
          }

          &.song-version {
            font-size: 1em;
            width: 100%;
            text-align: left !important;
          }

          &.artist {
            font-size: 0.8em;
            width: 100%;
            width: 100%;
            text-align: left !important;
          }

          &.item {
            font-size: 1em;
            width: 100%;
          }

          &.cost {
            font-size: 0.8em;
            width: 100%;
          }

          &.download-song,
          &.download-stems {
            display: none;
          }
        }
      }
    }

    .right {
      :last-child {
        margin-right: 0;
      }
    }

    .line-items-table-head {
      border-bottom: 2px solid #e7e7e7;
      color: #999;
      margin-bottom: -1px;

      ol {
        display: flex;
        align-items: flex-end;
        margin-bottom: 15px;

        @include under-tablet {
          padding-top: 10px;
          margin-bottom: 0px;
        }

        .left,
        .right {
          align-items: flex-end;
        }

        li {
          font-size: 0.75rem;
          white-space: normal;

          @include under-tablet {
            text-align: right;
            line-height: 1em;
            height: 20px;

            &.song-version,
            &.item,
            &.custom-item {
              font-size: 0.8rem;
            }

            &.artist,
            &.custom-artist,
            &.cost {
              font-size: 0.6rem;
            }
          }
        }
      }
    }

    .line-items-table-list {
      .line-item {
        border-bottom: 1px solid #e7e7e7;
        color: $black;
        font-size: 0.9rem;
        height: 62px;
        line-height: 62px;
        clear: left;

        @include under-tablet {
          padding: 20px 0;
          height: 35px;
        }

        &:last-child {
          border-color: #efefed;
          border-width: 2px;
        }
      }
    }
  }

  .totals {
    font-size: 0.9rem;
    margin-left: 55%;
    margin-right: 185px;
    padding-top: 28px;

    @include tablet-down {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    .subtotal,
    .promotions,
    .total {
      @include lead(3.8, 1.8);
      color: $black;
      overflow: hidden;
    }

    .promotions {
      color: var(--green);
      font-weight: 400;
    }

    .total {
      font-weight: 600;
    }

    .label {
      float: left;
    }

    .value {
      float: right;
      text-align: right;
    }
  }
}
