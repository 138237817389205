.serverRendered {
  .hero {
    padding-bottom: 4.75rem;

    .hOne {
      font-family: ObibokInversed;
      font-weight: 400;
      font-size: 3rem;
    }

    .hTwo {
      font-family: ObibokInversed;
      font-weight: 400;
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 3.75rem;

      .hOne {
        font-size: 2.4rem;
      }
    }

    @media screen and (max-width: 350px) {
      .hOne {
        font-size: 2.1rem;
      }
    }

    .contain {
      height: auto;
      width: auto;
      max-width: none;
      display: flex;
      margin: 0 5rem;

      @media screen and (max-width: 1024px) {
        margin: 0 2.5rem;
      }

      @media screen and (max-width: 767px) {
        margin: 0 1.5rem;
      }
    }

    .imageCol {
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    .descriptionCol {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: 3.5rem;
      padding-bottom: 1.75rem;
      width: 100%;

      @media screen and (max-width: 1024px) {
        margin-left: 0;
      }

      h1,
      h2 {
        padding-bottom: 1.75rem;
      }

      h2 {
        @media screen and (max-width: 767px) {
          font-size: 2.25rem;
        }
      }

      p:last-of-type {
        padding-bottom: 2.75rem;
      }

      .prismicButtonWrapper {
        justify-content: flex-start;
      }

      .prismicButton {
        margin-bottom: 0;
      }
    }
  }
}
