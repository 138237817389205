@import "globals";

#reset-password,
#edit-profile {
  &.artist,
  &.reset {
    .contain {
      width: 350px;
    }
    .spin-target {
      text-align: center;
    }
    button {
      float: none !important;
      margin: 70px auto 50px !important;
    }
  }

  .inline-errors {
    display: none;
  }

  .inline-hints {
    font-size: 1.2em;
    padding: 2px 0 0 2px;
  }

  .column {
    float: left;
    margin-right: 45px;
    width: 350px;
    @include small-display {
      width: 100%;
    }

    &.right {
      margin-right: 0;
    }
  }

  label {
    @include lead(2, 1.2);
    font-family: NeuzeitGro, georgia;
    font-size: 14px;
    color: #333;
    display: block;
    padding-top: 12px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"] {
    font-family: NeuzeitGro, georgia;
    font-size: 1rem;
    border: 2px solid #999;
    color: $black;
    height: 42px;
    outline: none;
    padding: 0 10px;

    &.invalid {
      border-color: $purple;
    }
  }

  #user_address_attributes_country_input {
    clear: both;
    display: block;
    float: none;

    .sorter-wrap {
      max-width: 326px;
      width: 100%;

      .sorter-link {
        height: 42px;
        max-width: 346px;
        width: 100%;

        .selected-option {
          line-height: 42px;
        }
      }

      .options {
        top: 45px;
        max-width: 346px;
        width: 100%;

        .option {
          width: 100%;
        }
      }
    }

    select {
      display: none;
    }
  }

  #user_first_name_input {
    float: left;
    @include small-display {
      width: 50%;
    }
    input {
      width: 147px;
      @include small-display {
        width: 90%;
        padding: 0 2%;
      }
    }
  }

  #user_last_name_input {
    float: right;
    @include small-display {
      width: 50%;
    }
    input {
      width: 147px;
      @include small-display {
        width: 95%;
        padding: 0 2%;
      }
    }
  }

  #user_address_attributes_subregion_input {
    float: left;
    input {
      width: 184px;
    }
    @include small-display {
      margin-right: 20px;
    }
  }

  #user_address_attributes_postal_code_input {
    float: right;
    input {
      width: 110px;
    }
    @include small-display {
      float: left;
    }
  }

  #user_address_attributes_city_input,
  #user_address_attributes_line2_input,
  #user_address_attributes_line1_input,
  #user_phone_number_input,
  #user_company_input,
  #job_other_input,
  #user_password_confirmation_input,
  #user_password_input,
  #user_email_input {
    clear: both;

    input {
      width: 100%;
      box-sizing: border-box;

      @include small-display {
        width: 97.5%;
        padding: 0 1%;
      }
    }
  }

  button[type="submit"] {
    border: none;
    cursor: pointer;
    float: right;
    font-family: Obibok;
    font-size: 0.75rem;
    font-weight: normal;
    letter-spacing: 0.1rem;
    margin-bottom: 50px;
    margin-top: 70px;
    width: 300px;

    @include small-display {
      clear: both;
      float: none;
      width: 100%;
    }
  }
}

#reset-password {
  .inline-errors {
    display: block;
  }
}

#edit-profile {
  .jobs {
    margin-bottom: 5px;
    margin-top: 14px;
    overflow: hidden;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      width: 33.3333%;

      a {
        @include relative;
        display: flex;
        justify-content: center;
        height: 95px;
        margin-bottom: 10px;
        text-decoration: none;
        width: 100%;

        &:hover {
          svg g {
            fill: #745bb4;
          }
        }

        &.active {
          .label {
            color: $purple;
          }
          svg g {
            fill: #745bb4;
          }
        }

        svg {
          width: 70px;
          height: 70px;
          g {
            fill: $black;
          }
        }
      }

      .label {
        font-family: NeuzeitGro, georgia;
        font-size: 14px;
        bottom: 0;
        color: $black;
        display: block;
        left: 0;
        position: absolute;
        text-align: center;
        width: 100%;
      }
    }
  }
}
