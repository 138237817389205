@import "functions";

/*!
 * Global Variables
 */

// Easing
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeOnOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

// Color aliases
$black: #3a3532;
$blue: #689ec0;
$gold: #c58b2b;
$green: #21928e;
$purple: #745bb4;
$red: #c92828;
$gray: #38383c;
$off-white: #f1eff0;

// Devices
$mq-mobile-portrait: 320px !default;
$mq-mobile-landscape: 767px !default;
$mq-tablet-portrait: 768px !default;
$mq-tablet-landscape: 1024px !default;
$mq-small-display: 1180px !default;

// Drawer widths
$drawer-width-desktop: 339px;

// Icons (sprites)
@import "icons/icons-legacy.scss";
@import "icons/icons-retina.scss";
@import "icons/icons-mobile-legacy.scss";
@import "icons/icons-mobile-retina.scss";
@import "icons/icons-social-legacy.scss";
@import "icons/icons-social-retina.scss";

$icons-map: (
  "legacy": $icons-legacy,
  "retina": $icons-retina,
  "mobile-legacy": $icons-mobile-legacy,
  "mobile-retina": $icons-mobile-retina,
  "social-legacy": $icons-social-legacy,
  "social-retina": $icons-social-retina,
);

/*!
 * Media Queries
 */

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mq-mobile-landscape) {
    @content;
  }
}

@mixin not-mobile {
  @media (min-width: calc($mq-mobile-landscape + 1px)) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: calc($mq-mobile-landscape + 1px)) and (max-width: $mq-tablet-landscape) {
    @content;
  }
}

@mixin not-retina-tablet {
  @include retina {
    @include tablet {
      @include not-touch {
        @content;
      }
    }
  }
}

@mixin tablet-portrait-down {
  @media only screen and (max-width: $mq-tablet-portrait) {
    @content;
  }
}

@mixin tablet-down {
  @media only screen and (max-width: $mq-tablet-landscape) {
    @content;
  }
}

@mixin under-tablet {
  @media only screen and (max-width: calc($mq-tablet-portrait - 1px)) {
    @content;
  }
}

@mixin small-display {
  @media only screen and (max-width: $mq-small-display) {
    @content;
  }
}

@mixin breakpoint($break) {
  @media only screen and (max-width: $break) {
    @content;
  }
}

@mixin content-break {
  @include breakpoint(1140px) {
    @content;
  }
}

@mixin touch {
  body.touch & {
    @content;
  }
}

@mixin not-touch {
  body:not(.touch) & {
    @content;
  }
}

@mixin contain-break {
  @include content-break {
    padding-left: 20px;
    padding-right: 20px;
    @include calc(width, "100% - 40px");
  }
}

/*!
 * General Mixins
 */

// force gpu acceleration
@mixin accelerate {
  -webkit-transform: translate3d(0, 0, 0);
}

// PSD Kern to letter-spacing
@mixin kern($kern: 1) {
  $kern: calc($kern / 625) + em;
  letter-spacing: $kern;
}

// PSD Lead to line-height
@mixin lead($lead: 1, $fs: 1) {
  $lead: calc($lead / $fs) + em;
  line-height: $lead;
}

// safely handle opacity for IE
@mixin opacity($opacity: 0) {
  filter: alpha(opacity=calc($opacity * 100));
  -moz-opacity: $opacity;
  -khtml-opacity: $opacity;
  opacity: $opacity;
}

@mixin relative {
  left: 0;
  position: relative;
  top: 0;
}

// handle all the prefixes
@mixin transition($prop: all, $duration: 250ms, $ease: linear, $delay: 0) {
  -webkit-transition-property: $prop;
  -webkit-transition-duration: $duration;
  -webkit-transition-timing-function: $ease;
  -webkit-transition-delay: $delay;
  -moz-transition-property: $prop;
  -moz-transition-duration: $duration;
  -moz-transition-timing-function: $ease;
  -moz-transition-delay: $delay;
  -ms-transition-property: $prop;
  -ms-transition-duration: $duration;
  -ms-transition-timing-function: $ease;
  -ms-transition-delay: $delay;
  -o-transition-property: $prop;
  -o-transition-duration: $duration;
  -o-transition-timing-function: $ease;
  -o-transition-delay: $delay;
  transition-property: $prop;
  transition-duration: $duration;
  transition-timing-function: $ease;
  transition-delay: $delay;
}

@mixin mult-trans($string) {
  -webkit-transition: $string;
  -moz-transition: $string;
  -ms-transition: $string;
  transition: $string;
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: moz-none;
  -ms-user-select: none;
  user-select: none;
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin box-sizing($definition: border-box) {
  -moz-box-sizing: $definition;
  -webkit-box-sizing: $definition;
  box-sizing: $definition;
}

/*!
 * Application specific
 */

// typeface
@mixin elena {
  font-family: "elena-web-basic", georgia;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

@mixin proxima {
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

@mixin proxima-semibold {
  @include proxima;
  font-weight: 600;
}

@mixin felt-tip {
  font-family: "felt-tip-roman-n4", "felt-tip-roman", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "Obibok";
  src: url("/app/assets/fonts/Obibok-Black.woff2") format("woff2");
  src: url("/app/assets/fonts/Obibok-Black.woff") format("woff");
  font-weight: 900;
}

@font-face {
  font-family: "Obibok";
  src: url("/app/assets/fonts/Obibok-Bold.woff2") format("woff2");
  src: url("/app/assets/fonts/Obibok-Bold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Obibok";
  src: url("/app/assets/fonts/Obibok-Regular.woff2") format("woff2");
  src: url("/app/assets/fonts/Obibok-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Obibok";
  src: url("/app/assets/fonts/Obibok-Light.woff2") format("woff2");
  src: url("/app/assets/fonts/Obibok-Light.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "ObibokInversed";
  src: url("/app/assets/fonts/Obibok-Inversed.woff2") format("woff2");
  src: url("/app/assets/fonts/Obibok-Inversed.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "NeuzeitGro";
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Regular.woff2") format("woff2");
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Regular.woff") format("woff");
}
@font-face {
  font-family: "NeuzeitGroLight";
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Light.woff2") format("woff2");
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Light.woff") format("woff");
}
@font-face {
  font-family: "NeuzeitGroBold";
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Bold.woff2") format("woff2");
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Bold.woff") format("woff");
}
@font-face {
  font-family: "NeuzeitGroBlk";
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Black.woff2") format("woff2");
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Black.woff") format("woff");
}
@font-face {
  font-family: "NeuzeitGroBlkCond";
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Black-Cond.woff2") format("woff2");
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Black-Cond.woff") format("woff");
}
@font-face {
  font-family: "NeuzeitGroBlkExCond";
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Black-Extra-Cond.woff2") format("woff2");
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Black-Extra-Cond.woff") format("woff");
}

@font-face {
  font-family: "NeuzeitGro";
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Regular.woff2") format("woff2");
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Regular.woff") format("woff");
}

@font-face {
  font-family: "NeuzeitGroLight";
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Light.woff2") format("woff2");
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Light.woff") format("woff");
}

@font-face {
  font-family: "NeuzeitGroBold";
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Bold.woff2") format("woff2");
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Bold.woff") format("woff");
}

@font-face {
  font-family: "NeuzeitGroBlk";
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Black.woff2") format("woff2");
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Black.woff") format("woff");
}

@font-face {
  font-family: "NeuzeitGroBlkCond";
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Black-Cond.woff2") format("woff2");
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Black-Cond.woff") format("woff");
}

@font-face {
  font-family: "NeuzeitGroBlkExCond";
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Black-Extra-Cond.woff2") format("woff2");
  src: url("/app/assets/fonts/Neuzeit-Grotesk-Black-Extra-Cond.woff") format("woff");
}

// UI
@mixin box-button($color: #fff) {
  @include kern(80);
  font-family: Obibok;
  font-size: 0.75rem;
  border: 2px solid $color;
  color: $color;
  display: inline-block;
  font-weight: 400;
  height: 40px;
  line-height: 40px;
  padding: 0 38px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  @include transition(all, 100ms);

  &.fixed {
    padding: 0;
  }

  &:hover {
    @include transition(all, 100ms);
    background: #fff;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
    color: $purple;
  }

  &:active {
    @include transition(none);
    background: $purple;
    border-color: $purple;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  }
}
.box-button {
  @include box-button;
}

@mixin button-states {
  @include transition(background, 100ms);
  @include not-mobile {
    &:hover {
      background: #262220;
    }
  }
  &:active {
    @include transition(background, 0ms);
    background: $purple;
  }
}

@mixin anchor-button-states {
  @include transition(background, 100ms);
  @include not-mobile {
    &:hover {
      background: $black;
    }
  }
  &:active {
    @include transition(background, 0ms);
    background: $purple;
  }
}

@mixin box-button-black {
  @include kern(80);
  font-family: Obibok;
  font-size: 0.75rem;
  background: $black;
  color: #fff;
  box-shadow: none;
  font-weight: 400;
  height: 60px;
  line-height: 60px;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: uppercase;
  @include button-states;
}
.box-button-black {
  @include box-button-black;
}

/**
 * Used to center an absolutely positioned button
 */
@mixin box-button-wrapper($top: auto, $right: auto, $bottom: auto, $left: auto) {
  bottom: $bottom;
  left: $left;
  position: absolute;
  right: $right;
  text-align: center;
  top: $top;
  width: 100%;
}
.box-button-wrapper {
  @include box-button-wrapper;
}

@mixin fluid-square($width: 50%) {
  @include relative;
  padding: 0 0 $width 0;
  width: $width;

  > .inner {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@mixin grab {
  cursor: pointer;
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: url("../images/openhand.cur"), auto;
}

@mixin grabbing {
  cursor: pointer;
  cursor: grabbing;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: url("../images/closedhand.cur"), auto;
}

@mixin spread-headline($padding-top: 60px, $color: #fff) {
  @include elena;
  @include kern(1200);
  color: $color;
  font-size: 1.8em;
  margin: 0 auto;
  max-width: 1106px;
  text-indent: 34px;
  // padding-left: 34px;
  padding-top: $padding-top;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  @include mobile {
    @include kern(400);
    font-size: 1.4em;
    white-space: nowrap;
    text-indent: 15px;
  }
}
.spread-headline {
  @include spread-headline;
}

// SPRITES!!!

@mixin icons-sprite($name) {
  $height: mm_sprite_height($name);
  $width: mm_sprite_width($name);
  background-image: url(mm_sprite_image($name));
  background-position: mm_sprite_position($name);
  background-repeat: no-repeat;
  display: block;
  height: $height + px;
  width: $width + px;

  @include retina {
    $sh: mm_sheet_height(legacy) + px;
    $sw: mm_sheet_width(legacy) + px;
    background-image: url(mm_sheet_image(retina));
    background-size: $sw $sh;
  }
}

@mixin social-sprite($name) {
  $height: mm_sprite_height($name, social-legacy);
  $width: mm_sprite_width($name, social-legacy);
  background-image: url(mm_sprite_image($name, social-legacy));
  background-position: mm_sprite_position($name, social-legacy);
  background-repeat: no-repeat;
  display: block;
  height: $height + px;
  width: $width + px;

  @include retina {
    $sh: mm_sheet_height(social-legacy) + px;
    $sw: mm_sheet_width(social-legacy) + px;
    background-image: url(mm_sheet_image(social-retina));
    background-size: $sw $sh;
  }
}

@mixin m-icons-sprite($name) {
  $height: mm_sprite_height($name, mobile-legacy);
  $width: mm_sprite_width($name, mobile-legacy);
  background-image: url(mm_sprite_image($name, mobile-legacy));
  background-position: mm_sprite_position($name, mobile-legacy);
  background-repeat: no-repeat;
  display: block;
  height: $height + px;
  width: $width + px;

  @include retina {
    $sh: mm_sheet_height(mobile-legacy) + px;
    $sw: mm_sheet_width(mobile-legacy) + px;
    background-image: url(mm_sheet_image(mobile-retina));
    background-size: $sw $sh;
  }
}

@mixin center-sprite($name) {
  $left: mm_sprite_center_left($name, legacy);
  $top: mm_sprite_center_top($name, legacy);
  @include icons-sprite($name);
  left: 50%;
  margin-left: $left + px;
  margin-top: $top + px;
  position: absolute;
  top: 50%;
}

@mixin m-center-sprite($name) {
  $left: mm_sprite_center_left($name, mobile-legacy);
  $top: mm_sprite_center_top($name, mobile-legacy);
  @include m-icons-sprite($name);
  left: 50%;
  margin-left: $left + px;
  margin-top: $top + px;
  position: absolute;
  top: 50%;
}

@mixin icons-sprite-state($default, $hover, $active, $duration: 250, $ease: linear) {
  $delay: calc($duration / 2);
  $delay: $delay + ms;
  $duration: $duration + ms;

  display: block;
  height: mm_sprite_height($default) + px;
  left: 0;
  position: relative;
  top: 0;
  width: mm_sprite_width($default) + px;

  span {
    left: 0;
    position: absolute;
    top: 0;
    // @include transition(opacity, $duration, $ease);
  }

  .default {
    @include transition(opacity, $duration, $ease, 0ms);
    @include icons-sprite($default);
    @include opacity(1);
  }

  .hover {
    @include transition(opacity, $duration, $ease, $delay);
    @include icons-sprite($hover);
    @include opacity(0);
  }

  .active {
    @include transition(opacity, $duration, $ease, $delay);
    @include icons-sprite($active);
    @include opacity(0);
  }

  &:hover,
  &:focus {
    .default {
      @include transition(opacity, $duration, $ease, $delay);
      @include opacity(0);
    }

    .active {
      @include transition(opacity, $duration, $ease, $delay);
      @include opacity(0);
    }

    .hover {
      @include transition(opacity, $duration, $ease, 0ms);
      @include opacity(1);
    }
  }

  &:active,
  &.active {
    .default {
      @include transition(opacity, $duration, $ease, $delay);
      @include opacity(0);
    }

    .hover {
      @include transition(opacity, $duration, $ease, $delay);
      @include opacity(0);
    }

    .active {
      @include transition(opacity, 0ms, $ease, 0ms);
      @include opacity(1);
    }
  }
}

@mixin social-sprite-state($default, $hover, $active, $duration: 250, $ease: linear) {
  $delay: calc($duration / 2);
  $delay: $delay + ms;
  $duration: $duration + ms;

  display: block;
  height: mm_sprite_height($default, social-legacy) + px;
  left: 0;
  position: relative;
  top: 0;
  width: mm_sprite_width($default, social-legacy) + px;

  span {
    left: 0;
    position: absolute;
    top: 0;
  }

  .default {
    @include transition(opacity, $duration, $ease, 0ms);
    @include social-sprite($default);
    @include opacity(1);
  }

  .hover {
    @include transition(opacity, $duration, $ease, $delay);
    @include social-sprite($hover);
    @include opacity(0);
  }

  .active {
    @include transition(opacity, $duration, $ease, $delay);
    @include social-sprite($active);
    @include opacity(0);
  }

  &:hover,
  &:focus {
    .default {
      @include transition(opacity, $duration, $ease, $delay);
      @include opacity(0);
    }

    .active {
      @include transition(opacity, $duration, $ease, $delay);
      @include opacity(0);
    }

    .hover {
      @include transition(opacity, $duration, $ease, 0ms);
      @include opacity(1);
    }
  }

  &:active,
  &.active {
    .default {
      @include transition(opacity, $duration, $ease, $delay);
      @include opacity(0);
    }

    .hover {
      @include transition(opacity, $duration, $ease, $delay);
      @include opacity(0);
    }

    .active {
      @include transition(opacity, 0ms, $ease, 0ms);
      @include opacity(1);
    }
  }
}

// Set input placeholder text color
@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin sticky($height) {
  @include not-mobile {
    $stuck-height: $height - 93;

    header[role="main"],
    .header-bg {
      left: 0;
      position: fixed;
      top: 0;

      &.absolute {
        position: absolute;
        top: $stuck-height + px;
      }
    }

    header[role="main"] {
      @include transition(margin-left, 500ms, $easeOutExpo, 300ms);

      &.absolute {
        @include transition(margin-left, 0ms);
      }
    }

    .header-bg {
      @include transition(left, 500ms, $easeOutExpo, 300ms);

      &.absolute {
        @include transition(left, 0ms);
      }
    }

    @content;
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(pulse) {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

@include keyframes(spool) {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.5);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@include keyframes(spin) {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@include keyframes(scaler) {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.25);
  }
}

@include keyframes(searchRoundSpinner) {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@include keyframes(soundwave) {
  0% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
  50% {
    transform: scaleY(0.7);
    transform-origin: 50% 50%;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
}

@mixin animation($args) {
  -webkit-animation: $args;
  -moz-animation: $args;
  -o-animation: $args;
  -ms-animation: $args;
  animation: $args;
}

@mixin animation-fill-mode($mode: both) {
  -webkit-animation-fill-mode: $mode;
  -moz-animation-fill-mode: $mode;
  -ms-animation-fill-mode: $mode;
  -o-animation-fill-mode: $mode;
  animation-fill-mode: $mode;
}

@mixin pulse {
  @include animation(pulse 3s linear infinite);
}

@mixin searchRoundSpinner {
  @include animation(searchRoundSpinner 1s infinite);
}

@mixin soundwave {
  @include animation(soundwave 1s infinite);
}
