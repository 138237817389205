/*!
 * Functions
 */

// SassC/LibSass-compatible deep map search
// Todo: When upgrading to Dart Sass, simply use map-get with additional properties to get a nested property value
@function map-deep-get($map, $keys...) {
  $scope: $map;
  $i: 1;
  @while (type-of($scope) == map) and ($i <= length($keys)) {
    $scope: map-get($scope, nth($keys, $i));
    $i: $i + 1;
  }
  @return $scope;
}

@function mm_sprite_image($icon, $song: "legacy") {
  $path: "/app/assets/images/icons-" + $song + ".png";
  @return $path;
}

@function mm_sprite_position($icon, $song: "legacy") {
  $sheet: map-get($icons-map, $song);
  $x: map-deep-get($sheet, $icon, "x");
  $y: map-deep-get($sheet, $icon, "y");
  @return -#{$x}px -#{$y}px;
}

// Returns the height of a given sprite, you must append units after it.
// eg: height: mm_sprite_height(icon_name) + px;
@function mm_sprite_height($icon, $song: "legacy") {
  $sheet: map-get($icons-map, $song);
  $height: map-deep-get($sheet, $icon, "h");
  @return $height;
}

// Returns half the height of a given sprite, you must append units.
// eg: margin-top: mm_sprite_half_height(icon_name) + px
@function mm_sprite_half_height($icon, $song: "legacy") {
  $height: mm_sprite_height($icon, $song);
  @return calc($height / 2);
}

// Returns a negative value to help center a sprite, you must append units.
// eg:
//   top: 50%;
//   margin-top: mm_sprite_center_top(icon_name) + px;
@function mm_sprite_center_top($icon, $song: "legacy") {
  $height: mm_sprite_half_height($icon, $song);
  @return calc($height * -1);
}

// Returns the width of a given sprite, you must append units.
// eg: width: mm_sprite_width(icon_name) + px;
@function mm_sprite_width($icon, $song: "legacy") {
  $sheet: map-get($icons-map, $song);
  $width: map-deep-get($sheet, $icon, "w");
  @return $width;
}

// Returns half the width of a given sprite, you must append units.
// eg: margin-left: mm_sprite_half_width(icon_name) + px;
@function mm_sprite_half_width($icon, $song: "legacy") {
  $width: mm_sprite_width($icon, $song);
  @return calc($width / 2);
}

// Returns a negative value to help center a sprite, you must append units.
// eg:
//   left: 50%;
//   margin-left: mm_sprite_center_left(icon_name) + px;
@function mm_sprite_center_left($icon, $song: "legacy") {
  $width: mm_sprite_half_width($icon, $song);
  @return calc($width * -1);
}

// Returns just the sprite sheet image path, useful for background-image prop
@function mm_sheet_image($song) {
  $path: "/app/assets/images/icons-" + $song + ".png";
  @return $path;
}

// Returns the height of the full sprite sheet, you must append units.
// useful for background-size prop
@function mm_sheet_height($song) {
  $sheet: map-get($icons-map, $song);
  $height: map-get($sheet, "sheet_height");
  @return $height;
}

// Returns the height of the full sprite sheet, you must append units.
// useful for background-size prop
@function mm_sheet_width($song) {
  $sheet: map-get($icons-map, $song);
  $width: map-get($sheet, "sheet_width");
  @return $width;
}
