@import "globals";

#mm-project-search-container {
  min-height: 800px;

  #mm-project-search {
    &.client-user {
      .heading hgroup {
        padding-bottom: 10px;

        p {
          cursor: text !important;

          &:hover {
            color: $black !important;
          }
        }
      }

      .loading-error-header {
        padding-bottom: 1rem;
      }

      .loading-error-message {
        font-family: NeuzeitGro, georgia;
        font-size: 1.5rem;
      }

      article.song-versions {
        .song-version-item {
          cursor: unset !important;
        }

        ol li {
          &.song-version {
            @include calc(width, "68% - 207px");
            @include transition(width, 500ms);

            @include tablet {
              @include calc(width, "72% - 174px");
            }

            @include mobile {
              @include calc(width, "64% - 67px");
            }
          }

          &.download-menu,
          &.notes-field {
            right: 127px !important;
          }
        }
      }
    }

    header#mm-project-search-landing {
      .cover-image {
        background: url("/app/assets/images/headers/brand-update-header.jpg") center center / cover
          no-repeat;
        height: 7.5rem;
        width: 100%;

        @include mobile {
          height: 80px;
        }
      }

      .heading {
        background: #efefed;
        min-height: 224px;
        text-align: center;
        width: 100%;

        .contain {
          @include relative;
        }

        .project-search-icon {
          @include relative;
          background: #fff;
          border-radius: 50%;
          display: block;
          height: 80px;
          margin: 0 auto;
          top: 40px;
          width: 80px;

          svg {
            height: 80px;
            width: 80px;

            g {
              fill: $green;
            }
          }
        }

        h1 {
          @include relative;
          color: $black;
          margin-top: 50px;

          input,
          p {
            background: transparent;
            border: none;
            color: $black;
            cursor: pointer;
            font-family: Obibok;
            font-size: 2.25rem;
            outline: none;
            text-align: center;
            width: 100%;

            &:hover {
              color: rgba(58, 53, 50, 0.6);
            }

            &:focus {
              color: $black;
              cursor: text;
            }
          }
        }

        .project-search-description {
          min-height: 25px;
          padding: 5em 0;
          position: relative;

          textarea,
          p {
            background: transparent;
            border: none;
            color: $black;
            cursor: pointer;
            font-family: NeuzeitGro, georgria;
            font-size: 2em;
            line-height: 1.2em;
            margin: 0 auto;
            outline: none;
            overflow-y: hidden;
            resize: none;
            text-align: center;
            width: 60%;

            &:hover {
              color: rgba(58, 53, 50, 0.6);
            }

            &:focus {
              color: $black;
              cursor: text;
            }

            @media (max-width: 700px) {
              width: 80%;
            }
          }

          textarea {
            max-height: 250px;
            overflow: visible;
          }
        }

        .info {
          @include relative;
          font-family: Obibok;
          font-weight: 100;
          padding-bottom: 25px;
          text-align: center;
          @include mobile {
            padding-bottom: 20px;
          }

          ul {
            display: inline-block;
            overflow: hidden;

            li {
              @include lead(2.8, 1.6);
              @include kern(-20);
              @include relative;
              color: $black;
              font-size: 0.8rem;
              float: left;
              @include mobile {
                float: none;
              }

              &:after {
                content: "-";
                color: #999;
                padding: 0 6px;
              }
              @include mobile {
                &:after {
                  content: "";
                  padding: 0;
                }
              }

              &:last-child:after {
                content: "";
              }

              em {
                color: #666;
              }

              time {
              }
            }
          }
        }
      }
    }

    mark {
      background: #fff;
      border: 2px solid #efefed;
      border-radius: 50%;
      display: block;
      height: 22px;
      float: left;
      width: 22px;

      &.license-button {
        font-family: NeuzeitGro, georgia;
        font-size: 16px;
        color: $green;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
        text-decoration: none;
        width: 25px;

        @include mobile {
          display: none;
        }
      }

      &:hover {
        background: $green;
        border: 0px;
        color: #fff;
        cursor: pointer;
        padding: 2px;
      }
    }

    .actions {
      @include relative;
      background: #f8f8f8;
      min-height: 50px;
      padding: 12px 0;
      text-align: center;

      > .contain {
        @include relative;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .round-list,
      .actions-list {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .round-list {
        .round-selector {
          @include transition(background, 100ms);
          @include transition(color, 100ms);
          font-family: Obibok;
          font-weight: 100;
          font-size: 26px;
          background: #fff;
          border: none;
          outline: none;
          height: 47px;
          width: 47px;
          margin: 1px 1px;
          cursor: pointer;
          border-bottom: 3px solid transparent;
          position: relative;

          &.hidden {
            color: #fff;
            background-color: #8c8c8c;

            .icon {
              display: block;
              left: 0;
              opacity: 0.3;
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              @include transition(opacity, 200ms);

              svg {
                height: 45px;
                width: 45px;

                g {
                  fill: #fff;

                  .icon-border {
                    display: none;
                  }
                }
              }
            }
          }

          &:hover {
            color: #fff;
            background: $gray;
            &.hidden {
              color: #8c8c8c;
            }
          }

          &.all-selector {
            width: 75px;
          }

          &.selected {
            background: $purple;
            color: #fff;
          }

          &:hover:not(.selected) {
            .icon {
              opacity: 1;
            }
          }

          &:active,
          &.selected {
            &:hover {
              color: #fff;
            }
          }
        }
      }

      .actions-list {
        .action {
          @include transition(background, 100ms);
          cursor: pointer;
          background: #fff;
          border: none;
          color: $black;
          font-family: Obibok;
          font-size: 0.8rem;
          font-weight: 100;
          height: 47px;
          line-height: 1rem;
          margin: 2px 0 0 0;
          outline: none;
          text-transform: capitalize;
          width: 100%;

          &:hover {
            color: #fff;

            &:not(.disabled) {
              background: $gray;
            }
          }

          &:active {
            &:not(.disabled) {
              background: $purple;
            }
          }

          &.disabled {
            @include opacity(0.5);
            cursor: default;
            pointer-events: none;

            &.won {
              background: $green;
            }

            &.lost {
              background: $red;
            }

            &.unqualified {
              background: #e29b20;
              width: 100px;
            }
          }

          &.won {
            background: #21928ec9;

            &:hover {
              background: $green;
            }
          }

          &.lost {
            background: rgba(201, 40, 40, 0.5);

            &:hover {
              background: lighten($red, 15%);
            }
          }

          &.won,
          &.lost,
          &.unqualified {
            font-size: 0.9rem;
            margin-right: 2px;

            &.disabled {
              @include opacity(1);
              color: #fff;
            }
          }

          &.toggle-btn {
            background: #efefed;

            &.enabled {
              background: $purple;
              color: #fff;

              &:hover {
                background: #efefed;
                color: $black;
              }
            }
          }

          &.download {
            font-size: 0.75rem;
            margin: 0 2px 0 0;
            width: 80px;
          }

          &.download {
            &:hover {
              span {
                color: #fff;
              }
            }
          }

          &.new-round {
            background: rgba(0, 0, 0, 0.6);
            color: #fff;
          }

          &.view-in-portal {
            background: var(--lightest-purple);
            color: #fff;

            &:hover {
              background: var(--purple);
            }
          }

          &.delete {
            background: rgba(201, 40, 40, 0.5);

            &:hover {
              background: rgba(201, 40, 40, 0.8);
            }
          }

          .label {
            display: none;
          }
        }
      }

      .download-notice {
        background: rgba(0, 0, 0, 0.7);
        border-radius: 10px;
        box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.3);
        color: #fff;
        display: flex;
        font-family: Obibok;
        font-weight: 100;
        line-height: 26px;
        min-height: 26px;
        left: 50%;
        max-width: 350px;
        top: 0;
        transform: translateX(-50%);
        padding: 12px 15px;
        position: absolute;
        z-index: 5;

        &.ready p {
          margin-right: 50px;
        }

        &.timeout {
          background: $red;
        }

        p {
          font-size: 0.9rem;
          text-align: left;
        }

        span.close {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-right: 15px;

          svg {
            height: 20px;
            width: 20px;

            g {
              fill: #fff;
            }
          }
        }

        .spinner {
          border-top-color: #fff;
          border-left-color: #fff;
          border-bottom-color: transparent;
          border-right-color: transparent;
          border-style: solid;
          border-width: 2px;
          border-radius: 50%;
          box-sizing: border-box;
          display: inline-block;
          margin-left: 15px;
          width: 1.5rem;
          height: 1.5rem;
          vertical-align: middle;
          animation: spin 1s linear infinite;
        }

        a {
          background: #e7e7e7;
          border: 2px solid #e7e7e7;
          border-radius: 10px;
          display: inline-block;
          height: 30px;
          margin: 0 auto;
          position: absolute;
          right: 12px;
          top: 7px;
          width: 40px;
          @include transition(background, 100ms);

          &:hover {
            background: #fff;
            border: 2px solid #fff;
          }

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: auto;

            svg {
              height: 45px;
              width: 45px;

              g {
                fill: $black;

                .icon-border {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .search-round-actions {
      &.multiple-rounds {
        margin-top: 15px;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .dropdown-menu {
        .dropdown-btn {
          background: white;
          width: 47px;
          height: 47px;

          .ellipsis-icon {
            fill: $gray;
          }

          &:hover {
            background: $gray;

            .ellipsis-icon {
              fill: #fff;
            }
          }

          &.dropdown-active {
            background: $gray;

            .ellipsis-icon {
              fill: #fff;
            }
          }
        }

        .dropdown-panel {
          padding: 0 2px 2px 2px;
          width: 220px;
          left: 52px;
          top: -2px;
          z-index: 4;

          @media (max-width: 620px) {
            top: 50px;
            left: -156px;
          }
        }
      }

      .won-lost {
        display: flex;

        .action {
          margin-top: 0;
          color: white;
          width: 60px;
        }
      }

      .download-options-menu {
        background: #e7e7e7;
        box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.3);
        height: auto;
        width: auto;
        overflow: visible;
        position: absolute;
        left: 45%;
        top: 100%;
        visibility: hidden;
        margin-top: 20px;
        z-index: -99999;

        @include mobile {
          display: none;
        }
        &.expanded {
          visibility: visible;
          z-index: 15;
        }
        li.action {
          width: auto;
          margin: 0 !important;
          padding: 2px 10px;
          width: 120px !important;

          background: #fff;
          @include transition(background, 100ms);
          a {
            display: flex;
            text-decoration: none;
            align-items: center;
            color: black;
            .icon {
              width: 45px;
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;
            }
            .label {
              width: 85px;
              display: flex;
              align-items: flex-end;
              font-size: 0.75rem;
              padding-left: 10px;
              font-weight: 100;
              text-align: left;
            }
          }

          svg {
            height: 45px;
            width: 45px;
            g {
              fill: $black;
              @include transition(fill, 100ms);
              .icon-border {
                display: none;
              }
            }
          }

          &.disabled {
            @include opacity(0.5);
            pointer-events: none;
          }

          &:hover {
            background: $black;
            .label {
              color: #fff;
            }
            svg g {
              fill: #fff;
            }
            .default {
              @include opacity(0);
            }
            .over {
              @include opacity(1);
            }
          }
        }
      }
    }

    /*
    *** Song Versions List
     */

    .song-versions {
      padding: 15px 0 50px 0;

      &:last-of-type {
        padding: 15px 0 95px 0;
      }

      &.all-rounds {
        .song-version-item {
          cursor: unset !important;
        }
      }

      .round-divider,
      .direction-divider {
        font-family: Obibok;
        font-size: 1.75rem;
        padding-left: 15px;

        &:first-of-type {
          padding-top: 15px;
        }

        &.edit-actions {
          position: relative;

          form {
            display: flex;
            justify-content: space-between;

            .input-wrapper {
              flex: unset;
              -webkit-box-flex: unset;
              margin-bottom: unset;
              width: 100%;

              input {
                font-family: Obibok;
                font-size: 1.75rem;
                border: none;
                width: 100%;

                &:hover,
                &:focus {
                  outline: 0;
                }

                &:hover {
                  color: rgba(58, 53, 50, 0.6);
                  cursor: pointer;
                }
                &:focus {
                  color: #000;
                  cursor: auto;
                }
              }
            }

            .action-buttons {
              .edit-action {
                @include box-button-black;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.7rem;
                height: 45px;
                letter-spacing: 0.1rem;
                line-height: 1rem;
              }

              .save-cancel-actions {
                display: flex;

                button {
                  border-radius: 25px;
                  width: 100px;

                  &:first-of-type {
                    margin-right: 5px;
                  }
                }

                .save-name,
                .cancel-edit {
                  &.disabled {
                    background: #999;
                    cursor: default;

                    &:hover {
                      background: #999;
                      cursor: default;
                    }
                  }
                }
              }

              .delete-direction {
                background: rgba(201, 40, 40, 0.7);
                border-radius: 25px;
                width: 125px;

                &:hover {
                  background: rgba(201, 40, 40, 0.9);
                }

                &.disabled {
                  background: #999;
                  cursor: default;

                  &:hover {
                    background: #999;
                    cursor: default;
                  }
                }
              }
            }
          }

          .error-message {
            background: $red;
            border-radius: 15px;
            bottom: -5px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Obibok;
            font-size: 0.9rem;
            height: 60px;
            left: 0;
            position: absolute;
            width: 100%;
            z-index: 1000;

            span.close {
              cursor: pointer;
              font-weight: 600;
              left: 0;
              margin-left: 15px;
              position: absolute;
            }
          }
        }
      }

      .mm-project-search-song-versions-table {
        @include relative;

        ol li {
          margin-right: 1%;

          @include tablet-down {
            margin-right: 0;
          }

          &.position {
            border-right: 2px solid #d3d3d3;
            margin-right: 0.5%;
            margin-left: 0.5%;
            width: 1.8%;
          }

          &.play {
            width: 64px;

            @include tablet-down {
              width: 55px;
            }
          }

          &.song-version {
            @include calc(width, "54% - 261px");
            @include transition(width, 500ms);

            @include tablet {
              @include calc(width, "60% - 261px");
            }

            @include mobile {
              @include calc(width, "64% - 67px");
            }
          }

          &.customizable {
            text-align: center;
            width: 5%;
            @include transition(width, 500ms);
          }

          &.rating {
            text-align: center;
            width: 14%;
            @include transition(width, 500ms);

            @include tablet {
              width: 16%;
            }
            @include mobile {
              width: 37%;
            }
          }

          &.length {
            text-align: center;
            width: 6%;
            @include transition(width, 500ms);
          }

          &.chosen-by {
            text-align: center;
            text-overflow: ellipsis;
            width: 13%;
          }

          &.download,
          &.similar,
          &.remove,
          &.notes,
          &.winner {
            display: inline-block;

            .loading {
              margin: 7px auto;
            }
          }

          &.disabled {
            pointer-events: none;
            .icon {
              svg {
                g {
                  opacity: 0.3;
                }
              }
            }
          }
        }

        .mm-song-versions-table-head {
          border-bottom: 2px solid #d3d3d3;
          color: #999;
          font-family: NeuzeitGro, georgia;

          @include mobile {
            border-width: 1px;
            .mobile-play {
              display: none !important;
            }
          }

          ol {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 54px;

            @include mobile {
              height: 25px;
            }

            .position {
              border: none;
            }

            .table-head-right {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              li {
                display: flex;
                justify-content: center;
                width: 45px;
                min-width: 45px;
                margin: 0 5px;
                text-transform: capitalize;

                &:nth-child(4) {
                  margin-right: 12px;
                }

                @include tablet-down {
                  margin-right: 0;
                }
              }
            }

            li {
              font-size: 13px;
              height: unset;

              &:not(.song-version) {
                text-align: center;
              }

              &.song-version {
                text-indent: 10px;

                @include tablet {
                  text-indent: 20px;
                }
              }

              &.length {
                text-indent: 10px;
              }

              @include mobile {
                font-size: 1.1em;
                line-height: 25px;

                &:not(.mobile, .artist, .song-version) {
                  display: none;
                }
              }
            }
          }
        }

        .mm-song-versions-table-list {
          .direction {
            min-height: 76px;

            .drag-message {
              display: flex;
              font-family: Obibok;
              font-size: 1.75rem;
              font-weight: 100;
              justify-content: center;
              align-items: center;
              height: 76px;
              position: absolute;
              width: 100%;
            }
          }

          .song-version-item {
            color: $black;
            font-family: Obibok;
            font-weight: 100;
            left: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            top: 0;
            white-space: nowrap;
            z-index: 1;

            &:last-child {
              .over {
                padding-bottom: 2px;
              }
              .default {
                border-width: 2px;
              }
            }

            &.playing {
              background-color: #f1f1f1 !important;
            }

            .default {
              border-bottom: 2px solid #d3d3d3;
              border-left: 2px solid #d3d3d3;
              border-right: 2px solid #d3d3d3;
              display: flex;
              justify-content: space-evenly;
              height: 62px;
              left: 0;
              padding: 6px;
              top: 0;
              z-index: 1;
              @include tablet-down {
                height: 54px;
              }

              li {
                font-size: 0.9rem;
                overflow: hidden;
                white-space: nowrap;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @include tablet-down {
                  margin: 2px 0;
                  height: 50px;
                }

                @include mobile {
                  font-size: 1.3em;
                }

                &.song-version {
                  @include relative;

                  @include tablet-down {
                    line-height: 20px;
                  }

                  .song-version-cell {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @include tablet-down {
                      margin-left: 10px;
                    }

                    @include tablet-portrait-down {
                      margin-left: 5px;
                    }
                  }

                  h4 {
                    display: flex;
                    flex-direction: column;
                    font-weight: 100;
                    overflow: hidden;

                    @media screen and (max-width: 768px) {
                      width: 200px;
                    }

                    @media screen and (max-width: 1024px) {
                      width: 250px;
                    }

                    a {
                      overflow: hidden;
                      white-space: normal;
                      text-overflow: ellipsis;
                      color: $black;
                      text-decoration: none;

                      &:hover {
                        text-decoration: underline;
                        white-space: normal;
                        overflow: visible;
                      }

                      @media screen and (max-width: 768px) {
                        white-space: nowrap;
                      }

                      @media screen and (max-width: 1024px) {
                        white-space: nowrap;
                      }
                    }

                    em {
                      color: #666;
                    }
                  }
                }

                &.length {
                  @include mobile {
                    font-size: 1.7em;
                  }
                }

                &.chosen-by {
                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &:hover {
                      overflow: visible;
                      white-space: normal;
                    }
                  }
                }
              }

              .actions-right {
                display: flex;
                align-items: center;

                li {
                  // margin-right: 0;

                  a {
                    border-radius: 50%;
                  }

                  &.download-menu {
                    background: #e7e7e7;
                    box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.3);
                    display: none;
                    max-height: 100px;
                    max-width: 225px;
                    border-radius: 10px;
                    overflow: visible;
                    position: absolute;
                    right: 235px;
                    text-align: center;
                    z-index: 3;
                    align-items: center;

                    div {
                      display: flex;
                      flex-direction: column;
                    }

                    a {
                      border-radius: 0%;
                    }

                    .download-button {
                      border: 0;
                      // border-bottom-right-radius: 10px;
                      // border-bottom-left-radius: 10px;
                      cursor: pointer;
                      font-size: 0.9rem;
                      height: 50px;
                      padding: 0.5rem;
                      //padding: 0.5rem;
                      letter-spacing: 0.1rem;
                      line-height: 50px;
                      //line-height: 10px;
                      width: 100%;

                      &.disabled {
                        background-color: #666;
                        color: #e7e7e7;
                        pointer-events: none;
                        cursor: pointer;
                      }

                      &:focus {
                        outline: none;
                        box-shadow: 0px 0px 2px 3px #0066ff;
                      }
                    }

                    .download-button:first-child {
                      border-radius: 10px 10px 0px 0px;
                    }

                    .download-button:last-child {
                      border-radius: 0px 0px 10px 10px;
                    }

                    @include tablet-down {
                      margin: 0;
                      right: 118px;
                    }

                    &.expanded {
                      display: block;
                    }

                    &:focus {
                      outline: none;
                      box-shadow: 0px 0px 2px 3px #0066ff;
                    }
                  }

                  &.notes-field {
                    background: #e7e7e7;
                    border-radius: 10px;
                    box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.3);
                    display: none;
                    height: auto;
                    max-width: 300px;
                    overflow: visible;
                    position: absolute;
                    right: 187px;
                    text-align: center;
                    z-index: 3;

                    @include tablet-down {
                      margin: 0;
                      right: 118px;
                    }

                    &.expanded {
                      display: block;
                    }

                    form {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    }

                    textarea {
                      background: transparent;
                      border: none;
                      color: $black;
                      font-family: NeuzeitGro, georgia;
                      font-size: 18px;
                      line-height: 1.2em;
                      margin: 10px 0 0;
                      outline: none;
                      resize: none;
                      width: 90%;
                    }

                    .save-notes {
                      border: 0;
                      border-bottom-right-radius: 10px;
                      border-bottom-left-radius: 10px;
                      cursor: pointer;
                      font-size: 0.9rem;
                      height: 50px;
                      letter-spacing: 0.1rem;
                      line-height: 50px;
                      width: 100%;

                      &:focus {
                        outline: none;
                        box-shadow: 0px 0px 2px 3px #0066ff;
                      }

                      &.success {
                        background: $green;
                      }
                    }

                    .tri {
                      color: #e7e7e7;
                      font-size: 40px;
                      right: -14px;
                      line-height: 43px;
                      pointer-events: none;
                      position: absolute;
                      text-decoration: none;
                      top: 10px;
                      filter: drop-shadow(2px 3px 1px rgba(0, 0, 0, 0.2));

                      @include tablet-down {
                        top: 5px;
                      }
                    }
                  }

                  &.similar,
                  &.notes,
                  &.remove,
                  &.download {
                    .icon {
                      svg {
                        height: 45px;
                        width: 45px;

                        g {
                          fill: $black;

                          .icon-border {
                            display: none;
                          }
                        }
                      }
                    }

                    .button {
                      background: #e7e7e7;
                      font-size: smaller;
                      border: none;
                      padding: 5px;
                      border-radius: 5px;
                      margin: 3px;
                      @include transition(background, 100ms);
                      &:hover {
                        padding: 3px;
                        cursor: pointer;
                        background: #fff;
                        border: 2px solid #e7e7e7;
                        display: block;
                      }
                    }
                  }

                  &.winner {
                    .icon {
                      background-image: url("/app/assets/images/svg_icons/trophy.svg");
                      height: 25px;
                      width: 25px;
                    }

                    &.won {
                      .icon {
                        background-image: url("/app/assets/images/svg_icons/winner_trophy.svg");
                      }
                    }
                  }

                  &.similar,
                  &.download,
                  &.remove,
                  &.notes,
                  &.winner {
                    a {
                      background: #e7e7e7;
                      display: block;
                      height: 45px;
                      width: 45px;
                      margin: 0 5px;
                      @include transition(background, 100ms);

                      &:hover,
                      &.expanded {
                        background: #fff;

                        svg g .icon-border {
                          display: block;
                          fill: #e7e7e7;
                        }
                      }
                    }
                  }

                  &.notes a.notify {
                    background: $gold;

                    .icon svg g .icon-border {
                      fill: $gold;
                    }

                    &:hover,
                    &.expanded {
                      background: rgba(197, 139, 43, 0.5);
                    }
                  }

                  &.remove {
                    a:hover {
                      background: rgba(201, 40, 40, 0.5);

                      svg g .icon-border {
                        display: block;
                        fill: $red;
                      }
                    }
                  }

                  &.winner {
                    a {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }

                    &.won a {
                      background: $green;
                      border-color: $green;

                      &:hover {
                        background: rgba(33, 146, 142, 0.6);
                      }
                    }
                  }
                }
              }

              .action {
                &.play {
                  @include relative;
                  height: 63px;
                  top: -1px;
                  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));

                  a {
                    background: #f8f8f8;
                    border: none;
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    width: 100%;
                    @include transition(background, 150ms);

                    .play-icon {
                      z-index: 100;
                      opacity: 0;
                      transition: opacity 0.4s;

                      svg {
                        width: 40px;
                        height: 40px;

                        g {
                          fill: $green;
                        }
                      }
                    }

                    &:hover {
                      background: $black;

                      .play-icon {
                        opacity: 1;
                      }
                    }

                    &:active {
                      @include transition(background, 0ms);
                      background: $purple;
                    }

                    &:after {
                      content: "";
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      top: 0;
                      left: 0;
                      background: rgba(0, 0, 0, 0.6);
                      opacity: 0;
                      transition: all 0.3s;
                    }

                    &:hover:after {
                      opacity: 1;
                    }
                  }

                  @include tablet-down {
                    margin: 0;
                    height: 55px;
                    width: 55px;

                    a {
                      @include relative;
                      background: #f8f8f8;
                      display: block;
                      height: 100%;
                      width: 100%;

                      &:hover {
                        background: #f8f8f8;

                        .over {
                          @include opacity(0);
                        }
                      }

                      &:active {
                        background: #f8f8f8;
                      }
                    }
                  }
                }

                &.rating {
                  font-size: 1.5rem;

                  @include mobile {
                    font-size: 1.3rem;
                  }

                  .star {
                    color: $black;
                    cursor: pointer;
                    @include transition(all, 100ms);

                    &:hover {
                      color: $gold;

                      &:before {
                        color: $gold;
                        content: "\2605";
                        position: absolute;
                      }
                    }

                    &.fill-star,
                    &.backfill {
                      color: $gold;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .add-direction {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 90px;

      .error-message {
        background: $red;
        border-radius: 15px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Obibok;
        font-size: 0.9rem;
        height: 45px;
        margin-top: 5px;
        padding: 0 15px;
        width: 380px;
      }

      .add-direction-button {
        @include box-button-black;
        border-radius: 30px;
        cursor: pointer;
        letter-spacing: 0.1rem;
        line-height: 40px;
        height: 40px;
        width: 200px;
      }

      .direction-form {
        display: flex;

        input {
          border: none;
          border-bottom: 2px solid #999;
          color: $black;
          font-family: NeuzeitGro, georgia;
          font-size: 1.3rem;
          height: 42px;
          outline: none;
          padding: 0 10px;
          margin: 0 5px;
          width: unset;
        }

        .save-button,
        .cancel-button {
          @include box-button-black;
          border-radius: 25px;
          cursor: pointer;
          letter-spacing: 0.1rem;
          line-height: 44px;
          margin: 0 5px;
          outline: none;
          height: 44px;
          width: 115px;

          &[disabled] {
            background: #999;
            cursor: default;
          }

          &:focus {
            background: #000;
            box-shadow: 0px 0px 2px 3px #0066ff;
            outline: none;
          }
        }
      }
    }

    .missing-content-message {
      text-align: center;
      padding: 50px 0;
    }
  }
}
