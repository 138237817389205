/* @group Base */

.chosen-container {
  font-size: 13px;
  position: relative;
  display: inline-block;
  zoom: 1;
  *display: inline;
  &.chosen-container-active .chosen-drop {
    background: #fff;
    border: 1px solid #aaa;
    border-top: 0;
    display: block;
    position: absolute;
    top: 29px;
    left: 0;
    -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    z-index: 1010;
  }
}
.chosen-container .chosen-drop {
  display: none;
}

/* @end */

/* @group Single Chosen */
.chosen-container-single .chosen-single {
  background-color: #ffffff;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0 );
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(20%, #ffffff),
    color-stop(50%, #f6f6f6),
    color-stop(52%, #eeeeee),
    color-stop(100%, #f4f4f4)
  );
  background-image: -webkit-linear-gradient(
    top,
    #ffffff 20%,
    #f6f6f6 50%,
    #eeeeee 52%,
    #f4f4f4 100%
  );
  background-image: -moz-linear-gradient(top, #ffffff 20%, #f6f6f6 50%, #eeeeee 52%, #f4f4f4 100%);
  background-image: -o-linear-gradient(top, #ffffff 20%, #f6f6f6 50%, #eeeeee 52%, #f4f4f4 100%);
  background-image: linear-gradient(#ffffff 20%, #f6f6f6 50%, #eeeeee 52%, #f4f4f4 100%);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #aaaaaa;
  -webkit-box-shadow: 0 0 3px #ffffff inset, 0 1px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 3px #ffffff inset, 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px #ffffff inset, 0 1px 1px rgba(0, 0, 0, 0.1);
  display: block;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  height: 23px;
  line-height: 24px;
  padding: 0 0 0 8px;
  color: #444444;
  text-decoration: none;
}
.chosen-container-single .chosen-default {
  color: #999;
}
.chosen-container-single .chosen-single span {
  margin-right: 26px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.chosen-container-single .chosen-single abbr {
  display: block;
  position: absolute;
  right: 26px;
  top: 6px;
  width: 12px;
  height: 12px;
  font-size: 1px;
  background: url("/app/assets/images/chosen-sprite.png") -42px 1px no-repeat;
}
.chosen-container-single .chosen-single abbr:hover {
  background-position: -42px -10px;
}
.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
  background-position: -42px -10px;
}
.chosen-container-single .chosen-single div {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 18px;
}
.chosen-container-single .chosen-single div b {
  background: url("/app/assets/images/chosen-sprite.png") no-repeat 0px 2px;
  display: block;
  width: 100%;
  height: 100%;
}
.chosen-container-single .chosen-search {
  padding: 3px 4px;
  position: relative;
  margin: 0;
  white-space: nowrap;
  z-index: 1010;
}
.chosen-container-single .chosen-search input {
  background: #fff url("/app/assets/images/chosen-sprite.png") no-repeat 100% -20px;
  background: url("/app/assets/images/chosen-sprite.png") no-repeat 100% -20px,
    -webkit-gradient(linear, 0 0, 0 100%, color-stop(1%, #eeeeee), color-stop(15%, #ffffff));
  background: url("/app/assets/images/chosen-sprite.png") no-repeat 100% -20px,
    -webkit-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background: url("/app/assets/images/chosen-sprite.png") no-repeat 100% -20px,
    -moz-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background: url("/app/assets/images/chosen-sprite.png") no-repeat 100% -20px,
    -o-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background: url("/app/assets/images/chosen-sprite.png") no-repeat 100% -20px,
    linear-gradient(#eeeeee 1%, #ffffff 15%);
  margin: 1px 0;
  padding: 4px 20px 4px 5px;
  outline: 0;
  border: 1px solid #aaa;
  font-family: sans-serif;
  font-size: 1em;
}
.chosen-container-single .chosen-drop {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
/* @end */

.chosen-container-single-nosearch .chosen-search input {
  position: absolute;
  left: -9000px;
}

/* @group Multi Chosen */
.chosen-container-multi .chosen-choices {
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(1%, #eeeeee),
    color-stop(15%, #ffffff)
  );
  background-image: -webkit-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background-image: -moz-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background-image: -o-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background-image: linear-gradient(#eeeeee 1%, #ffffff 15%);
  border: 1px solid #aaa;
  margin: 0;
  padding: 0;
  cursor: text;
  overflow: hidden;
  height: auto !important;
  height: 1%;
  position: relative;
}
.chosen-container-multi .chosen-choices li {
  float: left;
  list-style: none;
}
.chosen-container-multi .chosen-choices .search-field {
  white-space: nowrap;
  margin: 0;
  padding: 0;
}
.chosen-container-multi .chosen-choices .search-field input {
  color: #666;
  background: transparent !important;
  border: 0 !important;
  font-family: sans-serif;
  font-size: 100%;
  height: 15px;
  padding: 5px;
  margin: 1px 0;
  outline: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.chosen-container-multi .chosen-choices .search-field .default {
  color: #999;
}
.chosen-container-multi .chosen-choices .search-choice {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: #e4e4e4;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#eeeeee', GradientType=0 );
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(20%, #f4f4f4),
    color-stop(50%, #f0f0f0),
    color-stop(52%, #e8e8e8),
    color-stop(100%, #eeeeee)
  );
  background-image: -webkit-linear-gradient(
    top,
    #f4f4f4 20%,
    #f0f0f0 50%,
    #e8e8e8 52%,
    #eeeeee 100%
  );
  background-image: -moz-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  -webkit-box-shadow: 0 0 2px #ffffff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 2px #ffffff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 2px #ffffff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  color: #333;
  border: 1px solid #aaaaaa;
  line-height: 13px;
  padding: 3px 20px 3px 5px;
  margin: 3px 0 3px 5px;
  position: relative;
  cursor: default;
}
.chosen-container-multi .chosen-choices .search-choice.search-choice-disabled {
  background-color: #e4e4e4;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#eeeeee', GradientType=0 );
  background-image: -webkit-gradient(
    linear,
    0% 0%,
    0% 100%,
    color-stop(20%, #f4f4f4),
    color-stop(50%, #f0f0f0),
    color-stop(52%, #e8e8e8),
    color-stop(100%, #eeeeee)
  );
  background-image: -webkit-linear-gradient(
    top,
    #f4f4f4 20%,
    #f0f0f0 50%,
    #e8e8e8 52%,
    #eeeeee 100%
  );
  background-image: -moz-linear-gradient(
    to top,
    #f4f4f4 20%,
    #f0f0f0 50%,
    #e8e8e8 52%,
    #eeeeee 100%
  );
  background-image: -o-linear-gradient(to top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  background-image: -ms-linear-gradient(
    to top,
    #f4f4f4 20%,
    #f0f0f0 50%,
    #e8e8e8 52%,
    #eeeeee 100%
  );
  background-image: linear-gradient(to top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  color: #666;
  border: 1px solid #cccccc;
  padding-right: 5px;
}
.chosen-container-multi .chosen-choices .search-choice-focus {
  background: #d4d4d4;
}
.chosen-container-multi .chosen-choices .search-choice .search-choice-close {
  display: block;
  position: absolute;
  right: 3px;
  top: 4px;
  width: 12px;
  height: 12px;
  font-size: 1px;
  background: url("/app/assets/images/chosen-sprite.png") -42px 1px no-repeat;
}
.chosen-container-multi .chosen-choices .search-choice .search-choice-close:hover {
  background-position: -42px -10px;
}
.chosen-container-multi .chosen-choices .search-choice-focus .search-choice-close {
  background-position: -42px -10px;
}
/* @end */

/* @group Results */
.chosen-container .chosen-results {
  margin: 0 4px 4px 0;
  max-height: 240px;
  padding: 0 0 0 4px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.chosen-container-multi .chosen-results {
  margin: -1px 0 0;
  padding: 0;
}
.chosen-container .chosen-results li {
  display: none;
  line-height: 15px;
  padding: 5px 6px;
  margin: 0;
  list-style: none;
}
.chosen-container .chosen-results .active-result {
  cursor: pointer;
  display: list-item;
}
.chosen-container .chosen-results .highlighted {
  background-color: #3875d7;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3875d7', endColorstr='#2a62bc', GradientType=0 );
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(20%, #3875d7),
    color-stop(90%, #2a62bc)
  );
  background-image: -webkit-linear-gradient(top, #3875d7 20%, #2a62bc 90%);
  background-image: -moz-linear-gradient(top, #3875d7 20%, #2a62bc 90%);
  background-image: -o-linear-gradient(top, #3875d7 20%, #2a62bc 90%);
  background-image: linear-gradient(#3875d7 20%, #2a62bc 90%);
  color: #fff;
}
.chosen-container .chosen-results a {
  color: #444444;
}
.chosen-container .chosen-results .highlighted a {
  color: #fff;
  text-decoration: none;
}
.chosen-container .chosen-results li em {
  background: #feffde;
  font-style: normal;
}
.chosen-container .chosen-results .highlighted em {
  background: transparent;
}
.chosen-container .chosen-results .no-results {
  background: #f4f4f4;
  display: list-item;
}
.chosen-container .chosen-results .create-option {
  display: list-item;
}
.chosen-container .chosen-results .group-result {
  cursor: default;
  color: #999;
  font-weight: bold;
}
.chosen-container .chosen-results .group-option {
  padding-left: 15px;
}
.chosen-container-multi .chosen-drop .result-selected {
  display: none;
}
.chosen-container .chosen-results-scroll {
  background: white;
  margin: 0 4px;
  position: absolute;
  text-align: center;
  width: 321px; /* This should by dynamic with js */
  z-index: 1;
}
.chosen-container .chosen-results-scroll span {
  display: inline-block;
  height: 17px;
  text-indent: -5000px;
  width: 9px;
}
.chosen-container .chosen-results-scroll-down {
  bottom: 0;
}
.chosen-container .chosen-results-scroll-down span {
  background: url("/app/assets/images/chosen-sprite.png") no-repeat -4px -3px;
}
.chosen-container .chosen-results-scroll-up span {
  background: url("/app/assets/images/chosen-sprite.png") no-repeat -22px -3px;
}
/* @end */

/* @group Active  */
.chosen-container-active .chosen-single {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border: 1px solid #5897fb;
}
.chosen-container-active .chosen-single-with-drop {
  border: 1px solid #aaa;
  -webkit-box-shadow: 0 1px 0 #fff inset;
  -moz-box-shadow: 0 1px 0 #fff inset;
  box-shadow: 0 1px 0 #fff inset;
  background-color: #eee;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0 );
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(20%, #eeeeee),
    color-stop(80%, #ffffff)
  );
  background-image: -webkit-linear-gradient(top, #eeeeee 20%, #ffffff 80%);
  background-image: -moz-linear-gradient(top, #eeeeee 20%, #ffffff 80%);
  background-image: -o-linear-gradient(top, #eeeeee 20%, #ffffff 80%);
  background-image: linear-gradient(#eeeeee 20%, #ffffff 80%);
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.chosen-container-active .chosen-single-with-drop div {
  background: transparent;
  border-left: none;
}
.chosen-container-active .chosen-single-with-drop div b {
  background-position: -18px 2px;
}
.chosen-container-active .chosen-choices {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border: 1px solid #5897fb;
}
.chosen-container-active .chosen-choices .search-field input {
  color: #111 !important;
}
/* @end */

/* @group Disabled Support */
.chosen-disabled {
  cursor: default;
  opacity: 0.5 !important;
}
.chosen-disabled .chosen-single {
  cursor: default;
}
.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}

/* @group Right to Left */
.chosen-rtl {
  text-align: right;
}
.chosen-rtl .chosen-single {
  padding: 0 8px 0 0;
  overflow: visible;
}
.chosen-rtl .chosen-single span {
  margin-left: 26px;
  margin-right: 0;
  direction: rtl;
}

.chosen-rtl .chosen-single div {
  left: 3px;
  right: auto;
}
.chosen-rtl .chosen-single abbr {
  left: 26px;
  right: auto;
}
.chosen-rtl .chosen-choices .search-field input {
  direction: rtl;
}
.chosen-rtl .chosen-choices li {
  float: right;
}
.chosen-rtl .chosen-choices .search-choice {
  padding: 3px 5px 3px 19px;
  margin: 3px 5px 3px 0;
}
.chosen-rtl .chosen-choices .search-choice .search-choice-close {
  left: 4px;
  right: auto;
}
.chosen-rtl.chosen-container-single .chosen-results {
  margin: 0 0 4px 4px;
  padding: 0 4px 0 0;
}
.chosen-rtl .chosen-results .group-option {
  padding-left: 0;
  padding-right: 15px;
}
.chosen-rtl.chosen-container-active .chosen-single-with-drop div {
  border-right: none;
}
.chosen-rtl .chosen-search input {
  background: #fff url("/app/assets/images/chosen-sprite.png") no-repeat -30px -20px;
  background: url("/app/assets/images/chosen-sprite.png") no-repeat -30px -20px,
    -webkit-gradient(linear, 0 0, 0 100%, color-stop(1%, #eeeeee), color-stop(15%, #ffffff));
  background: url("/app/assets/images/chosen-sprite.png") no-repeat -30px -20px,
    -webkit-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background: url("/app/assets/images/chosen-sprite.png") no-repeat -30px -20px,
    -moz-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background: url("/app/assets/images/chosen-sprite.png") no-repeat -30px -20px,
    -o-linear-gradient(top, #eeeeee 1%, #ffffff 15%);
  background: url("/app/assets/images/chosen-sprite.png") no-repeat -30px -20px,
    linear-gradient(#eeeeee 1%, #ffffff 15%);
  padding: 4px 5px 4px 20px;
  direction: rtl;
}
.chosen-container-single.chosen-rtl .chosen-single div b {
  background-position: 6px 2px;
}
.chosen-container-single.chosen-rtl .chosen-single-with-drop div b {
  background-position: -12px 2px;
}
/* @end */

/* @group Retina compatibility */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-resolution: 144dpi) {
  .chosen-rtl .chosen-search input,
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input,
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: url("/app/assets/images/chosen-sprite@2x.png") !important;
    background-repeat: no-repeat !important;
    background-size: 52px 37px !important;
  }
}
/* @end */
