@import "globals";

#mm-order {
  a {
    color: $black;
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--purple);
    }
  }

  .flash-msg {
    margin: 1em 0;
    text-align: center;
  }

  #mm-order-landing {
    .cover-image {
      height: 225px;
      width: 100%;

      @include mobile {
        height: 80px;
      }
    }

    hgroup {
      @include relative;
      background: #efefed;
      min-height: 205px;
      text-align: center;
      width: 100%;
      z-index: 1;

      .contain {
        @include relative;
      }

      .icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        margin: 0 auto;
        top: 40px;
        width: 80px;
      }

      h1 {
        color: $black;
        padding-top: 50px;
      }
    }
  }

  #mm-checkout-container {
    main {
      display: flex;
      flex-direction: column;
    }

    input[type="checkbox"] {
      margin-right: 2px;
      cursor: pointer;
      height: 22px;
      width: 22px;
    }

    .order-info {
      padding-top: 20px;

      .order-table {
        display: grid;
        grid-template-columns: 2fr 1.5fr 2fr max-content;
        grid-template-rows: 50px 62px;
        grid-auto-rows: 62px;
        width: 100%;

        .order-header {
          display: flex;
          align-items: center;
          font-family: NeuzeitGro, georgia;
          font-size: 14px;
          border-bottom: 2px solid #e7e7e7;
          color: #999;

          @include small-display {
            font-size: 0.7rem;
          }
        }

        .order-cell {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e7e7e7;
          font-family: Obibok;
          font-size: 0.9rem;
          font-weight: 100;
          line-height: 1.5rem;
          overflow: hidden;

          &.song {
            padding-right: 0.5em;
          }

          @include small-display {
            font-size: 0.8rem;
          }

          .truncated-content {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          &:hover {
            .truncated-content {
              white-space: normal;
            }
          }
        }

        .order-cost {
          justify-content: flex-end;
          padding-left: 1em;
        }
      }

      ol li {
        float: left;
        height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;

        &.song-version {
          width: 25%;
        }

        &.artist {
          width: 25%;
        }

        &.license {
          width: 30%;
        }

        &.cost {
          width: 10%;
        }

        &.download {
          width: 10%;
        }
      }

      .line-items-table-head {
        @include proxima;
        border-bottom: 2px solid #e7e7e7;
        color: #999;
        margin-bottom: -1px;
        display: grid;

        ol {
          height: 54px;

          @include under-tablet {
            padding-top: 10px;
            height: 40px;
          }

          li {
            font-size: 1.4em;
            line-height: 54px;
            @include under-tablet {
              text-align: right;
              line-height: 1em;
              height: 20px;

              &.song-version {
                font-size: 1.6em;
              }

              &.license {
                font-size: 1.6em;
              }
            }
          }
        }
      }

      .line-items-table-list {
        .line-item {
          @include elena;
          border-bottom: 1px solid #e7e7e7;
          color: $black;
          font-size: 1.8em;
          height: 62px;
          line-height: 62px;

          @include under-tablet {
            padding: 20px 0;
            height: 35px;
          }

          &:last-child {
            border-color: #efefed;
            border-width: 2px;
          }

          .download {
            max-width: 62px;
            overflow: visible;

            a {
              @include relative;
              background: $black;
              display: inline-block;
              float: right;
              height: 43px;
              top: 10px;
              width: 62px;
              z-index: 2;
              @include transition(background, 100ms);

              span {
                @include center-sprite(song_version_download_over);
              }

              @include not-mobile {
                &:hover,
                &:focus {
                  @include transition(background, 100ms);
                  background: #262220;
                }
              }

              &:active {
                @include transition(background, 0ms);
                background: var(--purple);
              }
            }
          }
        }
      }

      .totals {
        display: flex;
        flex-direction: column;
        font-family: Obibok;
        font-size: 0.9rem;
        font-weight: 100;
        margin-left: auto;
        max-width: 458px;
        padding-bottom: 20px;

        .price-row {
          color: $black;
          line-height: 30px;
          overflow: hidden;
          display: flex;
          justify-content: space-between;
        }

        .promo-code {
          color: $green;
          font-weight: 400;
        }

        .total {
          font-weight: 700;
        }

        @include small-display {
          max-width: none;
          margin-left: 0;

          .price-row {
            font-size: 0.8rem;
          }
        }
      }

      @include small-display {
        padding: 2em 8em 2em 2em;
      }

      @include under-tablet {
        padding: 0;
      }
    }

    .promo-code-fields {
      form {
        display: flex;
        justify-content: flex-end;

        @include mobile {
          flex-direction: column;
        }
      }

      .promo_code_field {
        width: 300px;
        flex: unset;
        margin-right: 1em;

        @include tablet-down {
          width: 100%;
        }
      }

      .form-submit {
        margin: 0;
        padding: 0;
      }
    }

    article.billing-info {
      .checkout-form {
        padding-top: 4.5rem;

        h4 {
          padding-bottom: 1.25rem;
        }
      }
    }

    .grouped-inputs {
      display: flex;
      justify-content: space-between;

      .field-row {
        flex: 1 1 0;

        &:not(:last-child) {
          margin-right: 1em;
        }
      }

      .field-row {
        &.cvc {
          flex: 0.5 1 0;
        }

        &.postalCode {
          flex: 0.75 1 0;
        }
      }
    }

    .checkbox-field-row {
      display: flex;
      align-items: center;
    }

    .field-row {
      margin-bottom: 6px;

      input {
        @include elena;
        color: $black;
        box-sizing: border-box;
        border: 2px solid #999;
        font-size: 1.6em;
        height: 46px;
        outline: none;
        padding: 0 10px;
        width: 100%;

        &:focus {
          border-color: var(--purple);
          color: #000;
        }

        &:disabled {
          background-color: #f2f2f2;
          pointer-events: none;
          cursor: not-allowed;
        }
      }

      .react-select__control--is-focused {
        border-color: var(--purple);
        box-shadow: none;

        .react-select__single-value {
          color: #000;
        }
      }

      .react-select__menu {
        border-color: var(--purple);

        .react-select__option--is-focused {
          background-color: lighten(#745bb4, 30%);
        }

        .react-select__option--is-selected {
          background-color: var(--purple);
        }
      }

      .react-select__control {
        border-radius: 0;
        border: 2px solid #999;
        font-size: 1.6em;

        .react-select__value-container,
        .react-select__value-container--has-value {
          align-content: center;
          height: 42px;
        }
      }

      .error {
        border-color: #d86e6e;
        margin-top: 0;

        .react-select__control {
          border-color: #d86e6e;
        }
      }

      .react-select__menu {
        border-radius: 0;
        border-left: 2px solid #999;
        border-right: 2px solid #999;
        border-bottom: 2px solid #999;
        font-size: 1.6em;
        margin-bottom: 0;
        margin-top: 0;

        .react-select__menu-list {
          padding-top: 0;
          padding-bottom: 0;
        }

        .react-select__option {
          border-bottom: 2px solid #eee;
          min-height: 41px;
          color: $black;

          &:hover,
          &:focus {
            background: var(--purple);
            color: #fff;
          }
        }
      }
    }

    .column-wrapper {
      display: flex;
      justify-content: space-between;

      @include small-display {
        flex-direction: column;
      }

      .column {
        flex: 1;

        &:not(:last-child) {
          margin: 1.5em 1.5em 0 0;

          @include small-display {
            margin-right: 0;
          }
        }

        &:last-child {
          background: #e8e8e8;
          padding: 15px;
          margin-top: 28px;
          height: min-content;

          ul {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin: 0 auto;
            height: 46px;
            width: 150px;
          }
        }
      }

      label {
        @include lead(2, 1.2);
        @include proxima;
        color: #333;
        font-size: 1.4em;
      }
    }

    .selected-licenses,
    .master-license-agreement {
      background: #f4f4f4;

      .contain {
        @include proxima;
        background: #fff;
        color: $black;
        padding: 50px;
        overflow-y: auto;
        max-height: 360px;
        width: inherit;

        @include mobile {
          padding: 10px;
        }
      }
    }

    .selected-licenses {
      margin: 80px 0 40px;
      padding: 30px;

      h6 {
        text-align: center;
        margin-bottom: 0.5em;
      }

      p {
        color: #666;
        font-size: 1.6em;
        text-align: center;

        @include small-display {
          font-size: 1.4em;
          padding: 10px;
        }
      }

      dl {
        font-size: 1.5em;
        margin: 0;
        padding: 20px;

        dt,
        dd {
          margin: 0;
        }

        dt {
          font-weight: 600;
          margin-top: 20px;
          font-size: 1.1em;
          padding-bottom: 5px;
        }
      }
    }

    .master-license-agreement {
      height: 0;
      overflow: hidden;
      transition-property: height;
      transition-duration: 0.5s;
      transition-timing-function: ease;

      &.show {
        height: 520px;
        margin-bottom: 40px;
        padding: 30px 30px 0 30px;
      }

      h6,
      h2,
      address {
        text-align: center;
      }

      h2 {
        font-size: 2.3em;
        margin: 2em 0 0.5em;
      }

      p,
      address {
        margin-top: 20px;
      }

      address {
        font-size: 16px;
        font-style: normal;
        color: #666;
      }
    }

    .form-submit {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      margin: 5em 0;

      .agree-to-terms {
        display: flex;
        flex-direction: column;

        .error-msg {
          margin: 0 0 0 30px;
        }

        .checkbox-field-row {
          label {
            font-family: NeuzeitGro, georgia;
            font-size: 1rem;
          }
        }
      }

      .box-button-black {
        border: none;
        cursor: pointer;
        font-family: Obibok;
        font-size: 0.75rem;
        letter-spacing: 0.1rem;
        width: 300px;

        .spinner {
          position: relative;
          margin-left: 1.5em;

          &:before {
            content: "";
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -10px;
            border-radius: 50%;
            border: 2px solid #ccc;
            border-top-color: #333;
            animation: spinner 0.6s linear infinite;
          }
        }

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
          cursor: not-allowed;
        }

        @keyframes spinner {
          to {
            transform: rotate(360deg);
          }
        }
      }

      @include under-tablet {
        flex-direction: column;

        .agree-to-terms {
          margin-bottom: 2em;

          .error-msg {
            margin: 0.25em auto 0;
          }

          .checkbox-field-row label {
            font-size: 1.4em;
          }
        }
      }

      @include mobile {
        .box-button-black {
          width: 100%;
        }
      }
    }

    @include small-display {
      padding: 2em;
    }
  }
}
