.announcementBanner {
  --desktop-height: 70px;
  --mobile-height: 50px;

  display: flex;
  justify-content: center;
  height: var(--desktop-height);
  border-bottom: 2px solid black;

  img {
    max-height: var(--desktop-height);
  }

  &.coral {
    background-color: var(--coral);
  }

  &.gold {
    background-color: var(--gold);
  }

  &.green {
    background-color: var(--green);
  }

  &.purple {
    background-color: var(--purple);
  }

  &.white {
    background-color: var(--white);
  }

  &.black {
    background-color: var(--white);
  }

  &.track-red {
    background-color: var(--track-red);
  }

  &.track-yellow {
    background-color: var(--track-yellow);
  }

  &.track-blue {
    background-color: var(--track-blue);
  }

  &.track-pink {
    background-color: var(--track-pink);
  }

  &.track-orange {
    background-color: var(--track-orange);
  }

  &.track-ocean-blue {
    background-color: var(--track-ocean-blue);
  }

  &.track-sunflower {
    background-color: var(--track-sunflower);
  }

  @media screen and (max-width: 767px) {
    height: var(--mobile-height);

    img {
      max-height: var(--mobile-height);
    }
  }
}
