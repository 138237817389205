@import "globals";

header[role="main"].new-header {
  display: flex !important;
  justify-content: center;
  height: 113px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  @mixin touch-header {
    height: 80px;

    nav[role="main"] {
      justify-content: space-between;
      margin: 0 1.25rem;

      .logo svg {
        height: 100%;
        width: 192px;
      }

      .primary-nav,
      .secondary-nav {
        display: none;
      }

      .mobile-hamburger {
        display: block !important;
      }
    }
  }

  &.announcement {
    top: 70px;

    @media screen and (max-width: 767px) {
      top: 50px;
    }
  }

  &.touch {
    @include touch-header;
  }

  @include mobile {
    @include touch-header;
  }

  .logo svg {
    height: 100%;
    width: 260px;
  }

  nav[role="main"] {
    display: flex !important;
    align-items: center;
    height: 100%;
    overflow: hidden;
    margin: 0 5rem;
    width: 100%;

    .mobile-hamburger {
      display: none;

      svg {
        height: 18px;
        width: 33px;
      }
    }
  }

  nav[role="main"] ol {
    display: flex;

    &.primary-nav {
      font-family: ObibokInversed;
      font-size: 1rem;
      margin-top: 15px;

      li {
        margin-left: 30px;

        @include content-break {
          margin-left: 15px;
        }
      }
    }

    &.secondary-nav {
      font-family: Obibok;
      font-size: 12px;
      margin-left: auto;
      margin-right: 10px;
      margin-top: 20px;

      @include tablet-down {
        display: none;
      }

      li:not(:last-of-type) {
        margin-right: 20px;

        @include content-break {
          margin-right: 10px;
        }
      }
    }

    .dashboard-link {
      display: none;
      &.available {
        display: inline-block;
      }
    }

    a {
      color: #000;
      border-bottom: 1px solid transparent;
      padding-bottom: 2px;
      text-decoration: none;

      &:hover {
        border-bottom-color: #000;
      }

      &:active,
      &.current {
        @include opacity(0.35);
      }
    }
  }
}
