@import "globals";

#mm-style-guide {
  header#mm-style-guide-landing {
    .cover-image {
      background: #f7f7f7;
      height: 7.5rem;
      width: 100%;

      @include mobile {
        height: 80px;
      }
    }

    .nav-shadow {
      background: #ebebeb;
      height: 2px;
      width: 100%;
    }

    .heading {
      background: #efefed;
      min-height: 140px;
      text-align: center;
      width: 100%;

      .contain {
        @include relative;
      }

      h1 {
        @include elena;
        @include relative;
        color: $black;
        font-size: 2em;
      }
    }
  }

  article.style-guide {
    section {
      border-bottom: 1px solid #cdcdcd;
      padding: 50px 0 90px 0;

      @include content-break {
        padding-left: 20px;
        padding-right: 20px;
      }

      &:last-of-type {
        padding-bottom: 90px;
      }

      .section-header {
        color: $black;
        margin-bottom: 20px;
        text-transform: capitalize;
      }

      .content-box {
        border: #999 solid 1px;
        display: flex;
        min-height: 300px;

        @include mobile {
          flex-direction: column;
          justify-content: center;

          .description,
          .logo,
          .swatches,
          .fonts,
          .cta-button,
          .primary-button,
          .action-button,
          .inputs {
            flex: 1;
            min-height: 200px;
          }

          .logo > span,
          .primary-button {
            margin: 10px 0;
          }
        }

        &.flip {
          .description {
            background: #fff;
            color: $black;
            text-shadow: none;
          }
        }
      }

      .description {
        background: #999;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
        width: 100%;

        p {
          font-size: 18px;
          max-width: 80%;
        }

        .description-wrapper {
          padding: 25px 0;

          h3:not(:first-of-type) {
            padding-top: 20px;
          }

          .text {
            padding-top: 10px;

            p {
              max-width: 100%;

              &.bottom-padding {
                padding-bottom: 10px;
              }
            }
          }
        }
      }

      .caption {
        color: #999;
        font-style: italic;
        margin-top: 20px;
        margin-bottom: 30px;
      }

      &.logos {
        padding-bottom: 21px;

        .logo {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 100%;

          &:first-of-type {
            background: #999;
          }

          &:hover {
            .logo-overlay {
              @include opacity(1);

              @include tablet-down {
                display: none;
              }
            }
          }

          .logo-overlay {
            @include opacity(0);
            background-color: rgba(0, 0, 0, 0.8);
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: center;
            position: absolute;
            width: 100%;
            @include transition(opacity, 150ms);

            .box-button {
              cursor: pointer;
            }
          }

          .primary-white {
            @include icons-sprite(logo);
          }

          .primary-purple {
            @include icons-sprite(logo_purple);
          }

          .seal-white {
            background: url("/app/assets/images/marmoset-seal-white.png") no-repeat;
            height: 200px;
            width: 200px;
          }

          .seal-purple {
            background: url("/app/assets/images/marmoset-seal_screen_purple.png") no-repeat;
            height: 200px;
            width: 200px;
          }
        }
      }

      &.colors {
        textarea {
          opacity: 0;
          position: fixed;
        }

        .swatches {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;

          .swatch {
            @include proxima;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            margin: 1rem;
            width: 150px;

            div {
              border-radius: 50%;
              color: #fff;
              font-size: 1.3rem;
              font-weight: 600;
              height: 125px;
              line-height: 125px;
              overflow: hidden;
              position: relative;
              text-align: center;
              width: 125px;

              &:hover {
                .copy-tab {
                  @include opacity(1);

                  @include tablet-down {
                    display: none;
                  }
                }
              }

              .copy-tab {
                @include opacity(0);
                background: rgba(0, 0, 0, 0.5);
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-bottom-left-radius: 125px;
                border-bottom-right-radius: 125px;
                bottom: 0;
                cursor: pointer;
                height: 40%;
                line-height: 45px;
                position: absolute;
                width: 100%;
              }

              &.coal {
                background: $black;
              }

              &.charcoal {
                background: #999;
              }

              &.ash {
                background: #efefed;
                color: #999;
              }

              &.purple {
                background: $purple;
              }

              &.green {
                background: $green;
              }
            }

            .label {
              color: #3a3532;
              font-weight: 600;
              margin-top: 10px;
              text-transform: uppercase;
            }
          }
        }
      }

      &.typeface {
        .fonts {
          color: $black;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;

          p {
            @include elena;
            padding: 20px 10px 20px 20px;

            &.proxima {
              @include proxima;
            }
          }
        }
      }

      &.buttons {
        .content-box {
          margin-bottom: 50px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        .cta-button,
        .primary-button {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
        }

        .cta-button {
          background: #999;

          .button {
            @include proxima;
            @include no-select;
            border: 2px solid white;
            color: white;
            display: inline-block;
            font-size: 13.2px;
            font-weight: 600;
            height: 40px;
            line-height: 40px;
            letter-spacing: 1.85px;
            padding: 0 38px;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            width: 36%;

            @include tablet-down {
              width: 45%;
            }

            &.passive {
            }

            &.hover {
              background: #fff;
              box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
              color: $purple;
            }

            &.active {
              background: $purple;
              border-color: $purple;
              box-shadow: 0 0 0 rgba(0, 0, 0, 0);
              color: #fff;
              text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
            }
          }
        }

        .primary-button {
          .button {
            @include proxima;
            @include no-select;
            color: #fff;
            box-shadow: none;
            font-size: 15px;
            font-weight: 600;
            height: 60px;
            line-height: 60px;
            letter-spacing: 1.85px;
            text-align: center;
            text-decoration: none;
            text-shadow: none;
            text-transform: uppercase;
            width: 50%;

            @include tablet-down {
              margin: 2px 0;
              width: 70%;
            }

            &.passive {
              background: #3a3532;
            }

            &.hover {
              background: #262220;
            }

            &.active {
              background: $purple;
            }

            &.disabled {
              background: #bab7b5;
            }
          }
        }

        .action-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #999;
          width: 100%;

          .button {
            @include no-select;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $black;
            font-size: 14.4px;
            height: 47px;
            margin: 0 1px;
            width: 62px;

            &.passive {
              background: #fff;
            }

            &.hover {
              background: $black;
              color: #fff;
            }

            &.active {
              background: $purple;
            }

            &.disabled {
              background: #fff;
              opacity: 0.5;
            }
          }
        }
      }

      &.inputs {
        .inputs {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          width: 100%;

          .input-wrapper {
            width: 350px;

            @include mobile {
              width: 300px;

              .input {
                width: 276px !important;
              }
            }

            .label {
              @include proxima;
              @include no-select;
              color: #333;
              display: block;
              font-size: 12px;
              line-height: 20px;
              padding-top: 12px;
            }

            .input {
              @include no-select;
              border: 2px solid #999;
              font-size: 16px;
              height: 42px;
              line-height: 42px;
              padding: 0 10px;
              width: 326px;

              &.select {
                display: flex;
                padding: 0;

                .selected-option {
                  padding-left: 10px;
                  width: 270px;
                }

                .list-toggle {
                  border-left: 1px solid #999;
                  height: 100%;
                  width: 41px;

                  span {
                    @include center-sprite(select_arrow);
                    position: relative;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#mm-style-guide-container {
  min-height: 1000px;
}
