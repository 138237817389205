.serverRendered {
  .hr {
    margin: 0 auto;
    border-color: var(--gold);
    border-bottom-style: solid;
    border-bottom-width: 2px;
    width: 357px;

    @media screen and (max-width: 767px) {
      width: 192px;
    }
  }
}
