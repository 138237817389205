@import "globals";

.mm-connect-left,
.mm-connect-right {
  display: block;
  float: left;
  left: 0;
  position: relative;
  text-transform: uppercase;
  top: 0;
  white-space: nowrap;

  .connect-label {
    @include kern(80);
    @include opacity(0);
    color: $black;
    font-family: Obibok;
    font-size: 0.7rem;
    font-weight: 100;
    height: 100%;
    line-height: 63px;
    position: absolute;
    top: 0;
    @include transition(opacity, 100ms);

    &.connected {
      @include opacity(1);
    }
  }
}

.mm-connect-left {
  .connect-label {
    left: 0;
  }
}

.mm-connect-right {
  .connect-label {
    right: 0;
  }
}

.mm-connect-help {
  display: inline-block;
  position: absolute;
  text-transform: uppercase;
  right: 84px;
  top: 14px;

  .connect-label {
    @include kern(80);
    @include opacity(0);
    color: $black;
    font-family: Obibok;
    font-size: 0.7rem;
    font-weight: 100;
    height: 100%;
    line-height: 63px;
    position: absolute;
    top: 0;
    @include transition(opacity, 100ms);

    &.connected {
      @include opacity(1);
    }
  }
}

.mm-connect-song-version-actions,
.mm-connect-mixtape-destroy,
.mm-connect-mixtape-actions {
  .connect-label {
    @include kern(80);
    @include opacity(0);
    color: $black;
    font-family: Obibok;
    font-size: 0.7rem;
    font-weight: 100;
    height: 100%;
    line-height: 47px;
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    white-space: nowrap;
    @include transition(opacity, 100ms);

    @include mobile {
      display: none;
    }

    &.connected {
      @include opacity(1);
    }
  }
}

.mm-connect-song-version-actions {
  height: 47px;
  left: -10px;
  position: absolute;
  text-align: right;
  text-transform: uppercase;
  top: 0;

  .connect-label {
    width: auto;
  }
}

.mm-connect-mixtape-actions {
  height: 47px;
  left: -10px;
  position: absolute;
  text-align: right;
  text-transform: uppercase;
  top: 0;
}

.mm-connect-mixtape-destroy {
  height: 47px;
  position: absolute;
  right: 70px;
  top: 2px;
  text-align: right;
  text-transform: uppercase;
  @include content-break {
    right: 90px;
  }
}
