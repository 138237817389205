.dropdown-menu {
  display: flex;
  position: relative;

  .dropdown-btn {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .dropdown-panel {
    position: absolute;
    height: auto;
    background: #fff;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    z-index: 100;
  }
}

.corner-expand {
  transform-origin: top left;
  overflow: hidden;
  will-change: transform;
  contain: content;
  animation-name: corner-expand;
  animation-duration: 100ms;
  animation-timing-function: step-end;
}

@keyframes corner-expand {
  0% {
    transform: scale(0, 0);
  }
  3.44828% {
    transform: scale(0.05723, 0.05723);
  }
  6.89655% {
    transform: scale(0.11234, 0.11234);
  }
  10.34483% {
    transform: scale(0.16586, 0.16586);
  }
  13.7931% {
    transform: scale(0.21796, 0.21796);
  }
  17.24138% {
    transform: scale(0.26871, 0.26871);
  }
  20.68966% {
    transform: scale(0.31815, 0.31815);
  }
  24.13793% {
    transform: scale(0.3663, 0.3663);
  }
  27.58621% {
    transform: scale(0.41316, 0.41316);
  }
  31.03448% {
    transform: scale(0.45871, 0.45871);
  }
  34.48276% {
    transform: scale(0.50294, 0.50294);
  }
  37.93103% {
    transform: scale(0.54582, 0.54582);
  }
  41.37931% {
    transform: scale(0.58731, 0.58731);
  }
  44.82759% {
    transform: scale(0.62736, 0.62736);
  }
  48.27586% {
    transform: scale(0.66593, 0.66593);
  }
  51.72414% {
    transform: scale(0.70296, 0.70296);
  }
  55.17241% {
    transform: scale(0.73839, 0.73839);
  }
  58.62069% {
    transform: scale(0.77213, 0.77213);
  }
  62.06897% {
    transform: scale(0.80411, 0.80411);
  }
  65.51724% {
    transform: scale(0.83423, 0.83423);
  }
  68.96552% {
    transform: scale(0.86238, 0.86238);
  }
  72.41379% {
    transform: scale(0.88844, 0.88844);
  }
  75.86207% {
    transform: scale(0.91228, 0.91228);
  }
  79.31034% {
    transform: scale(0.93373, 0.93373);
  }
  82.75862% {
    transform: scale(0.95262, 0.95262);
  }
  86.2069% {
    transform: scale(0.96873, 0.96873);
  }
  89.65517% {
    transform: scale(0.98184, 0.98184);
  }
  93.10345% {
    transform: scale(0.99165, 0.99165);
  }
  96.55172% {
    transform: scale(0.99784, 0.99784);
  }
  100% {
    transform: scale(1, 1);
  }
}
