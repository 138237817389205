@import "globals";

#mm-mixtapes {
  header#mm-mixtapes-landing {
    .cover-image {
      background: #f7f7f7;
      height: 7.5rem;
      width: 100%;

      @include mobile {
        height: 80px;
      }
    }

    .nav-shadow {
      background: #ebebeb;
      height: 2px;
      width: 100%;
    }

    .heading {
      background: #efefed;
      min-height: 140px;
      text-align: center;
      width: 100%;

      .contain {
        @include relative;
      }

      h1 {
        @include kern(1100);
        @include relative;
        letter-spacing: 0.76em;
        color: $black;
        font-size: 2em;
        margin: 0 auto;
        max-width: 1106px;
        text-indent: 34px; // account for kern to center
        padding-top: 60px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        @include mobile {
          @include kern(400);
          white-space: nowrap;
          text-indent: 15px;
          font-size: 0.8rem;
        }
      }
    }
  }

  .view-more-container {
    display: flex;
    justify-content: center;
  }

  .box-button-black {
    display: inline-block;
    margin: 2em 0 0;
    width: 100%;
    margin-bottom: 100px;
    cursor: pointer;

    @media screen and (max-width: 1200px) {
      margin: 2em 1em 3em;
    }

    @include mobile {
      margin-top: 1em;
      font-size: 1.1em;
    }

    &.available {
      display: block;
    }
  }

  .sort-filter {
    display: flex;
    justify-content: flex-end;
    margin: 2em 0;
    font-family: NeuzeitGro, georgia;
    font-size: 14px;

    .rrs:first-child {
      margin-right: 1em;
    }

    .rrs:last-child {
      margin-left: 1em;
    }

    @media screen and (max-width: 1200px) {
      margin: 3em 1.5em;
    }

    @media screen and (max-width: 615px) {
      .loader {
        display: none;
      }
    }

    @media screen and (max-width: 580px) {
      margin: 1em 1.5em;
      margin-bottom: 3em;
      flex-direction: column;
      align-items: center;

      .rrs {
        margin-top: 3em;

        &:first-child {
          margin-right: 0;
        }

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }

  .mixtapes-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #no-mixtapes {
    display: none;
    padding-top: 40px;
    padding-bottom: 100px;
    text-align: center;
    &.showing {
      display: block;
    }
  }
}

#mm-mixtapes-container {
  min-height: 1000px;
}
