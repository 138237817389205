.serverRendered {
  .cta {
    padding-bottom: 2.75rem;

    .contain {
      display: flex;
      height: 263px;
      margin: 0 5.5rem;
      width: auto;
      max-width: none;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
        height: 316px;
        margin: 0 2.5rem;
      }

      @media screen and (max-width: 767px) {
        margin: 0 1.5rem;
      }

      .hThree {
        font-family: ObibokInversed;
        font-weight: 400;
      }
    }

    .text {
      color: var(--off-white);
      display: flex;
      flex-direction: column;
      flex: 2;
      justify-content: center;
      margin-left: 5rem;

      @media screen and (max-width: 1024px) {
        margin: 0 3.75rem;

        h3 {
          margin-bottom: 1rem;
        }
      }

      @media screen and (max-width: 767px) {
        margin: 1.75rem 1.75rem 0;

        h3 {
          margin-bottom: 1rem;
          font-size: 1.75rem;
        }

        p {
          font-size: 1rem;
        }
      }

      p {
        font-size: 1.125rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.5em;
      }
    }

    div.button {
      display: flex;
      flex: 1.7;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 1024px) {
        justify-content: flex-start;
        margin: 0 3.75rem;
      }

      @media screen and (max-width: 767px) {
        margin: 0 1.75rem;
      }
    }
  }
}
