@import "globals";

#mm-checkout-login {
  margin: 8em 20px;
  font-family: Obibok;

  @include mobile {
    margin: 4em 0;
  }

  .push {
    padding-bottom: 100px;
  }

  p {
    font-family: Obibok;
    font-size: 0.9rem;
    font-weight: 100;
    line-height: 1.25rem;
    min-height: 38px;
    margin: 10px 0 40px 0;
  }

  .checkout {
    display: block;
    font-family: "Obibok";
    font-size: 0.75rem;
    letter-spacing: 0.1rem;
  }

  .box-button-black {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  .columns {
    display: flex;

    @include mobile {
      flex-direction: column;
    }

    .column {
      display: block;
      flex-basis: 0;
      -webkit-box-flex: 1;
      flex-grow: 1;
      flex-shrink: 1;
      padding: 0.75rem;
    }

    .half {
      flex: none;
      -webkit-box-flex: 0;
      width: 50%;
      max-width: 400px;

      @include mobile {
        width: 80%;
      }
    }

    button {
      @include button-states;
    }
  }
}
