@import "globals";

@include mobile {
  .profiler-results {
    display: none;
  }
}

// RESETS
html,
body,
p,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
table,
th,
tr,
td,
figure,
figcaption,
input,
textarea,
select,
option,
button,
fieldset {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
}
body {
  font-size: 62.5%;
  height: 100%;
  min-height: 100%;
}
ul,
ol {
  list-style: none;
}
fieldset,
img,
table,
th,
tr,
td {
  border: none;
  background: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

::-moz-selection {
  background: #745bb4;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #745bb4;
  color: #fff;
  text-shadow: none;
}

/*!
 * Begin Marmoset styles
 */

body {
  font-family: Obibok;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;

  &[data-device="mobile"],
  &.touch {
    #mm-drawer .mm-drawer-shelf {
      height: 100%;
    }
  }

  &.inside-dragging {
    @include grabbing;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: moz-none;
    -ms-user-select: none;
    user-select: none;
  }
}

// easy toggling of desktop/mobile specific elements
.desktop {
  display: block !important;
}
.mobile {
  display: none !important;
}

@include mobile {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
}

body.touch {
  [data-no-touch] {
    display: none !important;
  }
  [data-touch] {
    display: block !important;
  }
  height: auto;
  overflow: auto;
}

[data-prevent-default] {
  cursor: pointer;
}

h1 {
  font-size: 4.6em;
  line-height: 1em;
}

h2 {
  font-size: 3.6em;
  line-height: 1em;
}

h3 {
  font-size: 2.4em;
  line-height: 1em;
}

p {
  font-size: 1.6em;
}

abbr[title="required"] {
  border-bottom: none;
  text-decoration: none;
}

.contain {
  height: 100%;
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;
}

body.touch #container {
  height: 100%;
}

#container {
  @include relative;
  background: #fff;
  height: 100%;
  @include calc(height, "100% - 55px");
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%;
  z-index: 2;
  -webkit-overflow-scrolling: touch;
  @include transition(margin-left, 500ms, $easeOutExpo, 300ms);

  @include mobile {
    height: 100%;
  }

  &[data-section="mixtape"].staff-pick {
    @include touch {
      .logo,
      .mobile-hamburger {
        svg g {
          fill: #fff;
        }
      }
    }
  }

  &[data-section="userAccount"] {
    #content {
      .cover-image {
        background: url("/app/assets/images/headers/brand-update-header.jpg") center center / cover
          no-repeat;
        height: 250px;
        width: 100%;
        @include mobile {
          height: 80px;
        }
      }
    }
  }

  &[data-section="not_found_full_page"] {
    height: 100%;
  }

  &[data-section="account"],
  &[data-section="artist"],
  &[data-section="customRequest"],
  &[data-section="devise"],
  &[data-section="favorites"],
  &[data-section="fervor-vinyl"],
  &[data-section="historyDetail"],
  &[data-section="history"],
  &[data-section="home"],
  &[data-section="prismicDevTestHome"],
  &[data-section="legal"],
  &[data-section="licenseAgreement"],
  &[data-section="mixtape"]:not(.staff-pick),
  &[data-section="not_found"],
  &[data-section="not_found_full_page"],
  &[data-section="order"],
  &[data-section="projectSearch"],
  &[data-section="promos"],
  &[data-section="receipt"],
  &[data-section="songVersion"],
  &[data-section="songVersions"],
  &[data-section="songVersionsBeta"],
  &[data-section="song"],
  &[data-section="submissions"],
  &[data-section="userAccount"],
  &[data-section="youTubeTracks"] {
    header[role="main"].new-header {
      .logo,
      .mobile-hamburger {
        svg g {
          fill: #fff;
        }
      }

      ol a {
        color: #fff;

        &:hover {
          border-bottom-color: #fff;
        }
      }
    }
  }
}

#content {
  width: 100%;
}

.inline-video {
  left: 50%;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.mm-v-align {
  display: table;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  .centered {
    display: table-cell;
    vertical-align: middle;
  }
}

// class for measuring scroll bar width
.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}
