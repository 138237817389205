@import "globals";

#message-center {
  @include opacity(0);
  display: block;
  height: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 100;
  @include transition(opacity, 500ms, $easeOutExpo);

  &.showing {
    @include opacity(1);
    height: 100%;
    visibility: visible;
    position: fixed;
  }

  &.videoPlayerContainer {
    background: rgba(0, 0, 0, 0.6);
    border-width: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -2px;
    text-align: center;
    width: 101%;
    height: 101%;

    &.showing {
      height: 101%;
    }
  }

  &.hiding {
    @include opacity(0);
    height: 100%;
    visibility: visible;
    position: fixed;
  }

  .cover {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.6);
  }

  &.videoPlayerContainer .cover {
    background: transparent;
  }

  .wrap {
    @include relative;
    background: #fff;
    border: 2px solid $black;
    display: inline-block;
    margin: -325px 0 0;
    max-height: 650px;
    max-width: 745px;
    overflow: hidden;
    overflow-y: scroll;

    top: 50%;

    @include mobile {
      border: none;
      height: 100%;
      margin-top: 0;
      min-height: 100%;
      top: 0;
      width: 100%;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    }

    .close {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      z-index: 2;
      padding: 20px 20px 0 0;

      span {
        svg {
          height: 20px;
          width: 20px;

          g {
            fill: $black;
          }
        }
      }

      &.white {
        span {
          @include center-sprite(mood_remove);
          left: auto;
          right: 15px;
          margin: 0;
          top: 15px;
        }
      }

      @include mobile {
        margin-top: 0;
        top: 0;
        right: 10px;
        width: 100% !important;
      }
    }

    .target {
      height: 100%;
      overflow: hidden;
      -webkit-overflow-scrolling: touch;
      padding-bottom: 16px;
      min-width: 500px;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0.2);
      }

      @include mobile {
        overflow: visible;
        height: auto;
        padding: 0;
        min-width: 0;
      }
    }
  }

  &.videoPlayerContainer .wrap {
    background: transparent;
    border-width: 0px;
    left: auto;
    top: auto;
    margin-top: 0;
    width: 72%;
    max-width: none;
    max-height: none;
    overflow: visible;

    & > .target {
      overflow: visible;
    }

    & > a.close {
      display: none;
    }

    @include mobile {
      height: auto;
      min-height: initial;
    }
  }

  &[data-id="mm-message-newsletter"] .wrap {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .mm-message-newsletter,
  .mm-message-lyrics,
  .mm-message-past-licenses,
  .mm-message-pro-information,
  .mm-message-mixtape-download {
    color: $black;
    padding: 10px 50px 50px 50px;

    h2 {
      @include elena;
      @include kern(2);
      font-size: 4.2em;
      text-align: center;
      padding-bottom: 30px;
    }

    p {
      @include lead(2.1, 1.8);
      font-family: NeuzeitGro, georgia;
      font-size: 18px;
      padding: 10px 0;
    }

    a {
      color: $purple;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .mm-message-mixtape-download {
    p,
    a {
      display: block;
      text-align: center;
    }
  }

  .mm-message-pro-information,
  .mm-message-past-licenses {
    h3 {
      font-size: 2em;
      margin-bottom: 1rem;
    }

    .license-headers,
    .pro-information,
    .license-information {
      display: flex;
      justify-content: space-between;
      li.label {
        font-weight: 600;
      }
    }

    .license-headers {
      text-decoration: underline;
    }

    .pro-information:nth-child(even),
    .license-information:nth-child(even) {
      background: #dac8ef;
    }

    .pro-information:nth-child(odd),
    .license-information:nth-child(odd) {
      background: #fff;
    }

    ul {
      line-height: 1.5rem;
      font-size: 15px;
      font-family: NeuzeitGro, georgia;
    }
  }

  .mm-message-newsletter {
    padding-bottom: 20px;

    .newsletter-header {
      background: image-url("/app/assets/images/headers/newsletter.jpg") no-repeat center center;
      background-size: cover;
      height: 124px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      h1 {
        @include relative;
        @include icons-sprite(logo);
        margin: 0 auto;
        text-indent: -9999px;
        top: 29px;
      }

      .icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        left: 50%;
        margin-left: -40px;
        top: 60px;
        width: 80px;

        span {
          @include center-sprite(newsletter_badge);
        }
      }
    }

    > p,
    h2 {
      text-align: center;
    }

    h2 {
      padding-top: 125px;
      padding-bottom: 12px;
    }

    > p {
      margin: 0 auto;
      width: 80%;
    }

    #newsletter-errors {
      @include lead(2.8, 1.8);
      color: $purple;
      font-size: 1.8em;
      text-align: center;
      p {
        padding: 0;
      }
    }

    form {
      margin: 0 auto;
      max-width: 348px;

      &.processing {
        label {
          @include opacity(0.5);
        }
      }

      label {
        display: block;
        padding-top: 15px;

        @include mobile {
          width: 100%;
        }

        &#email_label {
          clear: both;
        }

        p {
          @include proxima;
          color: $black;
          font-size: 1.2em;
        }
      }

      input {
        @include elena;
        border: 2px solid #999;
        color: $black;
        font-size: 1.6em;
        height: 42px;
        outline: none;
        padding: 0 10px;
        width: 324px;

        @include mobile {
          width: 90%;
          padding: 0 4%;
        }
      }

      .spin-target {
        @include relative;
        clear: both;
        margin: 40px auto 0;
        width: 100%;
        max-width: 300px;

        @include mobile {
          margin-bottom: 60px;
        }

        button {
          @include button-states;
          border: none;
          cursor: pointer;
          font-weight: 700;
          width: 100%;

          &[disabled="disabled"] {
            @include opacity(0.2);
            cursor: default !important;
          }
        }
      }
    }
  }
}
