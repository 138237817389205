@import "globals";

#mm-versions {
  header#mm-versions-landing {
    .cover-image {
      height: 250px;
      width: 100%;
      @include mobile {
        height: 80px;
      }
    }

    hgroup {
      @include relative;
      background: #efefed;
      height: 222px;
      text-align: center;
      width: 100%;
      z-index: 2;
      @include mobile {
        background: #fff;
        height: auto;
        padding-bottom: 20px;
      }

      .contain {
        @include relative;
      }

      .versions-icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        margin: 0 auto;
        top: 40px;
        width: 80px;
        @include mobile {
          background: #f8f8f8;
          top: 20px;
        }
      }

      h1 {
        color: $black;
        padding-top: 50px;
        @include mobile {
          font-size: 1.25rem;
          line-height: 1.5rem;
          padding-top: 0;
          padding-bottom: 6px;
        }
      }

      .artist-link {
        font-family: Obibok;
        font-size: 0.8rem;
        @include mobile {
          font-size: 0.6rem;
        }

        em,
        a {
          @include lead(2.8, 1.6);
          @include mobile {
            @include lead(1.4, 1.1);
          }
        }

        em {
          color: #666;
        }

        a {
          color: $purple;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .badges {
        position: absolute;
        right: 0;
        top: 72px;
        @include mobile {
          @include relative;
          margin-top: 38px;
          padding-bottom: 6px;
          text-align: center;
        }
        @include tablet {
          right: 5px;
        }

        ul {
          @include mobile {
            display: inline-block;
            overflow: hidden;
            width: auto;
          }

          li {
            display: flex;
            flex-direction: column;
            background: #fff;
            border-radius: 50%;
            height: 52px;
            width: 52px;
            @include mobile {
              background: none;
              border-radius: 0;
              flex-direction: row;
              height: 15px;
              margin: 0 8px;
              overflow: hidden;
              width: auto;
            }

            .staff-pick-icon {
              padding-bottom: 5px;

              svg {
                height: 53px;
                width: 53px;

                @include mobile {
                  height: 13px;
                  width: 13px;

                  .icon-border {
                    display: block !important;
                  }
                }

                g {
                  fill: $green;

                  .icon-border {
                    display: none;
                  }
                }
              }
            }

            .label {
              color: #b4b4b2;
              display: block;
              font-family: Obibok;
              font-size: 0.6rem;
              line-height: 10px;
              text-align: center;
              text-transform: uppercase;
              width: 52px;
              @include mobile {
                line-height: 13px;
                margin-left: 5px;
                text-align: left;
                width: auto;
              }
            }
          }
        }
      }
    }
  }

  .versions-bar {
    background: #f8f8f8;
    color: $black;
    font-family: Obibok;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 25px 0;
    text-transform: uppercase;
    text-align: center;
    @include mobile {
      background: #fff;
      font-size: 0.7rem;
      padding: 0;
      width: 100%;
    }

    .catalog {
      padding-top: 5px;
    }
  }

  .versions-song-version-list {
    background-color: #fff;
    padding-bottom: 80px;
    @include mobile {
      padding: 25px 0 25px 0;
    }

    .mm-song-versions-table {
      position: relative;
      .song-version-badges {
        display: none;
      }
    }
  }
}
