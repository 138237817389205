@import "globals";

#mm-legal {
  > *:last-child {
    padding-bottom: 120px;
  }

  #mm-legal-landing {
    .cover-image {
      height: 250px;
      width: 100%;

      @include mobile {
        height: 80px;
      }
    }

    hgroup {
      @include relative;
      background: #efefed;
      min-height: 206px;
      z-index: 1;

      .contain {
        @include relative;
      }

      .legal-icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        margin: 0 auto;
        top: 40px;
        width: 80px;
      }

      h1 {
        padding: 50px 0 25px;
        text-align: center;
      }
    }
  }
  nav.mm-legal-nav {
    background: #f8f8f8;
    min-height: 70px;
    text-align: center;

    ol {
      display: inline-block;
      overflow: hidden;

      li {
        float: left;

        @include mobile {
          width: 50%;
        }

        @include tablet {
          width: 33%;
        }

        a {
          @include kern(80);
          font-family: Obibok;
          font-size: 0.75rem;
          color: $purple;
          display: block;
          padding: 27px 25px;
          text-decoration: none;
          text-transform: uppercase;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  article {
    background-color: white;
    overflow: hidden;
    margin: 0 auto;
    padding: 60px 0 0;
    max-width: 1140px;
    width: 100%;

    & > * {
      padding: 0 20px;
    }

    h2 {
      font-family: Obibok;
      font-weight: 100;
      font-size: 1.5rem;
      border-bottom: 2px solid #f8f8f8;
      color: $black;
      padding-bottom: 14px;
    }

    h3 {
      font-family: Obibok;
      font-weight: 100;
      font-size: 1.25rem;
      padding-bottom: 10px;
    }

    ol {
      list-style-type: decimal;
      padding-left: 20px;
    }

    ul {
      list-style: none;
      // padding-left:20px;
      padding-left: 40px;

      li {
        @include relative;
        display: list-item;
        padding: 5px 0;

        > span {
          color: $green;
          font-size: 10px;
          left: -24px;
          position: absolute;
          top: 6px;
        }
      }
    }

    p,
    section li {
      @include lead(2.1, 1.8);
      font-family: NeuzeitGro, georgia;
      color: $black;
      font-size: 1.8em;

      a {
        color: $purple;
      }
    }

    p {
      padding-bottom: 10px;
    }

    section {
      padding-top: 20px;
    }
  }
}
