@import "globals";
.mm-song-versions-table {
  position: relative;
  @include content-break {
    padding-left: 20px;
    padding-right: 20px;
    @include calc(width, "100% - 40px");
  }
  @include mobile {
    margin: 0 5px;
    padding: 0;
    width: auto;
  }
  &.hide {
    display: none;
  }
  .mm-hover-connect.tooltip {
    cursor: default;
  }
  .versions-and-licenses {
    display: flex;
    align-items: center;
    @include tablet {
      margin-right: 9px;
    }
    @include mobile {
      display: none;
    }
    .new-song-pill-wrapper {
      margin-left: 5px;
      pointer-events: none;
      mark.new {
        background-color: $green;
        .pill {
          color: #fff;
        }
      }
    }
  }
  .past-licenses,
  .stems {
    &.hidden {
      display: none;
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  mark {
    background: #fff;
    border: 2px solid #efefed;
    border-radius: 50%;
    display: block;
    @include mobile {
      border: none;
      border-radius: 0;
    }
    &.versions,
    &.licenses,
    &.new,
    &.stems {
      font-family: NeuzeitGro, georgia;
      font-size: 16px;
      border-radius: 100px;
      color: $green;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
      text-decoration: none;
      width: 45px;
      @include tablet {
        text-align: center;
        text-indent: 0;
      }
      @include mobile {
        border: 2px solid #efefed;
        margin-top: 11px;
        margin-right: 5px;
        text-align: center;
        text-indent: 0;
      }
      &.versions {
        .pill:before {
          content: "+ ";
        }
      }
      &:not(.stems):hover {
        background: $green;
        border: 0px;
        color: #fff;
        cursor: pointer;
        padding: 2px;
      }
    }
    &.licenses,
    &.stems {
      width: 25px;
      margin-left: 7px;
    }
  }
  /*
  ***  TABLE HEAD AND LIST STYLING
   */
  ol li {
    float: left;
    height: 100%;
    width: 100%;
    margin-right: 1%;
    &.song-version {
      @include calc(width, "47.5% - 124px");
      @include transition(width, 500ms);
      @include tablet {
        @include calc(width, "72% - 124px");
      }
      @include mobile {
        @include calc(width, "88% - 124px");
        padding-left: 0;
      }
      h4 {
        a,
        span {
          font-family: Obibok;
          font-size: 0.9rem;
          font-weight: 100;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          color: $black;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
            overflow: visible;
          }
        }
        em {
          color: #666;
        }
      }
    }
    &.genres {
      display: flex !important;
      width: 16.5%;
      @include transition(width, 500ms);
      @include mobile {
        display: none !important;
      }
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &:hover {
        overflow: visible;
        white-space: normal;
        span {
          overflow: visible;
          white-space: normal;
        }
      }
    }
    &.arc {
      overflow: visible !important;
      width: 10.5%;
      @include transition(width, 500ms);
      @include tablet-down {
        display: none !important;
      }
    }
    &.energy {
      display: flex !important;
      width: 11.5%;
      @include transition(width, 500ms);
      @include tablet-down {
        display: none !important;
      }
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &:hover {
        overflow: visible;
        white-space: normal;
        span {
          overflow: visible;
          white-space: normal;
        }
      }
    }
    &.length {
      text-align: right !important;
      width: 4%;
      @include transition(width, 500ms);
      @include tablet {
        width: 5%;
      }
      @include mobile {
        width: 9%;
      }
    }
    &.expand-actions-menu {
      text-align: right;
      width: 15px;
      @include tablet-down {
        text-align: center;
        width: 5%;
      }
    }
    &.collaborator {
      margin-right: -999999%;
      opacity: 0;
      padding-left: 0;
      width: 0;
      @include transition(all, 500ms);
    }

    &.download-sub {
      height: 0px;
      overflow: hidden;
      background-color: #dddddd;
      @include transition(height, 100ms);

      &.open {
        /* height should be [number of children] * 40px */
        height: 120px;

        overflow: unset;
        @include transition(height, 100ms);
      }
    }
  }
  /*
  ***  MIXTAPE COLLABORATORS STYLING
   */
  &.mixtape-collaborators {
    ol li {
      &.song-version {
        width: calc(39% - 124px);
        @include transition(width, 500ms);
        @include tablet {
          width: calc(62% - 124px);
        }
        @include mobile {
          @include calc(width, "73% - 124px");
          padding-left: 0;
        }
      }
      &.genres {
        width: 12.281%;
        @include transition(width, 500ms);
      }
      &.arc {
        width: 10.596%;
        @include transition(width, 500ms);
      }
      &.energy {
        width: 9.456%;
        @include transition(width, 500ms);
      }
      &.actions-menu {
        right: 280px !important;
        @include breakpoint(1140px) {
          right: 25% !important;
        }
        @include tablet-down {
          right: 28% !important;
        }
      }
      &.license {
        float: left !important;
        @include mobile {
          margin: 5px 0 0 0 !important;
        }
      }
      &.collaborator {
        float: right;
        margin-right: 1%;
        overflow: hidden;
        opacity: 1;
        text-overflow: ellipsis;
        width: 13%;
        @include transition(all, 500ms);
        @include mobile {
          width: 14%;
        }
      }
    }
    .mm-song-versions-table-head {
      ol li {
        @include tablet-down {
          margin: 0;
        }
        &.song-version {
          width: calc(46% - 124px);
          @include tablet {
            width: calc(68% - 124px);
          }
          @include mobile {
            width: calc(87% - 124px);
          }
        }
        &.length {
          @include tablet-down {
            left: 3px !important;
          }
        }
        &.collaborator {
          overflow: visible;
          @include tablet-down {
            text-indent: 10px;
          }
          @include mobile {
            display: block !important;
            margin-right: 0;
            padding-left: 0;
            text-indent: 0;
          }
        }
      }
    }
    .mm-song-versions-table-list {
      .song-version-item {
        .collaborator {
          line-height: 62px;
          @include mobile {
            display: block !important;
            font-size: 1.3em;
            left: 0px;
          }
        }
        .length {
          margin-right: 10px;
        }
      }
    }
  }
  /*
  ***  TABLE HEAD STYLING
   */
  .mm-song-versions-table-head {
    font-family: NeuzeitGro, georgia;
    font-size: 14px;
    border-bottom: 2px solid #d3d3d3;
    color: $black;
    &.hidden {
      display: none;
    }
    @include mobile {
      border-width: 1px;
      .mobile-play {
        display: none !important;
      }
    }
    ol {
      height: 54px;
      @include mobile {
        height: 25px;
      }
      li {
        line-height: 54px;
        &.song-version {
          text-indent: 80px;
          @include calc(width, "50% - 76px");
          @include tablet {
            @include calc(width, "67% - 57px");
            text-indent: 70px;
          }
          @include mobile {
            @include calc(width, "99% - 124px");
            text-indent: 70px;
          }
        }
        &.genres,
        &.length {
          text-align: left !important;
        }
        @include mobile {
          font-size: 12px;
          line-height: 25px;
          &:not(.mobile, .artist, .song-version, .collaborator) {
            display: none;
          }
        }
      }
    }
  }
  /*
  ***  TABLE LIST STYLING ONLY
   */
  .mm-song-versions-table-list {
    &.hidden {
      display: none;
    }
    .mm-song-versions-table-result-info {
      display: none;
    }
    .song-version-badges {
      display: inline-block;
      @include mobile {
        display: none;
      }
      mark {
        background: #fff;
        @include mobile {
          margin-right: 0;
        }
        &.versions {
          &:hover {
            background: $green;
          }
        }
      }
    }
    .song-item.hidden {
      display: none;
    }
    .song-version-item,
    .song-item {
      font-family: Obibok;
      font-size: 0.9rem;
      font-weight: 100;
      color: $black;
      left: 0;
      top: 0;
      z-index: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      div #mm-select-blanket-order-modal {
        white-space: normal;
        z-index: 1000;

        &.hidden {
          display: none;
        }

        div {
          font-weight: 400;
        }

        h2 {
          font-size: 2.5em;
        }

        li {
          display: list-item;
          font-size: 1.1rem;
          font-weight: 400;
        }

        button {
          font-size: 1.1em;
        }
      }

      .alt-versions {
        visibility: hidden;
        position: absolute;
        z-index: -99999;
        .versions {
          display: none;
        }
        .versions-link {
          border-bottom: 2px solid #d6d6d6;
          height: 62px;
          left: 0;
          position: relative;
          top: 0;
          @include tablet-down {
            height: 54px;
          }
          a {
            @include kern(80);
            font-family: Obibok;
            font-size: 0.75rem;
            font-weight: 400;
            color: $green;
            display: block;
            line-height: 62px;
            padding: 0 38px;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            &:hover {
              background: $green;
              color: #fff;
              cursor: pointer;
            }
          }
        }
      }
      &.expanded {
        background-color: #e7e7e7;
        .top-version,
        .alt-versions {
          .default {
            border-color: #d6d6d6;
            border-width: 2px;
            padding: 6px;
            li.actions-menu {
              background: #e9e9e9;
            }
            li.license {
              a {
                background: #e9e9e9;
                border: 2px solid #fff;
                &:hover {
                  background: #fff;
                }
              }
            }
          }
        }
        mark.versions {
          background: $green;
          border: 0px;
          color: #fff;
          padding: 2px;
          .pill:before {
            content: "- ";
          }
        }
      }
      .default {
        border-width: 2px;
        padding: 6px;
      }
      &.playing {
        background-color: #f1f1f1 !important;
      }
    }
    .song-version-item {
      &.hidden {
        .default {
          li:not(.actions-menu) {
            opacity: 0.3;
          }
        }
      }
      .default {
        border-bottom: 2px solid #d3d3d3;
        border-left: 2px solid #d3d3d3;
        border-right: 2px solid #d3d3d3;
        height: 62px;
        left: 0;
        top: 0;
        z-index: 1;
        @include tablet-down {
          height: 54px;
        }
        .tooltip-wrapper {
          position: relative;

          &.download-tooltip {
            top: 40px;
          }
        }
        li {
          font-family: Obibok;
          font-size: 0.9rem;
          font-weight: 100;
          overflow: hidden;
          white-space: nowrap;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @include tablet-down {
            margin: 2px 0;
            height: 50px;
          }
          @include mobile {
            font-size: 1.1em;
          }
          &.play {
            width: 64px;
            @include tablet-down {
              width: 55px;
            }
          }
          &.song-version {
            @include relative;
            h4 {
              max-width: calc(100% - 60px);
              @include mobile {
                line-height: 17px;
                max-width: 95%;
                padding: 0 0 0 10px;
                .song-version-artist {
                  font-size: 0.7rem;
                }
              }
            }
            .song-version-cell {
              display: flex;
              justify-content: space-between;
              @include tablet-down {
                margin-left: 10px;
              }
              @include tablet-portrait-down {
                margin-left: 0;
              }
            }
            .title-and-artist {
              display: flex;
              flex-direction: column;
            }
            .versions-and-licenses {
              display: flex;
              align-items: center;
              @include tablet {
                margin-right: 9px;
              }
              @include mobile {
                display: none;
              }
              &.new-song-pill-wrapper {
                margin-left: 5px;
                pointer-events: none;
                mark.new {
                  background-color: $green;
                  .pill {
                    color: #fff;
                  }
                }
              }
            }
          }
          &.arc span {
            display: none;
            height: 20px;
            margin: 21px auto 0;
            width: 100px;
            @include tablet-down {
              margin-top: 15px;
              margin-left: 30px;
            }
          }
          &.steady {
            .steady {
              display: block;
            }
          }
          &.ascending {
            .ascending {
              display: block;
            }
          }
          &.descending {
            .descending {
              display: block;
            }
          }
          &.middle-crescendo {
            .middle-crescendo {
              display: block;
            }
          }
          &.multiple-crescendos {
            .multiple-crescendos {
              display: block;
            }
          }
          &.frenetic {
            .frenetic {
              display: block;
            }
          }
          &.missing {
            .missing {
              display: block;
            }
          }
          &.length {
            @include mobile {
              font-size: 0.8rem;
            }
          }
          &.actions-menu {
            background: #e7e7e7;
            border-radius: 10px;
            box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.3);
            height: auto;
            max-width: 240px;
            overflow: visible;
            position: absolute;
            right: 115px;
            visibility: hidden;
            z-index: -99999;
            @include breakpoint(1140px) {
              right: 135px;
            }
            @include tablet-down {
              margin: 0;
              right: 155px;
            }
            @include mobile {
              display: none;
            }
            &.expanded {
              visibility: visible;
              z-index: 101;
            }
            .action {
              cursor: pointer;
              float: left;
              font-family: Obibok;
              font-size: 0.9rem;
              height: 40px;
              opacity: 1 !important;
              padding: 0 10px;
              width: 220px;
              @include transition(background, 150ms);
              a {
                height: 40px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                flex-direction: row;
                text-decoration: none;
              }
              &:first-of-type {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
              }
              &:last-of-type {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
              }
              &.sub {
                text-indent: 10px;
                &:first-of-type {
                  border-top-left-radius: unset;
                  border-top-right-radius: unset;
                }
                &:last-of-type {
                  border-bottom-left-radius: unset;
                  border-bottom-right-radius: unset;
                }
              }
              &:hover {
                background: rgba(255, 255, 255, 0.6);
              }
              a {
                &:hover {
                  text-decoration: none;
                }
              }
              .option-text {
                color: $black;
                display: flex;
                line-height: normal;
                width: 200px;
                height: 40px;
                justify-content: flex-start;
                align-items: center;
              }
              .icon {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                height: 100%;
                width: 100%;
                svg {
                  height: 35px;
                  width: 35px;
                  g {
                    fill: $black;
                    @include transition(fill, 100ms);
                    .icon-border {
                      display: none;
                    }
                  }

                  &.ForwardIcon {
                    transform: rotate(90deg) scaleY(0.8) scaleX(0.9);
                    path {
                      stroke-width: 0.4;
                      stroke: black;
                    }
                  }
                }
              }
              &.favorite .icon {
                .favorited {
                  &.selected {
                    svg g {
                      fill: $green;
                    }
                  }
                }
              }
              &.show-hide .icon {
                &.hidden {
                  display: none;
                }
                &.hide-icon svg g {
                  fill: $purple;
                }
              }
              &.hide {
                .hide-icon {
                  display: flex;
                }
                .unhide-icon {
                  display: none;
                }
              }
              &.unhide {
                .unhide-icon {
                  display: flex;
                }
                .hide-icon {
                  display: none;
                }
              }
              &.disabled {
                pointer-events: none;
                cursor: default;
                @include opacity(0.5);
              }
            }
            .disabled {
              opacity: 0.5 !important;
            }
            .tri {
              color: #e7e7e7;
              font-size: 40px;
              right: -14px;
              line-height: 43px;
              pointer-events: none;
              position: absolute;
              text-decoration: none;
              top: 10px;
              filter: drop-shadow(2px 3px 1px rgba(0, 0, 0, 0.2));
              @include tablet-down {
                top: 5px;
              }
            }
          }
          &.expand-actions-menu {
            @include mobile {
              display: none;
            }
            .vertical-ellipsis {
              cursor: pointer;
              font-size: 1.4em;
              font-weight: bold;
              position: relative;
              text-align: center;
              top: 2px;
              @include transition(all, 150ms);
              &:hover,
              &.expanded {
                text-shadow: 1px 1px 2px;
              }
            }
          }
          &.tooltip {
            @include opacity(0);
            background: rgba(0, 0, 0, 0.8);
            border-radius: 7px;
            height: 58px;
            margin-right: 0;
            position: absolute;
            top: 2px;
            overflow: visible;
            z-index: -1;
            @include transition(opacity, 200ms);
            @include tablet-down {
              display: none;
            }
            &.partial-exclusivity-tooltip,
            &.vintage-tooltip {
              box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.3);
              right: 250px;
              width: 240px;
              .tri {
                filter: drop-shadow(2px 3px 1px rgba(0, 0, 0, 0.2));
              }
            }
            &.double-tip {
              width: 300px;
            }
            &.partial-exclusivity-tooltip[data-connect-action="license"] {
              right: 90px;
              &.mixtape-nudge {
                right: 250px;
              }
            }
            &.show {
              @include opacity(1);
              z-index: 4;
            }
            p {
              @include kern(80);
              color: #fff;
              font-family: NeuzeitGro, georgia;
              font-size: 13px;
              font-weight: bold;
              letter-spacing: 0.05rem;
              line-height: 15px;
              padding: 0 10px;
              text-align: left;
              text-transform: uppercase;
              white-space: normal;
            }
            .tri {
              color: rgba(0, 0, 0, 0.8);
              font-size: 30px;
              right: -10.5px;
              line-height: 38px;
              pointer-events: none;
              position: absolute;
              text-decoration: none;
              top: 10px;
            }
          }
        }
        .action {
          &.play {
            @include relative;
            height: 63px;
            top: -1px;
            background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
            a {
              background: #f8f8f8;
              border: none;
              display: flex !important;
              justify-content: center;
              align-items: center;
              height: 100%;
              width: 100%;
              @include transition(background, 150ms);
              .play-icon {
                display: flex;
                z-index: 100;
                opacity: 0;
                transition: opacity 0.4s;
                svg {
                  width: 40px;
                  height: 40px;
                }
              }
              &:hover {
                background: $black;
                .play-icon {
                  opacity: 1;
                }
              }
              &:active {
                @include transition(background, 0ms);
                background: $purple;
              }
              &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.6);
                opacity: 0;
                transition: all 0.3s;
              }
              &:hover:after {
                opacity: 1;
              }
            }
            @include tablet-down {
              margin: 0;
              height: 55px;
              width: 55px;
              a {
                @include relative;
                background: #f8f8f8;
                display: block;
                height: 100%;
                width: 100%;
                &:hover {
                  background: #f8f8f8;
                  .over {
                    @include opacity(0);
                  }
                }
                &:active {
                  background: #f8f8f8;
                }
              }
            }
          }
          &.license {
            float: right;
            height: 43px;
            margin: 10px 6px 0 0;
            width: 62px;
            @include tablet-down {
              margin: 5px 5px 0 0;
            }
            a {
              background: #e7e7e7;
              border: 2px solid #e7e7e7;
              display: block;
              height: 39px;
              left: 0;
              position: relative;
              top: 0;
              width: 58px;
              @include transition(background, 100ms);
              &:hover {
                background: #fff;
                border: 2px solid #e7e7e7;
              }
            }
            .button-text {
              font-family: Obibok;
              font-size: 0.75rem;
              font-weight: 100;
              line-height: 1rem;
              text-align: center;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            &.disabled {
              @include opacity(0.5);
            }
          }
        }
      }
      &.archived {
        div[itemtype="http://schema.org/MusicRecording"]
        {
          position: relative;
        }
        .archived-message {
          background: rgba(0, 0, 0, 0.8);
          color: #fff;
          display: flex;
          flex-direction: column;
          font-family: NeuzeitGro, georgia;
          font-size: 1.5rem;
          font-style: italic;
          height: calc(100% - 2px);
          justify-content: center;
          position: absolute;
          text-align: center;
          top: 0px;
          width: 100%;
          z-index: 10;
          @include tablet {
            font-size: 1.3rem;
          }
          @include mobile {
            font-size: 1rem;
          }
          .title-artist {
            margin-left: 20px;
            padding-right: 42px;
            white-space: normal;
          }
          .remove-archived-message {
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 22px;
            width: auto;
            @include mobile {
              right: 10px;
              top: 20px;
            }
            svg {
              height: 30px;
              width: 30px;
              g {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.no-results {
  display: none;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
  p {
    font-family: Obibok;
    font-size: 1rem;
    font-weight: 100;
    line-height: 1.25rem;
  }
  &.showing {
    display: block;
  }
  @include mobile {
    padding: 60px 15px;
  }
}

li.action.blanket-deal {
  &:hover {
    #Layer_1 > path {
      fill: #fff;
    }
  }
}
