.serverRendered {
  .modalContainer {
    display: flex;
    background: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    height: 101%;
    width: 101%;
  }
}
