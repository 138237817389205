@import "globals";

#mm-song-versions {
  header#mm-song-versions-landing {
    height: 7.5rem;
    width: 100%;
    @include touch {
      height: 80px;
    }
    @include mobile {
      height: 80px;
    }
  }

  select#filter-select {
    display: none;

    @include mobile {
      @include opacity(0);
      display: block !important;
      height: 40px;
      -webkit-min-logical-height: 40px;
      left: 15px;
      position: absolute;
      top: 25px;
      width: 76% !important;
      z-index: 10;
      @include calc(width, "100% - 80px");
    }
  }

  #mm-song-versions-listing {
    background: #fff;
    display: inline-block;
    width: 100%;
    @include touch {
      @include accelerate;
    }

    .browse-featured-mixtapes {
      display: flex;
      justify-content: center;
    }

    .box-button-black {
      display: none;
      margin: 2em auto 0;
      max-width: 1140px;
      position: relative;

      @include content-break {
        margin: 0 20px;
        max-width: none;
      }
      @include mobile {
        font-size: 1.1em;
        height: 50px;
        margin: 25px 15px;
        line-height: 50px;
      }

      &.available {
        display: block;
      }

      &.disabled {
        pointer-events: none;
        cursor: default;
      }

      .spinner-target {
        @include opacity(0);
        background: rgba(248, 248, 248, 0.95);
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &.spinning {
          @include opacity(1);
        }

        .spinner {
          top: 30px !important;
          left: 50% !important;
          z-index: 1 !important;
          @include mobile {
            top: 25px !important;
          }
        }
      }
    }

    .mm-pagelist {
      margin: 2em auto 0;
      max-width: 1140px;
      position: relative;
      display: flex;
      justify-content: center;

      @include content-break {
        margin: 20px;
        max-width: none;
      }
      @include mobile {
        font-size: 1.1em;
        margin: 25px 15px;
      }

      &.hidden {
        display: none;
      }

      &.disabled {
        pointer-events: none;
        cursor: default;
      }

      ol {
        text-align: center;

        li {
          display: inline-block;
          margin: 0px 5px 0px;

          &.hidden {
            display: none;
          }

          a {
            color: black;
            font-family: NeuzeitGro, georgia;
            font-size: 0.9rem;
            display: inline-block;
            padding: 2px 7px 2px;

            @include mobile {
              font-size: 1.3rem;
            }
          }

          &.mm-page-nav {
            vertical-align: middle;
            height: 20px;

            a {
              background-size: auto 16px;
              background-position: center center;
              background-repeat: no-repeat;
              display: inline-block;
              height: 16px;
              width: 20px;
              padding: 0;

              @include mobile {
                background-size: auto 29px;
                height: 29px;
                width: 30px;
                position: relative;
                top: -3px;
              }
            }

            &.prev a {
              background-image: image-url("/app/assets/images/prev.png");
            }

            &.next a {
              background-image: image-url("/app/assets/images/next.png");
            }

            @include mobile {
              height: 29px;
            }
          }

          &.selected a {
            color: white;
            background-color: $purple;
          }

          &.hidden {
            display: none;
          }

          @include mobile {
            line-height: 25px;
          }
        }
      }
    }

    > .contain {
      height: auto;
      display: flex;
      flex-direction: column;
    }

    footer {
      .desktop {
        color: $black;
        font-family: NeuzeitGro;
        font-size: 20px;
        font-style: italic;
        margin: 50px auto 90px;
        text-align: center;
        padding: 0 100px 50px;

        a {
          color: $purple;
          text-decoration: none;
        }
      }

      ul.mobile {
        margin: 0 9px;

        li {
          @include relative;
          border: 1px solid #999;
          font-family: NeuzeitGro, georgia;
          display: inline-block;
          font-size: 1.3em;
          margin: 6px;
          padding: 12px 26px 12px 10px;

          &[data-filter="page"] {
            display: none;
          }

          span {
            color: #333;
            font-family: Obibok;
            font-size: 14px;
            font-weight: 200;
            margin-top: -10px;
            position: absolute;
            right: 10px;
            top: 50%;
          }
        }
      }

      a[data-action="clear-all"] {
        display: block;
      }
    }

    .mm-results {
      left: 0;
      position: relative;
      top: 0;
    }

    .contain > ol {
      margin-bottom: 50px;
      padding-top: 25px;
      text-align: left;
      @include mobile {
        border-bottom: 1px solid #e7e7e7;
        margin-bottom: 0;
      }
    }
  }

  #mm-search-input {
    background-color: #fff;
    margin-bottom: 10px;
    width: 100%;
    height: 60px;
    border-radius: 40px;
    @include mobile {
      @include relative;
      background: #f3f3f3;
      margin: 0;
      padding: 25px 0;
      width: auto;
    }

    .search-input-group {
      display: block;
      float: left;
      position: relative;
      width: 86%;

      @include tablet {
        width: 80%;
      }

      @include mobile {
        width: 75%;
      }

      .icon {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: -5px;
        position: absolute;

        svg {
          height: 60px;
          width: 60px;

          g {
            fill: $purple;

            .icon-border {
              display: none;
            }
          }
        }

        &:hover,
        &:focus {
          opacity: 0.7;
        }

        @include tablet-down {
          &:hover,
          &:focus {
            opacity: 1;
          }
        }

        @include mobile {
          margin-left: 0;

          svg {
            height: 40px;
            width: 40px;
          }
        }
      }

      label {
        text-indent: -9999px;
        font-size: 0.1em;
        margin: 25px 0 0 10px;
        position: absolute;
      }

      input {
        @include placeholder(#ebeaea);
        font-family: NeuzeitGro, georgia;
        font-size: 3em;
        border: none;
        height: 60px; //controls input field size WITHIN outer search div
        margin: 0 5% 0 50px;
        outline: none;
        width: inherit;
        @include tablet {
          width: 85%;
        }
        @include mobile {
          background: #fff;
          border-radius: 40px 0px 0px 40px;
          font-size: 2em;
          height: 50px;
          margin: 0;
          padding: 0 14px 0 42px;
          width: calc(100% - 55px);
        }
      }
    }

    .search-filter {
      background: #fff;
      cursor: pointer;
      width: 14%;
      display: inline-block;
      text-align: left;
      color: $black;
      font-size: 3em;
      height: 60px;
      border-radius: 0px 40px 40px 0px;

      @include tablet {
        width: 20%;
      }
      @include mobile {
        font-size: 1.7em;
        width: 25%;
        height: 50px;
      }

      .expand.icon {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 9px solid $black;
        right: 1%;
        top: 30px;
        position: absolute;

        @include small-display {
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 8px solid $black;
        }
        @include tablet {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 7px solid $black;
        }
        @include mobile {
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 6px solid $black;
          top: 48px;
        }
      }

      .target {
        font-family: NeuzeitGro, georgia;
        height: 60px;
        line-height: 65px;
        padding-left: 8%;
        white-space: nowrap;
        text-transform: capitalize;

        @include small-display {
          padding-left: 5%;
        }
        @include mobile {
          height: 50px;
          line-height: 55px;
        }
      }

      .menu {
        background: #fff;
        border-top: 1px solid #eee;
        box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
        display: none;
        font-size: 80%;
        position: absolute;
        width: inherit;
        border-radius: 10px;

        .item:first-of-type {
          padding-top: 5px;
        }

        .item:first-of-type:hover {
          border-radius: 10px 10px 0px 0px;
        }

        .item:last-of-type:hover {
          border-radius: 0px 0px 10px 10px;
        }

        .item {
          font-family: NeuzeitGro, georgia;
          padding-bottom: 5px;
          padding-left: 15%;
          padding-top: 5px;
          text-transform: capitalize;

          @include small-display {
            padding-left: 10%;
          }

          &:hover,
          &:focus {
            background: $purple;
            color: #fff;
          }

          &.selected {
            font-weight: bold;
          }
        }
      }
    }
  }

  #work-bench {
    background: #eee;
    left: 0;
    position: relative;
    top: 0;
    z-index: 2;
    @include touch {
      @include accelerate;
    }
    @include mobile {
      background: #fff;
    }

    section.search-wrap {
      form {
        margin: 0 auto;
        width: 85%;
      }
    }

    article.bench {
      height: 200px;
      padding-bottom: 40px;
      @include contain-break;
      @include mobile {
        background: #eee;
        display: block;
        height: auto;
        padding: 0;
        width: 100%;
      }

      .remove svg {
        height: 20px;
        width: 20px;

        g {
          fill: #fff;
        }
      }
    }

    .admin-key {
      padding: 0 0 15px;
      @include contain-break;

      @include mobile {
        background: #f3f3f3;
        margin: 0;
        padding: 10px 0 0 0;
        width: 100%;
      }

      li.catalog {
        display: inline-block;
        padding: 0 15px 10px 0;

        &:before {
          content: "";
          display: inline-block;
          vertical-align: middle;
        }

        @include mobile {
          display: block;
          padding: 0 10px 10px 0;
        }

        div {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .catalog-symbol {
        border-radius: 20px;
        height: 25px;
        margin-right: 5px;
        width: 40px;

        @include mobile {
          margin-left: 15px;
          width: 25px;
        }
      }

      .catalog-name {
        font-family: NeuzeitGro, georgia;
        font-size: 18px;

        @include mobile {
          font-size: 1.6em;
        }
      }
    }

    footer.desktop {
      background: #f8f8f8;
      min-height: 65px;
      @include contain-break;
      padding: 8px 20px 8px;
      display: flex !important;
      align-items: center;

      @include tablet {
        display: flex !important;
        align-items: center;
      }

      .contain {
        .sort {
          margin-left: auto;
        }
      }

      .browse-summary {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .group {
          display: flex;
          align-items: center;
        }
      }

      .label,
      .result-count {
        color: #777;
        font-family: NeuzeitGro, georgia;
        font-size: 14px;
      }

      .result-count {
        @include relative;
        width: 100px;

        .spinner-target {
          @include opacity(0);
          background: rgba(248, 248, 248, 0.95);
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 80px;

          &.spinning {
            @include opacity(1);
          }

          .spinner {
            z-index: 1 !important;
          }
        }

        .hidden {
          display: none;
        }
      }

      .searched-by {
        padding-left: 10px;
        width: 25vw;

        @include tablet {
          width: 200px;
        }

        .label {
          width: 80px;
          padding-right: 4px;
        }
      }

      .filtered-by {
        max-width: 50vw;

        @include tablet {
          position: relative;
          width: 40vw;
        }

        @include breakpoint(900px) {
          width: 56vw;
        }

        .label {
          width: 68px;
          padding-right: 4px;
        }
      }

      .searched-by,
      .filtered-by {
        display: none;
        &.active {
          display: flex;
        }
      }

      .result-count li,
      .searched-by li,
      .filtered-by li,
      .results-picker li {
        @include lead(1.6, 1.4);
        font-family: NeuzeitGro, georgia;
        font-size: 14px;
        color: #333;
        cursor: pointer;
        float: left;
        left: 0;
        position: relative;
        top: 0;

        &[data-filter="page"] {
          display: none;
        }

        &.clear-all {
          font-style: italic;
        }

        &.hidden {
          display: none;
        }

        span {
          @include opacity(0);
          background-color: #f8f8f890;
          font-size: 1.2rem;
          font-weight: bold;
          position: absolute;
          right: 10px;
          text-align: right;
          top: -2px;
          width: 20px;
          @include transition(opacity, 150ms);
        }

        &:hover,
        &:focus {
          &.clear-all {
            text-decoration: underline;
          }
          span {
            @include opacity(1);
          }
        }

        &:after {
          content: "-";
          color: #ccc;
          padding: 0 3px;
        }

        &:nth-last-child(2):after,
        &.clear-all:after {
          visibility: hidden;
        }
      }

      .results-picker {
        display: flex;
        max-width: 50vw;
        margin-top: 12px;

        &.only-child {
          margin-top: 0;
        }

        &.hidden {
          display: none;
        }

        .label {
          width: 100px;
        }

        li {
          display: inline-block;

          a.selected {
            text-decoration: underline;
            color: $purple;
          }
        }
      }

      .sort {
        float: none;
        margin-top: 0;

        @include breakpoint(900px) {
          display: none;
        }

        ol.options {
          overflow-y: unset;
        }
      }
    }

    footer.mobile {
      background: #f3f3f3;

      .count-wrapper {
        &.hidden {
          display: none;
        }
      }
    }

    section[data-toggle] {
      display: none;
      &.current {
        display: block;
        @include mobile {
          background: #f3f3f3;
        }
      }
      &.filter-wrap {
        left: 0;
        position: relative;
        top: 0;
      }
    }

    nav.tabs {
      height: 80px;
      width: 100%;
      @include mobile {
        height: 44px;
      }

      ul {
        height: 100%;
        overflow: hidden;
        width: 100%;
      }

      li {
        background: #fff;
        display: block;
        float: left;
        height: 100%;
        line-height: 80px;
        width: 50%;

        @include transition(background, 100ms);
        @include mobile {
          line-height: 44px;
        }

        a {
          @include kern(80);
          font-family: Obibok;
          font-size: 0.7rem;
          font-weight: 700;
          color: $black;
          display: block;
          max-width: 570px;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          width: 100%;

          @include mobile {
            font-size: 1.1em;
          }
        }

        &:hover,
        &:focus {
          background: #fafafa;
        }

        &.current {
          background: $green;
          a {
            color: #fff;
          }
        }
      }

      .filter {
        a {
          float: right;
        }
      }
    }

    nav.filter {
      @include relative;
      background: #eee;
      height: 40px;
      padding: 25px 0;
      text-align: center;

      a {
        text-decoration: none;
      }

      ul {
        display: inline-block;
        width: 78.9473684%;

        @include breakpoint(1000px) {
          width: 96%;
        }

        @include mobile {
          margin: 0 auto;
          width: 100%;
        }

        li {
          float: left;
          display: block;
          text-align: center;
          margin: 0 5px;

          &.mood {
            width: 9.333%;
            @include calc(width, "8.333% - 5px");
          }

          &.genres,
          &.length,
          &.energy {
            width: 10.444%;
            @include calc(width, "10.444% - 5px");
          }

          &.arc {
            width: 7.666%;
            @include calc(width, "7.666% - 5px");
          }

          &.vocals {
            width: 10.888%;
            @include calc(width, "9.888% - 5px");
          }

          &.instruments {
            width: 15.222%;
            @include calc(width, "15.222% - 5px");
          }

          &.customizable {
            width: 16.444%;
            @include calc(width, "16.444% - 5px");
          }

          &.internal {
            width: 5%;
            @include calc(width, "5% - 5px");

            a {
              height: 36px;

              .icon {
                svg g {
                  fill: $black;
                  @include transition(fill, 100ms);

                  .icon-border {
                    display: none;
                  }
                }
              }

              &:hover,
              &:focus {
                .icon {
                  svg g {
                    fill: #fff;
                    @include transition(fill, 100ms);
                  }
                }
              }
            }
          }

          @include tablet-down {
            margin: 0 4px;
          }

          @include mobile {
            margin: 0;
            margin-left: 15px;
            width: 76% !important;
            @include calc(width, "100% - 80px");
          }
        }

        a {
          @include kern(80);
          font-family: Obibok;
          font-size: 0.7rem;
          font-weight: 100;
          background: #fff;
          border: 2px solid #fff;
          border-radius: 20px;
          color: $black;
          display: block;
          line-height: 36px;
          padding: 0;
          text-align: center;
          text-transform: uppercase;
          width: 95%;
          @include calc(width, "100% - 4px");
          @include mult-trans((background 100ms linear, color 100ms linear, border 100ms linear));

          @include mobile {
            display: none;
            font-size: 0.9rem;
            text-align: center;
          }

          &.set {
            border: 2px solid $green;
          }

          &:hover,
          &.current,
          &:focus {
            background: $green;
            border: 2px solid $green;
            color: #fff;

            .icon svg g {
              fill: #fff !important;
            }
          }

          &.current {
            @include mobile {
              @include relative;
              display: block;
            }
          }
        }
      }

      .connect-label {
        @include tablet-down {
          display: none;
        }
      }
    }

    .info {
      position: absolute;
      top: 26px;
      right: 0px;
      background: #fff;
      border: 2px solid #fff;
      border-radius: 20px;
      height: 33px;
      width: 33px;

      @include tablet-down {
        right: 48px;
      }
      @include mobile {
        right: 18px;
      }

      &[data-touch] {
        display: none;
        @include tablet-down {
          display: block;
        }
      }

      .icon {
        height: 100%;
        width: 100%;

        svg g {
          @include transition(opacity, 100ms);
          fill: $black;

          @include retina {
            padding-right: 2px;
          }
        }
      }

      @include not-mobile {
        &:hover,
        &:focus {
          background: $green;
          border: 2px solid $green;

          svg g {
            fill: #fff;
          }
        }
      }

      &.active {
        background: $green;
        border: 2px solid $green;

        svg g {
          fill: #fff;
        }
      }
    }

    #mm-filter-info {
      @include opacity(0);
      background: rgba(238, 238, 238, 0.95);
      height: 330px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      visibility: hidden;
      z-index: 3;
      @include transition(opacity, 150ms);
      @include mobile {
        height: 100%;
      }

      &.showing {
        @include opacity(1);
      }

      &.visible {
        visibility: visible;
      }

      article {
        color: $black;
        display: none;
        height: 100%;
        left: 50%;
        margin-left: -225px;
        position: absolute;
        top: 90px;
        width: 450px;

        @include mobile {
          left: 0;
          margin: 0 15px;
          width: auto;
        }

        &.showing {
          display: block;
        }

        p {
          @include lead(1.9, 1.6);
          font-family: NeuzeitGro, georgia;
          font-size: 1.6em;
          padding: 3px 0;
        }
      }
    }

    form {
      height: 100%;
      left: 0;
      position: relative;
      top: 0;
      width: 100%;
      z-index: 2;

      @include mobile {
        margin: 0 15px;
        width: auto;
      }

      fieldset {
        @include opacity(0);
        display: none;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
        @include mobile {
          @include relative;
          display: none;
          padding-bottom: 25px;
        }

        li.actionable {
          @include no-select;
        }

        &.current {
          @include opacity(1);
          display: block;
          z-index: 2;
          @include mobile {
            display: block;
          }
        }

        &#arc {
          canvas {
            left: 50%;
            margin-left: -275px;
            position: absolute;
            top: 0;
            @include mobile {
              @include relative;
              margin-left: 0;
              width: 100%;
            }
          }

          ul {
            float: left;
            height: 100%;
            margin-right: 43px;
            width: 22.11%;
            @include mobile {
              margin-right: 0;
              margin-top: 25px;
              width: 50%;
              @include calc(width, "50% - 2px");
            }

            &:last-child {
              float: right;
              margin-right: 0;
            }

            li {
              @include relative;
              margin-bottom: 6px;
              height: 61px;
              width: 100%;
              @include mobile {
                height: 50px;
                margin-bottom: 4px;
              }

              .remove {
                @include opacity(0);
                cursor: pointer;
                display: block;
                position: absolute;
                right: 15px;
                top: 15px;
                z-index: 10;
                @include transition(opacity, 100ms);
                @include mobile {
                  @include transition(none);
                }
              }

              a {
                font-family: Obibok;
                font-size: 0.9rem;
                font-weight: 100;
                height: 100%;
                background: #fff;
                border: 2px solid #fff;
                color: $black;
                display: block;
                line-height: 61px;
                text-align: center;
                text-decoration: none;
                @include transition(background color border-color, 100ms);

                @include mobile {
                  height: 46px;
                  font-size: 0.75rem;
                  line-height: 46px;
                  overflow: hidden;
                  white-space: nowrap;
                  @include transition(none);
                }

                @include not-mobile {
                  &:hover,
                  &:focus {
                    background: $purple;
                    border-color: $purple;
                    color: #fff;
                    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
                  }
                }
              }

              &.selected a,
              &.selected a:hover,
              &.selected a:focus {
                background: #fff;
                border-color: $purple;
                color: $black;
                text-shadow: none;
              }

              &.selected a:hover,
              &.selected a:focus {
                background: $purple;
                color: #fff;
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
              }

              @include not-mobile {
                &.selected:hover {
                  .remove {
                    @include opacity(1);
                  }
                }
              }

              @include mobile {
                &.selected {
                  a {
                    background: $purple;
                    color: #fff;
                    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
                  }

                  .remove {
                    right: 2px;
                    top: 4px;
                    padding-right: 2px;
                    @include opacity(1);
                  }
                }
              }
            }
          }
        }

        &#length {
          p {
            font-family: NeuzeitGro, georgia;
            font-size: 16px;
            color: $black;
            margin-top: 20px;
            text-align: center;

            @include mobile {
              margin: 0 auto;
              font-size: 1.3em;
              width: 50%;
            }
          }

          .mm-filter-length {
            height: 80px;
            left: 0;
            position: relative;
            top: 25px;
            width: 100%;

            em {
              font-family: NeuzeitGro, georgia;
              font-size: 14px;
              display: block;
              font-style: italic;
              position: absolute;
              text-align: center;
              top: -20px;
              width: 80px;

              &.min {
                left: 0;
              }

              &.max {
                right: 0;
              }
            }

            ul {
              height: 80px;
              display: table;
              width: 100%;

              li {
                display: table-cell;
                text-align: center;
                vertical-align: middle;
                width: 6.25%;

                span {
                  @include fluid-square(50%);
                  background: #ccc;
                  border-radius: 50%;
                  display: block;
                  left: 25%;
                  @include transition(background, 150ms);
                }

                &.highlight span {
                  background: $purple;
                }
              }
            }

            .actionable {
              font-family: Obibok;
              font-size: 1.25rem;
              @include grab;
              background: #fff;
              border-radius: 50%;
              color: $black;
              height: 80px;
              line-height: 80px;
              position: absolute;
              text-align: center;
              top: 0;
              width: 80px;

              @include mobile {
                height: 40px;
                font-size: 0.75rem;
                line-height: 40px;
                top: 25%;
                width: 40px;
              }

              &.dragging {
                @include grabbing;
              }

              &.min {
                left: 0;
              }

              &.max {
                transform: translateX(100%);
              }
            }
          }
        }

        &#mood {
          select {
            display: none !important;

            @include touch {
              @include opacity(0);
              display: block !important;
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;
              z-index: 1;
            }

            @include mobile {
              display: block;
              left: -10000px;
              position: absolute;
              top: 0;
            }
          }

          ol {
            height: 100%;
            width: 100%;
          }

          ol > li {
            font-size: 1rem;
            background: #fff;
            color: $black;
            float: left;
            height: 100%;
            left: 0;
            position: relative;
            top: 0;
            width: 25%;

            @include mobile {
              @include relative;
              height: 0;
              padding-bottom: 35%;
              width: 50%;
            }

            &:nth-child(1) {
              background: $purple;
              ul {
                top: 0;
                height: 200px;
                @include mobile {
                  height: 100%;
                }

                a:hover,
                a:focus {
                  border-color: $purple;
                  background: $purple;
                }

                li.selected a,
                li.selected a:hover,
                li.selected a:focus {
                  background: #fff;
                  border-color: $purple;
                }
                li.selected a:hover,
                li.selected a:focus {
                  @include opacity(0.8);
                }
              }
            }

            &:nth-child(2) {
              &.set {
                background: #826bbc;
              }

              li a:hover,
              li a:focus {
                background: #826bbc;
                border-color: #826bbc;
              }

              li.selected a,
              li.selected a:hover,
              li.selected a:focus {
                background: #fff;
                border-color: #826bbc;
              }
            }

            &:nth-child(3) {
              background: #dcdbdb;
              &.set {
                background: #907cc3;
              }

              li a:hover,
              li a:focus {
                background: #907cc3;
                border-color: #907cc3;
              }

              li.selected a,
              li.selected a:hover,
              li.selected a:focus {
                background: #fff;
                border-color: #907cc3;
              }
            }

            &:nth-child(4) {
              background: #e5e5e4;
              &.set {
                background: #9e8ccb;
              }

              li a:hover,
              li a:focus {
                background: #9e8ccb;
                border-color: #9e8ccb;
              }

              li.selected a,
              li.selected a:hover,
              li.selected a:focus {
                background: #fff;
                border-color: #9e8ccb;
              }
            }

            &.available {
              background: #fff;
              cursor: pointer;

              h4 {
                visibility: visible;
              }
              .add {
                display: block;
              }
            }

            &.set {
              color: #fff;
              text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);

              &:not(:first-child) {
                cursor: pointer;

                &:hover {
                  .remove {
                    display: block;
                  }
                  .target {
                    @include opacity(0.5);
                  }
                }
              }

              h4 {
                visibility: visible;
              }
              .add {
                display: none;
              }
              .target {
                display: block;
              }
            }

            h4 {
              font-family: Obibok;
              font-size: 0.9rem;
              float: left;
              padding: 15px;
              visibility: hidden;

              @include mobile {
                font-size: 0.9rem;
              }
            }

            .target {
              font-family: Obibok;
              line-height: 1.25em;
              font-size: 2.25rem;

              @include opacity(1);
              display: none;
              @include transition(opacity, 150ms);

              @include mobile {
                font-size: 1.25rem;
                left: auto;
                margin-top: -13px;
                position: absolute;
                text-align: center;
                top: 50%;
                width: 100%;
                @include transition(none);
              }
            }

            .remove {
              cursor: pointer;
              display: none;
              float: right;
              left: 0;
              margin: 15px;
              position: relative;
              top: 0;
              z-index: 10;
              @include transition(opacity, 100ms);

              @include mobile {
                @include transition(none);
              }

              &:hover {
                @include opacity(0.85);
              }
            }

            .add {
              display: none;
              -webkit-font-smoothing: none;
              @include mobile {
                left: auto;
                margin-top: -13px;
                position: absolute;
                text-align: center;
                top: 50%;
                width: 100%;
              }
            }

            .mm-v-align {
              @include mobile {
                display: block;
                height: 100%;
              }
            }

            .centered {
              font-size: 4.6em;
              text-align: center;
              @include mobile {
                display: block;
                font-size: 2.3em;
                height: 100%;
              }
            }
          }

          ul {
            background: #eee;
            display: none;
            height: 333px;
            left: 0;
            overflow-y: scroll;
            overflow-x: hidden;
            position: absolute;
            top: -67px;
            width: 100%;
            z-index: 15;
            @include mobile {
              height: 100%;
            }

            li {
              height: 61px;
              margin-bottom: 6px;
              width: 281px;
              @include mobile {
                height: 33%;
                width: 100%;
              }

              &:last-child {
                height: 62px;
                margin-bottom: 0;
                @include mobile {
                  height: 30px;
                }
              }

              &.disabled {
                display: none;
              }

              a {
                font-family: NeuzeitGro, georgia;
                font-size: 18px;
                background: #fff;
                border: 2px solid #fff;
                color: $black;
                display: block;
                height: 100%;
                line-height: 61px;
                text-align: center;
                text-decoration: none;
                width: 100%;
                @include mobile {
                  font-size: 1.1em;
                  line-height: 30px;
                }

                &:hover,
                &:focus {
                  color: #fff;
                  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
                }
              }
            }

            li.selected a {
              color: $black;
              text-shadow: none;
            }

            &.focused {
              display: block;
            }
          }
        }

        &#energy {
          ol {
            height: 100%;
            width: 100%;
            @include mobile {
              width: auto;
            }

            li {
              @include relative;
              height: 100%;
              float: left;
              margin-right: 6px;
              width: 20%;
              @include calc(width, "20% - 6px");
              @include mobile {
                float: none;
                height: 50px;
                margin: 0;
                width: 100%;
              }

              .remove {
                @include opacity(0);
                cursor: pointer;
                display: block;
                position: absolute;
                right: 15px;
                top: 15px;
                z-index: 1;
                @include transition(opacity, 100ms);

                @include mobile {
                  @include transition(none);
                  display: none;
                }
              }

              &:last-child {
                margin-right: 0;

                @include mobile {
                  width: 100%;
                }
              }

              &.low a,
              &.low-medium a,
              &.medium a,
              &.medium-high a,
              &.high a {
                border: 2px solid #fff;
                color: $black;
                display: block;
                font-family: Obibok;
                font-size: 0.9rem;
                font-weight: 100;
                height: 100%;
                text-align: center;
                text-decoration: none;
                width: 100%;
                @include transition(background color, 100ms);

                @include mobile {
                  font-size: 0.75rem;
                  line-height: 50px;
                  text-align: left;
                  @include transition(none);

                  figcaption {
                    float: left;
                    text-indent: 15px;
                  }
                }

                @include not-mobile {
                  &:hover,
                  &:focus {
                    background: $purple;
                    border-color: $purple;
                    color: #fff;
                    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);

                    canvas {
                      transform: translateY(-10px);
                      @include transition(transform, 100ms);
                    }
                  }
                }
              }

              &.low a,
              &.selected.low a {
                background: #f1f1f1;
                border-color: #f1f1f1;
                .default {
                  @include m-center-sprite(filter_energy_low_default);
                }
                .active {
                  @include m-center-sprite(filter_energy_low_rollover);
                }
              }

              &.low-medium a,
              &.selected.low-medium a {
                background: #f5f5f5;
                border-color: #f5f5f5;
                .default {
                  @include m-center-sprite(filter_energy_lowmed_default);
                }
                .active {
                  @include m-center-sprite(filter_energy_lowmed_rollover);
                }
              }

              &.medium a,
              &.selected.medium a {
                background: #f8f8f8;
                border-color: #f8f8f8;
                .default {
                  @include m-center-sprite(filter_energy_med_default);
                }
                .active {
                  @include m-center-sprite(filter_energy_med_rollover);
                }
              }

              &.medium-high a,
              &.selected.medium-high a {
                background: #fcfcfc;
                border-color: #fcfcfc;
                .default {
                  @include m-center-sprite(filter_energy_medhigh_default);
                }
                .active {
                  @include m-center-sprite(filter_energy_medhigh_rollover);
                }
              }

              &.high a,
              &.selected.high a {
                background: #fff;
                border-color: #fff;
                .default {
                  @include m-center-sprite(filter_energy_high_default);
                }
                .active {
                  @include m-center-sprite(filter_energy_high_rollover);
                }
              }

              &.selected a {
                border-color: $purple !important;
                color: $purple;
                text-shadow: none;
                height: 92%;

                .icon .default {
                  @include opacity(1);
                }
                .icon .active {
                  @include opacity(0);
                }
              }

              &.selected a:hover,
              &.selected a:focus {
                background: $purple;
                color: #fff;
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
                .icon .default {
                  @include opacity(0);
                }
                .icon .active {
                  @include opacity(1);
                }
              }

              @include not-mobile {
                &.selected:hover {
                  .remove {
                    @include opacity(1);
                  }
                }
              }

              @include mobile {
                &.selected {
                  background: $purple;
                  border-color: $purple;
                  color: #fff;
                  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);

                  .icon .default {
                    @include opacity(0);
                  }

                  .icon .active {
                    @include opacity(1);
                  }

                  .remove {
                    @include opacity(1);
                    display: block;
                    top: 4px;
                    right: 0;
                    padding-right: 2px;

                    svg g {
                      fill: $black;
                    }
                  }
                }
              }

              canvas {
                display: block;
                left: 0;
                margin: 40px auto 10px;
                position: relative;
                top: 0;
                z-index: 2;
              }

              .icon {
                @include relative;
                height: 50px;
                display: block;
                float: right;
                left: -50px;
                width: auto;

                .default,
                .active {
                  @include transition(opacity, 100ms);
                  @include mobile {
                    @include transition(none);
                  }
                }
                .default {
                  @include opacity(1);
                }
                .active {
                  @include opacity(0);
                }
              }
            }
          }
        }

        &#vocals,
        &#internal,
        &#customizable {
          ul {
            height: 100%;
            width: 100%;
          }

          li {
            @include relative;
            display: block;
            float: left;
            height: 100%;
            margin-right: 6px;

            .remove {
              @include opacity(0);
              cursor: pointer;
              display: block;
              position: absolute;
              right: 15px;
              top: 15px;
              z-index: 10;

              @include transition(opacity, 100ms);
              @include mobile {
                @include transition(none);
              }
            }

            &.no {
              @include mobile {
                float: right;
              }
            }

            a {
              font-family: Obibok;
              font-size: 1.2rem;
              font-weight: 100;
              background: #fff;
              border: 2px solid #fff;
              color: $black;
              display: block;
              height: 100%;
              text-align: center;
              text-decoration: none;
              width: 100%;
              @include transition(background color border-color, 100ms);

              .icon {
                transform: translateY(0px);
                @include transition(transform, 100ms);
              }

              @include mobile {
                @include transition(none);
                font-size: 0.9rem;
              }

              @include not-mobile {
                &:hover,
                &:focus {
                  background: $purple;
                  border-color: $purple;
                  color: #fff;

                  .icon svg g {
                    fill: #fff;
                  }

                  .icon {
                    transform: translateY(-10px);
                  }
                }
              }

              .icon svg g {
                fill: $black;
              }
            }

            &.selected a {
              background: #fff;
              border-color: $purple;
              color: $purple;

              .icon svg g {
                fill: $purple;
              }
            }

            &.selected a:hover,
            &.selected a:focus {
              background: $purple;
              color: #fff;

              .icon svg g {
                fill: #fff;
              }
            }

            @include mobile {
              &.selected {
                a {
                  background: $purple;
                  color: #fff;

                  .remove {
                    @include opacity(1);
                    display: block;
                    padding-right: 2px;
                    top: 6px;
                    right: 0;
                  }

                  .icon svg g {
                    fill: #fff;
                  }
                }
              }
            }
          }
        }

        &#internal {
          li a {
            display: flex;
            justify-content: center;
            justify-items: center;
            flex-direction: column;
          }
        }

        &#internal,
        &#vocals {
          li {
            figure {
              display: flex;
              align-items: center;
              flex-direction: column;
              height: 100%;
              justify-content: flex-end;

              figcaption {
                padding: 5px 0 15px;

                @include mobile {
                  padding: 10px 0 35px;
                }
              }
            }
          }

          .yes,
          .female,
          .male {
            figure .icon {
              svg {
                height: 100%;
                width: 60px;
              }

              @include mobile {
                svg {
                  width: 35px;
                }
              }
            }
          }

          .no figure .icon {
            svg {
              height: 100px;
              width: 100px;
            }

            @include mobile {
              svg {
                height: 50px;
                width: 50px;
              }
            }
          }
        }

        &#internal {
          li {
            width: 20%;
            @include calc(width, "20% - 6px");
            @include mobile {
              @include fluid-square;
              @include calc(width, "20% - 6px");
              padding-bottom: 17em;
            }
          }
        }

        &#vocals {
          li {
            width: 25%;
            @include calc(width, "25% - 6px");
            @include mobile {
              @include fluid-square;
              @include calc(width, "25% - 6px");
              padding-bottom: 17em;
            }
          }
        }

        &#customizable {
          li {
            width: 50%;
            @include calc(width, "50% - 6px");
            @include mobile {
              @include fluid-square;
              @include calc(width, "50% - 6px");
              @include calc(padding-bottom, "50% - 6px");
            }

            &.no {
              margin-right: 0;
            }

            figure {
              display: flex;
              align-items: center;
              flex-direction: column;
              height: 100%;
              justify-content: flex-end;

              figcaption {
                padding: 25px 0 30px;

                @include mobile {
                  padding-top: 20px;
                }
              }
            }
          }

          .yes,
          .no {
            figure .icon {
              svg {
                height: 100%;
                width: 72px;
              }

              @include mobile {
                svg {
                  width: 50px;
                }
              }
            }
          }
        }

        &#internal,
        &#instruments,
        &#genres {
          li {
            @include relative;
            &.selected a {
              border-color: $purple;
              @include not-mobile {
                &:hover {
                  background: $purple;
                  color: #fff;
                  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
                }
              }
              @include mobile {
                background: $purple;
                color: #fff;
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
              }
            }

            &.selected-negative a {
              border-color: rgba(58, 53, 50, 0.5);
              color: rgba(58, 53, 50, 0.5);
              text-decoration: line-through;
              @include not-mobile {
                &:hover {
                  background: rgba(58, 53, 50, 0.5);
                  border-color: rgba(58, 53, 50, 0);
                  color: #fff;
                  text-decoration: line-through;
                  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
                }
              }
              @include mobile {
                background: rgba(58, 53, 50, 0.5);
                border-color: rgba(58, 53, 50, 0);
                color: #fff;
                text-decoration: line-through;
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
              }

              .default {
                filter: invert(60%);
              }
            }
          }
        }

        &#instruments {
          .instrument-mute {
            position: absolute;
            top: -64px;
            background: #fff;
            border: 2px solid #fff;
            border-radius: 20px;
            height: 33px;
            width: 33px;

            @include breakpoint(1000px) {
              display: none;
            }

            .icon {
              cursor: pointer;

              svg g {
                @include transition(opacity, 100ms);
                fill: $black;

                .icon-border {
                  display: none;
                }
              }

              .default {
                display: none;

                &.selected {
                  display: block;

                  &:hover {
                    display: block;
                  }
                }
              }
              .muted {
                display: none;

                &.selected {
                  display: block;

                  &:hover {
                    display: block;
                  }
                }
              }
            }
          }

          li {
            float: left;
            height: 50px;
            margin-right: 6px;
            margin-bottom: 6px;
            width: 25%;
            @include calc(width, "25% - 6px");
            @include mobile {
              @include calc(width, "50% - 6px");
              height: 50px;
              margin-bottom: 12px;
              margin-right: 0;
              &:nth-child(even) {
                float: right;
              }
            }

            &:nth-child(4),
            &:nth-child(8),
            &:nth-child(12),
            &:nth-child(16) {
              margin-right: 0;
            }

            a {
              font-family: Obibok;
              font-size: 0.9rem;
              font-weight: 100;
              background: #fff;
              border: 2px solid #fff;
              color: $black;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              text-align: center;
              text-decoration: none;
              width: 100%;
              @include transition(background color, 100ms);

              @include not-mobile {
                &:hover,
                &:focus {
                  background: $purple;
                  border-color: $purple;
                  color: #fff;
                  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
                }
              }

              @include mobile {
                @include transition(none);
                font-size: 1.3em;
              }
            }
          }
        }

        &#genres {
          li {
            float: left;
            height: 65px;
            margin-right: 6px;
            margin-bottom: 6px;
            width: 16.666%;
            @include calc(width, "16.666% - 6px");
            @include mobile {
              @include calc(width, "50% - 6px");
              height: 50px;
              margin-bottom: 12px;
              margin-right: 0;
              &:nth-child(even) {
                float: right;
              }
            }

            &:nth-child(6),
            &:nth-child(12),
            &:nth-child(18) {
              margin-right: 0;
            }

            a {
              font-family: Obibok;
              font-size: 0.9rem;
              font-weight: 100;
              background: #fff;
              border: 2px solid #fff;
              color: $black;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              text-align: center;
              text-decoration: none;
              width: 100%;
              @include transition(background color, 100ms);

              @include not-mobile {
                &:hover,
                &:focus {
                  background: $purple;
                  border-color: $purple;
                  color: #fff;
                  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
                }
              }

              @include mobile {
                @include transition(none);
                font-size: 1.3em;
                line-height: 50px;
              }
            }
          }

          .clear-all-genres {
            a {
              background: $purple;
              border-color: $purple;
              color: #fff;
              text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);

              @include not-mobile {
                &:hover,
                &:focus {
                  background: #fff;
                  border: 2px solid #fff;
                  color: $black;
                  text-shadow: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
