@import "globals";

#mm-favorites {
  header#mm-favorites-landing {
    .cover-image {
      height: 7.5rem;
      width: 100%;

      @include mobile {
        height: 80px;
      }
    }

    hgroup {
      @include relative;
      background: #efefed;
      min-height: 222px;
      text-align: center;
      width: 100%;
      z-index: 1;

      @include mobile {
        height: 170px;
      }

      .contain {
        @include relative;
      }

      .favorites-icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        margin: 0 auto;
        top: 40px;
        width: 80px;

        @include mobile {
          top: 20px;
        }

        svg {
          height: 80px;
          width: 80px;

          g {
            fill: $green;
          }
        }
      }

      h1 {
        color: $black;
        padding-top: 50px;

        @include mobile {
          padding-top: 30px;
        }
      }

      .info {
        @include relative;
        text-align: center;

        @include mobile {
          padding-bottom: 20px;
        }
      }
    }
  }

  .favs {
    background: #fff;
    padding-bottom: 80px;

    @include mobile {
      padding: 25px 0 25px 0;
    }

    h1 {
      padding-bottom: 15px;
      float: left;

      @include content-break {
        padding-left: 20px;
      }

      @include mobile {
        font-size: 1.8em;
        margin-left: 15px;
      }
    }

    .sort {
      margin-top: -14px;

      @include content-break {
        margin-right: 20px;
      }
    }

    .mm-song-versions-table-list {
      position: relative;
      .song-version-badges {
        display: none;
      }
    }
  }
}
