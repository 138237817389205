.serverRendered {
  .imageWrapper {
    padding-bottom: 4.75rem;

    @media screen and (max-width: 1024px) {
      padding-bottom: 3.25rem;
    }

    .imageSizedByFileContain {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 5.5rem;
    }

    .image {
      max-width: 1000px;

      @media screen and (max-width: 1024px) {
        max-width: 100%;
      }
    }
  }
}
