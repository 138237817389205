@import "globals";

#mm-youtube-tracks {
  header#mm-youtube-tracks-landing {
    height: 7.5rem;
    width: 100%;
    @include touch {
      height: 80px;
    }
    @include mobile {
      height: 80px;
    }
  }

  .search-wrap {
    background-color: #eee;

    nav.tabs {
      &.mobile {
        height: 0px;
        @include mobile {
          height: 44px;
          width: 100%;
        }
      }

      &.desktop {
        height: 50px;
        width: 100%;
        @include mobile {
          height: 0px;
        }
        li {
          line-height: 50px;
        }
      }

      ul {
        height: 100%;
        overflow: hidden;
        width: 100%;
      }

      li {
        background: #fff;
        display: block;
        float: left;
        height: 100%;
        width: 50%;

        @include transition(background, 100ms);
        @include mobile {
          line-height: 44px;
        }

        a {
          @include kern(80);
          font-family: Obibok;
          font-size: 0.7rem;
          font-weight: 700;
          color: $black;
          display: block;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          width: 100%;

          @include mobile {
            font-size: 1.1em;
          }

          span.new::before {
            content: "NEW";
            font-family: NeuzeitGro, georgia;
            font-size: 11.2px;
            background-color: #21928e;
            border-radius: 100px;
            color: #fff;
            font-weight: 600;
            line-height: 22px;
            text-align: center;
            text-decoration: none;
            width: 45px;
            padding: 4px 6px 3px 7px;
            margin-right: 7px;
            border: 2px solid #eee;
          }
        }

        &:hover,
        &:focus {
          background: #f8f8f8;
        }

        &.current {
          background: $green;
          a {
            color: #fff;
          }
        }
      }

      .filter {
        a {
          float: right;
        }
      }
    }

    h3.youtube-search-header {
      width: 100%;
      text-align: center;
      font-family: NeuzeitGro, georgia;
      font-size: 4em;
      margin-top: 20px;
      color: var(--lighter-black);
    }

    form {
      margin: 0 auto;
      margin-top: 50px;
      width: 85%;
      max-width: 1140px;
      display: flex;
      align-items: center;

      #mm-search-input {
        background-color: #fff;
        margin-bottom: 50px;
        width: 90%;
        height: 60px;
        border-radius: 40px;

        &.error {
          border: var(--red) solid 3px;
        }

        @include mobile {
          height: 40px;
        }

        .search-input-group {
          display: inline-block;
          float: left;
          position: relative;
          width: 90%;

          @include mobile {
            width: 80%;
          }

          .icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: -5px;
            position: absolute;

            svg {
              height: 60px;
              width: 60px;
              g {
                fill: #745bb4;
                .icon-border {
                  display: none;
                }
              }
            }
          }

          label {
            text-indent: -9999px;
            font-size: 0.1em;
            margin: 25px 0 0 10px;
            position: absolute;
          }

          input {
            font-family: NeuzeitGro, georgia;
            font-size: 3em;
            border: none;
            height: 60px;
            margin: 0 5% 0 50px;
            outline: none;
            width: inherit;

            @include mobile {
              font-size: 2em;
              height: 40px;
            }
          }
        }
      }
      button.youtube-submit {
        width: 100px;
        height: 50px;
        font-size: 1rem;
        margin-left: 20px;
        background-color: $purple;
        border: $purple solid 3px;
        color: white;
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        margin-bottom: 50px;
        border-radius: 50px;
        font-family: "Obibok";

        &:hover {
          background-color: white;
          color: $purple;
        }

        @include mobile {
          width: 100px;
          height: 40px;
          font-size: 1rem;
        }
      }
    }
  }

  #empty-search-wrapper {
    box-sizing: border-box;
    padding: 50px 1em 100px 1em;
    text-align: center;
    height: 100%;
    margin: 0 auto;
    width: 100%;
    .empty-search-text {
      color: #3a3532;
      padding-bottom: 15px;
    }
    p.paragraph {
      font-family: Obibok;
      font-size: 1rem;
      font-weight: 100;
      letter-spacing: 0.1rem;
      line-height: 30px;
    }
  }

  .long-process-wrapper {
    .long-process-text {
      display: none;
    }
    .long-process-message {
      text-align: center;
      position: relative;
      bottom: 30px;
      &.failed {
        margin-top: 80px;
        color: var(--red);
      }
    }
  }

  .spotify-message {
    text-align: center;
    bottom: 1rem;
    margin-top: 2rem;
    .failed {
      font-size: 1rem;
      color: var(--red);
    }
  }

  .youtube-player-wrapper {
    margin: 20px auto;
    background: #000;
    border: 4px solid #000;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    width: 600px;
    border-radius: 12px;

    @media screen and (max-width: 767px) {
      font-size: 0.75rem;
      width: 200px;
    }
  }
  .youtube-iframe-wrapper {
    height: 0;
    position: relative;
    padding-top: 56.25%; /* for responsive 16:9 aspect ratio */
    width: 100%;
  }

  iframe.youtube-similarity-player {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    border-radius: 10px;
  }

  #mm-song-versions {
    max-width: 1140px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}
