@import "globals";

#mm-order-history {
  #mm-order-history-landing {
    .cover-image {
      height: 250px;
      width: 100%;
      @include mobile {
        height: 80px;
      }
    }

    hgroup {
      @include relative;
      background: #efefed;
      min-height: 205px;
      padding-bottom: 20px;

      .user-icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        margin: 0 auto;
        top: 40px;
        width: 80px;

        span {
          @include center-sprite(user_badge);
        }
      }

      h1 {
        padding-top: 50px;
        text-align: center;
      }

      p {
        @include lead(2.8, 1.6);
        font-family: Obibok;
        font-size: 0.9rem;
        font-weight: 100;
        line-height: 1.25rem;
        color: $black;
        text-align: center;

        em {
          color: #666;
        }
      }
    }
  }

  nav.anchors {
    background: #f8f8f8;
    min-height: 70px;
    text-align: center;

    ol {
      display: inline-block;
      overflow: hidden;

      li {
        float: left;

        @include mobile {
          width: 50%;
        }

        @include tablet {
          width: 25%;
        }

        a {
          @include kern(80);
          color: #745bb4;
          display: block;
          font-family: Obibok;
          font-size: 0.75rem;
          line-height: 1.25rem;
          font-weight: 400;
          margin: 27px 20px;
          text-decoration: none;
          text-transform: uppercase;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  #order-history {
    padding-top: 30px;
    margin-bottom: 70px;

    @media screen and (max-width: 767px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    h2 {
      padding-bottom: 10px;
    }
  }
}
