@import "globals";

#mm-licensing {
  > *:last-child {
    padding-bottom: 120px;
  }

  #mm-licensing-landing {
    .cover-image {
      height: 250px;
      width: 100%;

      @media screen and (max-width: 797px) {
        height: 80px;
      }
    }

    hgroup {
      @include relative;
      background: #efefed;
      min-height: 206px;
      z-index: 1;

      .contain {
        @include relative;
      }

      .licensing-icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        margin: 0 auto;
        top: 40px;
        width: 80px;

        svg {
          height: 80px;
          width: 80px;
        }
      }

      h1 {
        color: $black;
        padding: 50px 0 25px;
        text-align: center;
      }
    }
  }

  nav.mm-licensing-nav {
    background: #f8f8f8;
    min-height: 70px;
    text-align: center;

    ol {
      display: inline-block;
      overflow: hidden;

      li {
        float: left;

        @include tablet-down {
          width: 100%;
          float: none;
        }

        a {
          font-family: Obibok;
          font-size: 0.75rem;
          font-weight: 400;
          letter-spacing: 0.1rem;
          color: $purple;
          display: block;
          padding: 27px 25px;
          text-decoration: none;
          text-transform: uppercase;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  article {
    background-color: white;
    overflow: hidden;
    margin: 0 auto;
    padding: 60px 0 0;
    max-width: 1140px;
    width: 100%;

    & > * {
      padding: 0 20px;
    }

    h4 {
      font-family: NeuzeitGro, georgia;
      border-bottom: 2px solid #f8f8f8;
      color: $black;
      padding-bottom: 14px;
    }

    address {
      font-family: NeuzeitGro, georgia;
      margin-bottom: 1rem;
      font-size: 1.2rem;
      color: #3a3532;
    }

    h2,
    &section #terms {
      font-family: NeuzeitGro, georgia;
      font-size: 1.5rem;
      border-bottom: 2px solid #f8f8f8;
      padding-bottom: 14px;
      color: #3a3532;
    }

    ol {
      list-style-type: decimal;
      padding-left: 20px;
    }

    p,
    &#terms section li {
      font-family: NeuzeitGro, georgia;
      @include lead(2.1, 1.8);
      color: $black;
      font-size: 1.2rem;
    }

    p {
      padding-bottom: 10px;
    }

    section {
      padding-top: 20px;
    }

    dl {
      dt {
        font-family: NeuzeitGro, georgia;
        @include lead(2.1, 1.8);
        color: $black;
        font-size: 1.5rem;
        margin: 0;
        padding-top: 10px;
      }

      dd {
        margin: 0;
        padding-bottom: 22px;
      }
    }

    ul {
      &.cost li {
        padding-left: 25px;
        line-height: 0.4em;
        span {
          top: 14px;
        }
      }

      li {
        @include relative;
        overflow: hidden;

        span {
          @include relative;
          color: $green;
          left: 15px;
          top: 18px;
        }

        p {
          padding-left: 40px;
          padding-bottom: 0;
        }
      }
    }
  }
}
