@import "globals";

#user_address_attributes_country_input,
#order_billing_information_attributes_address_attributes_country_input,
#order_billing_information_attributes_address_attributes_subregion_input,
#saved_cards_select,
.sort {
  float: right;
  margin-top: 11px;

  .mm-filter-sorter {
    display: none;
  }

  .sorter-wrap {
    display: inline-block;
    background: #fff;
    left: 0;
    position: relative;
    top: 0;

    &.open {
      .icon span {
        transform: scale(1, -1);
      }

      .options {
        display: block;
      }
    }
  }

  .sorter-link {
    border: 2px solid #999;
    display: block;
    height: 39px;
    width: 191px;

    .selected-option {
      font-family: NeuzeitGro, georgia;
      font-size: 1.6em;
      color: $black;
      float: left;
      height: 100%;
      line-height: 39px;
      overflow: hidden;
      padding: 0 0 0 10px;
      text-overflow: ellipsis;
      width: 139px;
      white-space: nowrap;

      span {
        font-size: 14px;
      }
    }

    .icon {
      @include relative;
      border-left: 1px solid #999;
      display: block;
      float: right;
      height: 100%;
      width: 41px;

      span {
        @include center-sprite(select_arrow);
      }
    }
  }

  .options {
    border-left: 2px solid #999;
    border-right: 2px solid #999;
    border-bottom: 2px solid #999;
    display: none;
    left: 0;
    max-height: 330px;
    overflow-y: scroll;
    position: absolute;
    top: 42px;
    z-index: 10;

    .option {
      font-family: NeuzeitGro, georgia;
      font-size: 1.6em;
      background: #fff;
      border-bottom: 2px solid #eee;
      color: $black;
      cursor: pointer;
      height: 39px;
      line-height: 39px;
      text-indent: 10px;
      width: 191px;

      span {
        font-size: 14px;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover,
      &.selected {
        background: $purple;
        color: #fff;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
      }
    }
  }
}

#work-bench .sort .sorter-link,
#work-bench-beta .sort .sorter-link {
  width: auto;
  min-width: 191px;
}
