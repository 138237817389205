@import "globals";

@include tablet {
  html,
  body {
    -webkit-overflow-scrolling: touch !important;
    overflow: auto !important;
    height: 100% !important;
  }
}

#mm-team {
  header#mm-team-landing {
    @include relative;
    background: url("/app/assets/images/headers/brand-update-header.jpg") center center / cover
      no-repeat;
    height: 500px;

    h2 {
      color: #fff;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
      padding-top: 93px;
      text-align: center;
    }
  }

  article#team {
    padding-bottom: 100px;

    .contain.action {
      text-align: center;
      clear: both;

      .box-button {
        border-color: $black;
        color: $black;
        margin: 0 auto;
        text-shadow: none;

        &:hover {
          background: $black;
          color: #fff;
        }

        &:active {
          border-color: $purple;
          background: $purple;
        }
      }
    }

    .columns {
      height: 100%;
      padding: 60px 0;
      width: auto;
      position: relative;
    }

    @include tablet-portrait-down {
      width: 100%;
    }

    &.left {
      float: left;
    }

    &.right {
      float: right;
    }

    .bio {
      width: 30%;
      padding: 0 1.5%;
      margin-bottom: 5%;
      vertical-align: top;
      display: inline-block;

      @include small-display {
        width: 44%;
        padding: 30px 2.5% 0 2.5%;
        vertical-align: top;
        display: inline-block;
      }

      @include under-tablet {
        width: 90%;
        padding: 0 5%;
      }

      figure {
        width: 100%;

        .portrait {
          @include fluid-square(79.92%);
          margin: 0 auto;

          &:hover {
            .over {
              @include opacity(1);
            }
          }

          @include mobile {
            @include no-select;
          }

          .over {
            @include opacity(0);
            @include transition(opacity, 100ms);
            background: rgba(116, 91, 180, 0.7);
            border-radius: 50%;
            cursor: pointer;
            display: block;
            height: 100%;
            left: 0px;
            padding: 0px;
            position: absolute;
            top: 0px;
            width: 100%;
            z-index: 2;
            backface-visibility: hidden;

            @include mobile {
              @include opacity(1);
            }

            &.showing {
              @include opacity(1);

              @include mobile {
                @include opacity(0);
              }
            }

            .name_title {
              color: #fff;
              left: 0;
              margin: 0 auto;
              position: absolute;
              right: 0;
              text-align: center;
              top: 40%;
              width: 90%;

              @include tablet-portrait-down {
                top: 33%;
              }

              h3 {
                @include lead(4.2, 3.2);
                font-family: Obibok;
                font-size: 1.75rem;
                line-height: 1em;
                padding-bottom: 1rem;

                @include small-display {
                  font-size: 1.5rem;
                }

                @include under-tablet {
                  font-size: 1.75rem;
                }

                @include mobile {
                  font-size: 1.5rem;
                }
              }

              span {
                @include lead(3, 1.5);
                font-family: Obibok;
                font-size: 0.9rem;
                line-height: 1em;

                @include small-display {
                  font-size: 1rem;
                }

                @include under-tablet {
                  font-size: 1.2rem;
                }

                @include mobile {
                  font-size: 0.9rem;
                }
              }
            }

            &:active {
              .default,
              .active {
                @include transition(opacity, 0ms);
              }
              .default {
                @include opacity(0);
              }
              .active {
                @include opacity(1);
              }
            }
          }

          .inner {
            border-radius: 50%;
          }
        }
      }

      .body {
        display: none;
        left: 4%;
        padding-right: 10%;
        padding-top: 25px;
        position: absolute;
        right: -4%;

        .header {
          padding-bottom: 5px;
          padding-top: 20px;

          h3 {
            @include lead(4.2, 3.2);
            font-family: Obibok;
            font-size: 1.75rem;
            padding-bottom: 0.5rem;
            color: $black;
            line-height: 1em;
          }

          p {
            @include lead(3, 1.5);
            font-family: Obibok;
            font-size: 0.9rem;

            &.title {
              line-height: 1.3em;
            }
          }
        }

        p {
          @include lead(2.8, 1.8);
          font-family: NeuzeitGro, georgia;
          color: $black;
          font-size: 1.8em;
          line-height: 1.3em;
          padding-bottom: 7px;

          &:last-of-type {
            padding-bottom: 0;
          }

          a {
            color: $purple;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .albums {
          @include lead(2.8, 1.8);
          font-family: NeuzeitGro;
          color: $black;
          font-size: 1rem;
          line-height: 1.3em;
          padding-top: 15px;
          font-weight: 100;

          h4 {
            font-family: Obibok;
            color: $green;
            font-weight: 600;
          }
        }
      }
    }
  }
}
