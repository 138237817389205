@import "globals";

#mm-download-song-versions {
  header#mm-download-song-versions-landing {
    .cover-image {
      background: #f7f7f7;
      height: 7.5rem;
      width: 100%;

      @include mobile {
        height: 80px;
      }
    }

    .nav-shadow {
      background: #ebebeb;
      height: 2px;
      width: 100%;
    }

    hgroup {
      @include relative;
      background: #efefed;
      min-height: 222px;
      text-align: center;
      width: 100%;
      z-index: 1;

      .contain {
        @include relative;
      }

      .download-icon {
        @include relative;
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 80px;
        margin: 0 auto;
        top: 40px;
        width: 80px;

        svg {
          height: 80px;
          width: 80px;

          g {
            fill: $green;
          }
        }
      }

      h1 {
        color: $black;
        padding-top: 50px;
      }

      h2 {
        color: #3a3532;
        font-family: "elena-web-basic", georgia;
        font-size: 2em;
        font-weight: 400;
        line-height: 1.75em;
        text-align: center;
        -webkit-font-smoothing: antialiased;

        @include mobile {
          padding-bottom: 25px;
        }
      }
    }
  }

  .download-song-versions-message {
    background: #f8f8f8;
    left: 0;
    padding: 12px 0;
    position: relative;
    text-align: center;
    top: 0;

    p {
      padding: 10px 10%;

      a {
        @include elena;
        color: $purple;
        text-decoration: none;
        @include mobile {
          @include lead(1.6, 1.1);
          &:active {
            text-decoration: underline;
          }
        }

        &:hover {
          text-decoration: underline;
          @include mobile {
            text-decoration: none;
          }
        }
      }
    }
  }

  article {
    padding-top: 30px;
    margin-bottom: 70px;
    min-height: 200px;
  }

  .loading {
    @include proxima;
    font-size: 2.5rem;
    text-align: center;
  }

  .error-message {
    padding-top: 20px;

    h2 {
      font-size: 3em;
      line-height: 40px;
      text-align: center;

      a {
        @include elena;
        color: $purple;
        text-decoration: none;
        @include mobile {
          @include lead(1.6, 1.1);
          &:active {
            text-decoration: underline;
          }
        }

        &:hover {
          text-decoration: underline;
          @include mobile {
            text-decoration: none;
          }
        }
      }
    }
  }

  .download-all-files-container {
    margin-top: 50px;
    text-align: center;

    a {
      cursor: pointer;
      display: inline-block;
      font-size: 1.6em;
      padding: 0 30px;

      @include mobile {
        width: 100%;
      }
    }
  }

  .mm-download-song-versions-list {
    @include small-display {
      margin: 0 7px;
    }

    ul {
      display: inline-block;
      margin-top: 30px;
      text-align: center;
      width: 100%;

      li {
        display: inline-block;
        height: 281px;
        left: 0;
        margin-bottom: 4px;
        margin-right: 4px;
        position: relative;
        text-align: left;
        top: 0;
        width: 24.7%;

        &:nth-of-type(4n + 4) {
          margin-right: 0;
          @include mobile {
            margin-right: 10px;
          }
        }

        @include tablet-down {
          height: 244px;
          width: 24.55%;
        }
        @include tablet-portrait-down {
          height: 185px;
          width: 24.55%;
        }
        @include mobile {
          @include box-sizing;
          @include no-select;
          backface-visibility: hidden;
          width: 100%;
          height: 100px;
          margin: 2px 0 !important;
        }
        @include not-mobile {
          &:hover {
            .over {
              @include opacity(1);
            }
            .gradient-overlay {
              height: 100%;
            }
            hgroup {
              h1,
              h2 {
                color: rgba(255, 255, 255, 0.4);
              }
            }
          }
        }

        .gradient-overlay {
          background: linear-gradient(0deg, #000, transparent);
          bottom: 0;
          left: 0;
          height: 150px;
          opacity: 1;
          position: absolute;
          width: 100%;

          @include transition(height, 100ms);

          @include tablet-down {
            height: 130px;
          }
          @include tablet-portrait-down {
            height: 110px;
          }
          @include mobile {
            height: 100%;
          }
        }

        hgroup {
          bottom: 0;
          color: #fff;
          left: 0;
          padding: 9% 8%;
          position: absolute;
          z-index: 2;

          @include mobile {
            padding: 0 20px 20px;
          }

          h1 {
            @include elena;
            @include kern(-20);
            @include lead(3.2, 3.2);
            font-size: 2em;
            font-weight: 700;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.45);

            @include tablet-down {
              font-size: 1.9em;
            }
            @include mobile {
              font-size: 2em;
            }
          }

          h2 {
            @include elena;
            color: #ccc;
            font-size: 1.6em;
            padding-top: 7px;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.45);

            @include tablet-down {
              font-size: 1.5em;
            }
            @include mobile {
              font-size: 1.6em;
            }
          }
        }

        .over {
          @include opacity(0);
          @include transition(opacity, 100ms);
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 2;
          backface-visibility: hidden;

          > div {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            a.download-file {
              background: #f2f2f2;
              box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
              color: $purple;
              font-size: 1.3em;
              padding: 0;
              margin: 10px;
              width: 200px;

              &:hover {
                background: #fff;
              }

              @include tablet-down {
                height: auto;
                padding: 10px;
                line-height: 16px;
                max-width: 150px;
              }

              @include mobile {
                border: 0;
                height: 100%;
                left: 0;
                margin: 0;
                top: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
